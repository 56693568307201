import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { addItem, removeItem } from "../../../state-management/menu/actions"
import { truncateDecimal } from "../../../state-management/menu/utils"
import ForcedModifiers from "../../Modifiers/ForcedModifiers/ForcedModifiers"
import OptionalModifiers from "../../Modifiers/OptionalModifiers/OptionalModifiers"
import FooterModifier from "./FooterModifier"
import "./DishModal.css"
import "simplebar/src/simplebar.css"
import { getTaxes } from "../../../state-management/menu/operations"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { openModal, closeModal } from "../../../state-management/modal/actions"
import DemoFoodImage from "./demo-food.png"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IsDealForcedModCategory from "../../Modifiers/ForcedModifiers/ModifiersCategory/IsDealForcedModCategory"
import IsDealForcedMod from "./IsDealForcedMod"
import { CgClose } from 'react-icons/cg';


const DishModal = () => {
  const dispatch = useDispatch()
  const menu = useSelector((state) => state.menu)
  const modal = useSelector((state) => state.modal)
  const [modalM, setModalM] = useState(false)
  const [activeLinkedFm, setActiveLinkedFm] = useState()
  let commonDescription = "Delicious meals are yummy, mouth-watering, delightful, enjoyable. You wouldn't call delicious that what is tasteless. Delicious meals are yummy, mouth-watering, delightful, enjoyable."
  const isImageUrl = require("is-image-url")
  const [itemCnt, setItemCnt] = useState(0)
  const [fltrssDatas, setFltrssDatas] = useState()

  const [tempstate, settempstate] = useState({
    selectedTempForcedModifier: [],
    forcedTempPrice: 0
  })

  const [state, setState] = useState({
    selectedOptionalModifier: [],
    selectedForcedModifier: [],
    forcedPrice: 0,
    optionalPrice: 0,
    item: modal.modal.state.item
  })


  var checkIsDeal = JSON.parse(state?.item?.linked_modifiers ? state?.item?.linked_modifiers : null)
  var linkedFmTests = [state?.item?.linked_modifiers]

  const [radioBtnValue, setRadioBtnValue] = useState(checkIsDeal?.linked_fm?.items ? checkIsDeal?.linked_fm?.items[0]?.fm_item_id : 0)
  const [forcedModData, setForcedModData] = useState()
  const [optionalModData, setOptionalModData] = useState()

  const [forcedRadioBtnValue, setForcedRadioBtnValue] = useState()

  const handleChangeIsDealRadio = (event) => {
    setRadioBtnValue(event.target.value);
  };

  const handleChangeForcedRadio = (event) => {
    setForcedRadioBtnValue(event.target.value);
  };

  useEffect(() => {
    setForcedModData(checkIsDeal?.fm?.filter((n => n.fm_item_id == radioBtnValue)))
    setOptionalModData(checkIsDeal?.om?.filter((n => n.fm_item_id == radioBtnValue)))

  }, [radioBtnValue])

  let itemSizeFilter = checkIsDeal?.linked_fm?.items?.filter((n => n.fm_item_id == radioBtnValue)) ? checkIsDeal?.linked_fm?.items?.filter((n => n.fm_item_id == radioBtnValue)) : 0
  const [isDealForTotalPrice, setIsDealForTotalPrice] = useState([])
  const [isDealOptTotalPrice, setisDealOptTotalPrice] = useState([])

  const [forSubTotal, setForSubTotal] = useState(0)
  const [optSubTotal, setOptSubTotal] = useState(0)

  useEffect(() => {
    var totals = 0;
    for (let i = 0; i < isDealForTotalPrice?.length; i++) {
      totals += isDealForTotalPrice[i]?.price
    }
    setForSubTotal(totals)
  }, [isDealForTotalPrice])

  useEffect(() => {
    var totals = 0;
    for (let i = 0; i < isDealOptTotalPrice.length; i++) {
      totals += isDealOptTotalPrice[i].price
    }
    setOptSubTotal(totals)
  }, [isDealOptTotalPrice])

  useEffect(() => {
    let fltrData = menu?.cart?.filter(n => n.id == state?.item?.id)
    if (fltrData?.length < 1) {
      setShowMod(false);
    } else {
      setShowMod(true);
    }
  }, [])


  let isDealTotalPrice = forcedModData?.length != 0 || optionalModData?.length != 0 ? Number(state?.item?.price) + Number(itemSizeFilter[0]?.price) + forSubTotal + optSubTotal : Number(state?.item?.price) + Number(itemSizeFilter[0]?.price)

  const getForcedModifiers = () => {
    const itemForcedModifiers = state.item.forced_modifier.split(",")
    const newLinkedJson = JSON.parse(state?.item?.linked_modifiers ? state?.item?.linked_modifiers : null)
    const isDeal = JSON.parse(state?.item?.linked_modifiers)?.linked_fm?.is_deal === "1" ? true : false
    const isLinked = JSON.parse(state?.item?.linked_modifiers)?.linked_fm?.is_linked_status_on === "1" ? true : false

    if (isLinked) {
      let linkedArr = []
      const linkedFm = newLinkedJson.linked_fm
      const otherFm = newLinkedJson?.fm

      linkedArr.push(linkedFm)
      for (let i = 0; i < otherFm?.length; i++) {
        linkedArr.push(otherFm[i])
      }

      return linkedArr
    } else {
      if (state.item.forced_modifier !== "0") {
        const itemForcedModifiers = state.item.forced_modifier.split(",")
        return menu.allForcedModifier.filter((item) =>
          itemForcedModifiers.includes(item.fm_cat_id)
        )
      }
      return []
    }
  }

  const getOptionModifiers = () => {
    if (state.item.optional_modifier !== "0") {
      const itemOptionalModifiers = state.item.optional_modifier.split(",")

      return menu.allOptionalModifier.filter((item) =>
        itemOptionalModifiers.includes(item.om_cat_id)
      )
    }

    return []
  }

  useEffect(() => {
    var priceArr = []
    const isDeal = JSON.parse(state?.item?.linked_modifiers)?.linked_fm?.is_deal === "1" ? true : false

    const isLinked = JSON.parse(state?.item?.linked_modifiers)?.linked_fm?.is_linked_status_on === "1" ? true : false


    if (isDeal && isLinked) {
      let priceArr = checkIsDeal?.linked_fm?.items.filter(object => object.fm_item_id == radioBtnValue)

      let otherFmIsDeal = checkIsDeal?.fm?.filter((n => n.fm_item_id == radioBtnValue))
      if (otherFmIsDeal) {
        let fmArrays1 = []

        for (let i = 0; i < otherFmIsDeal.length; i++) {
          fmArrays1.push(otherFmIsDeal[i].items)
        }

        for (let i = 0; i < fmArrays1.length; i++) {
          priceArr.push(fmArrays1[i][0])
        }
      }

      let selectedDealId = priceArr.filter(object => object?.addon_price == undefined)[0]?.fm_item_id
      let tempMod = priceArr.filter(object => object?.addon_price != undefined)
      let selectedMod = []

      for (var i = 0; i < tempMod.length; i++) {
        for (var j = 0; j < tempMod[i].addon_price.length; j++) {
          if (tempMod[i].addon_price[j].fm_item_id == selectedDealId) {
            selectedMod.push(tempMod[i].addon_price[j])
          }
        }
      }

      let tempPrice = 0

      priceArr.map((val) => {
        tempPrice += Number(val?.price)
      })
      selectedMod.map((val) => {
        tempPrice += Number(val?.fm_item_price)
      })

      setState({
        ...state,
        selectedForcedModifier: priceArr,
        forcedPrice: tempPrice
      })
      settempstate({
        ...tempstate,
        selectedTempForcedModifier: priceArr,
        forcedTempPrice: tempPrice
      })

    } else if (isLinked) {
      const itemForcedModifiers = JSON.parse(state?.item?.linked_modifiers ? state?.item?.linked_modifiers : null)
      let linked_fm_id = itemForcedModifiers.linked_fm.fm_cat_id
      setActiveLinkedFm(linked_fm_id)
      const linkedArray = itemForcedModifiers?.linked_fm?.items
      let min = Math.min.apply(
        null,
        linkedArray?.map(function (item) {
          return item.price
        })
      )
      temp = temp + parseFloat(min)
      linkedArray?.map((curr) => {
        if (min == curr.price) {
          priceArr.push(curr)
        }
      })

      //fm
      // const fmArray = itemForcedModifiers.fm[0]?.items
      if (itemForcedModifiers.fm[0]?.items) {
        const fmArray = itemForcedModifiers.fm[0]?.items
        let fmArrays1 = []

        for (let i = 0; i < itemForcedModifiers.fm.length; i++) {
          fmArrays1.push(itemForcedModifiers.fm[i].items)
        }

        for (let i = 0; i < fmArrays1.length; i++) {
          priceArr.push(fmArrays1[i][0])
        }
      }

      let selectedLinkedId = priceArr.filter(object => object?.addon_price == undefined)[0]?.fm_item_id
      let tempMod = priceArr.filter(object => object?.addon_price != undefined)
      let selectedMod = []

      for (var i = 0; i < tempMod.length; i++) {
        for (var j = 0; j < tempMod[i].addon_price.length; j++) {
          if (tempMod[i].addon_price[j].fm_item_id == selectedLinkedId) {
            selectedMod.push(tempMod[i].addon_price[j])
          }
        }
      }

      let tempPrice = 0

      priceArr.map((val) => {
        tempPrice += Number(val?.price)
      })
      selectedMod.map((val) => {
        tempPrice += Number(val?.fm_item_price)
      })

      setState({
        ...state,
        selectedForcedModifier: priceArr,
        forcedPrice: tempPrice
      })
      settempstate({
        ...tempstate,
        selectedTempForcedModifier: priceArr,
        forcedTempPrice: tempPrice
      })

    } else {
      const itemForcedModifiers =
        modal.modal.state.item.forced_modifier.split(",")

      var temp = 0
      itemForcedModifiers.map((curritem) => {
        const filteredModifier = menu.allForcedModifier.filter((itemOfFilter) =>
          curritem.includes(itemOfFilter.fm_cat_id)
        )
        let fmArrays1 = []
        for (let i = 0; i < filteredModifier.length; i++) {
          fmArrays1.push(filteredModifier[i].items)
        }

        for (let i = 0; i < fmArrays1.length; i++) {
          priceArr.push(fmArrays1[i][0])
        }

        let tempPrice = 0
        priceArr.map((val) => {
          tempPrice += Number(val?.price)
        })

        setState({
          ...state,
          selectedForcedModifier: priceArr,
          forcedPrice: tempPrice
        })
        settempstate({
          ...tempstate,
          selectedTempForcedModifier: priceArr,
          forcedTempPrice: tempPrice
        })
      })
    }
  }, [])




  const isPriceWithoutTaxs = () => {
    return Number(menu.restaurantInfo["price_without_tax_flag"])
  }

  const getActualsPrices = () => {
    if (menu.restaurantInfo) {
      return isPriceWithoutTaxs()
        ? state?.item?.price
        : (
          Number(state?.item?.price) +
          Number(getTaxes(state?.item, state?.item?.price, menu.restaurantInfo).tax)
        ).toFixed(2)
    }

    return 0
  }



  const handleAddItem = () => {
    const current_item = modal.modal.state.item
    const similarItems = menu.cart.filter(({ id }) => current_item.id === id)

    const menuItems = menu.menuItems
    const item = menuItems.find(({ id }) => state.item.id === id)
    const { forcedPrice, optionalPrice } = state
    const modifier = {
      forcedModifier: state.selectedForcedModifier,
      optionalModifier: state.selectedOptionalModifier
    }

    const subTotal = (isNaN(forcedPrice) ? 0 : forcedPrice) + (isNaN(optionalPrice) ? 0 : optionalPrice) + Number(item.price)

    if (state.item.linked_modifiers == null && state.item.forced_modifier == "0" && state.item.optional_modifier == "0") {
      // dispatch(addItem(item, modifier, subTotal, menu.restaurantInfo))
      console.log("Its a plane item, not a modifiers item");

      dispatch(
        addItem(
          item,
          item.modifiers || null,
          0,
          menu.restaurantInfo
        )
      )
      // dispatch(closeModal())

      setState({ ...state, forcedPrice: 0, optionalPrice: 0 })
    }

    else if (checkIsDeal?.linked_fm?.is_deal == "1") {
      console.log("Its a isdeal modifiers item");
      dispatch(
        addItem(
          item,
          modifier,
          subTotal,
          menu.restaurantInfo
        )
      )
      // dispatch(closeModal())

      setState({ ...state, forcedPrice: 0, optionalPrice: 0 })
    }

    else {
      console.log("Its a modifiers item");

      dispatch(addItem(item, modifier, subTotal, menu.restaurantInfo))
      // dispatch(closeModal())

      setState({ ...state, forcedPrice: 0, optionalPrice: 0 })
    }
  }

  const closeTheModal = () => {
    dispatch(closeModal())
  }

  const handleForcedModifierSelectionChange = (selectedForcedModifier, forcedPrice) => {
    setState({
      ...state,
      selectedForcedModifier: selectedForcedModifier,
      forcedPrice: forcedPrice
    })
    settempstate({
      ...tempstate,
      selectedTempForcedModifier: selectedForcedModifier,
      forcedTempPrice: forcedPrice
    })
  }

  const handleOptionalModifierSelectionChange = (selectedOptionalModifier, optionalPrice) => {
    setState({ ...state, selectedOptionalModifier, optionalPrice })
  }

  const isPriceWithoutTax = () => {
    return Number(menu.restaurantInfo["price_without_tax_flag"])
  }

  const getActualPrice = (item) => {
    if (menu.restaurantInfo) {
      return isPriceWithoutTax()
        ? Number(item.price)
        : truncateDecimal(
          Number(item.price) +
          Number(
            getTaxes(item, Number(item.price), menu.restaurantInfo).tax
          )
        )
    }

    return 0
  }

  const getModifierPrice = (price) =>
    isPriceWithoutTax()
      ? price
      : truncateDecimal(
        Number(price) +
        Number(getTaxes(state.item, Number(price), menu.restaurantInfo).tax)
      )

  const getPrice = (item, price) => {
    return isPriceWithoutTax()
      ? truncateDecimal(price)
      : truncateDecimal(
        Number(price) +
        Number(getTaxes(item, Number(price), menu.restaurantInfo).tax)
      )
  }

  const getTotalPrice = (item) => {
    const { forcedPrice, optionalPrice } = state
    const { price } = item

    const modifierPrice = (
      isNaN(forcedPrice)
        ?
        0
        :
        forcedPrice
    )
      +
      (
        isNaN(optionalPrice)
          ?
          0
          :
          optionalPrice
      )

    const result = modifierPrice
      ? modifierPrice + Number(parseFloat(price))
      : getActualPrice(item)

    if (state.item.linked_modifiers == null && state.item.forced_modifier == "0" && state.item.optional_modifier == "0") {
      return item?.price
    } else {
      return modifierPrice ? getPrice(item, result) : result
    }
  }

  const isConfirmationDisabled = () => {
    const forcedModifers = getForcedModifiers()

    const { selectedForcedModifier } = state
    if (forcedModifers && forcedModifers.length) {
      return (
        !selectedForcedModifier ||
        (selectedForcedModifier &&
          forcedModifers?.length !== selectedForcedModifier.length)
      )
    }

    return false
  }

  const menuItems = menu.menuItems
  const itemForFooter = menuItems.find(({ id }) => state.item.id === id)

  const toggle = () => {
    setModalM(!modalM)
    dispatch(closeModal())
  }

  const crossBtnOfMdlss = () => {
    // let fltrData = menu?.cart?.filter(n => n.id == state?.item?.id)

    // for (let i = 0; i < itemCnt; i++) {
    //   dispatch(removeItem(fltrData[0], fltrData[0].modifiers || null, 0, menu.restaurantInfo))
    // }
    dispatch(closeModal())
  }

  const [showMod, setShowMod] = useState(false)
  const [showItemDescp, setShowItemDescp] = useState(false)

  return (
    <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Modal
          isOpen={true}
          // toggle={toggle}
          style={{
            top: "15%",
            left: "0",
            borderRadius: "20px",
            width: "35px",
          }}>

          <div className="hdrs-parent">
            <CgClose
              onClick={crossBtnOfMdlss}
              style={{ marginTop: "" }} size={25} />
            <div className='hdrs'>
              <div className='hdrs-text'>{state?.item?.name}</div>
              {console.log("its states", state)}
              {console.log("hey price", JSON.parse(state?.item?.linked_modifiers ? state?.item?.linked_modifiers : null))}
            </div>
          </div>

          <div style={{
            width: '113%',
            marginLeft: '-42px',
            maxHeight: "380px",
            overflowX: "hidden",
            overflowY: "scroll",
          }}>
            {
              state.item.linked_modifiers == null
                &&
                state.item.forced_modifier == "0"
                &&
                state.item.optional_modifier == "0"
                ?
                <div style={{ paddingLeft: '14%' }}>
                  <div className="hds-price">
                    {menu.restaurantInfo.monetary_symbol} {getActualsPrices()}
                  </div>

                  <div className='imgss-item'>
                    <img className='imgss' alt='Item-Img' src={isImageUrl(state.item.image_url) ? state.item.image_url : DemoFoodImage} />
                  </div>

                  <div className="tags-container">

                    {state?.item?.is_vegan == "1"
                      &&
                      <div style={{ backgroundColor: '#46965e' }} className="tgs">
                        Vegetarian
                      </div>
                    }

                    {state?.item?.is_hot == "1"
                      &&
                      <div style={{ backgroundColor: '#ce2100' }} className="tgs">
                        Hot
                      </div>
                    }

                    {state?.item?.nuts_free == "1"
                      &&
                      < div style={{ backgroundColor: '#e67935' }} className="tgs">
                        Nuts free
                      </div>
                    }

                    {state?.item?.lactose_free == "1"
                      &&
                      <div style={{ backgroundColor: '#e67935' }} className="tgs">
                        Lactose free
                      </div>
                    }

                    {state?.item?.gluten_free == "1"
                      &&
                      <div style={{ backgroundColor: '#46965e' }} className="tgs">
                        Gluten free
                      </div>
                    }

                    {state?.item?.is_reduced == "1"
                      &&
                      < div style={{ backgroundColor: '#d4a303' }} className="tgs">
                        Reduced fat
                      </div>
                    }
                  </div>

                  {
                    state?.item?.description
                      ?
                      <>
                        {
                          state?.item?.description?.length <= 124
                            ?
                            <div className="itm-descptns">
                              {state?.item?.description}
                            </div>
                            :
                            <>
                              {showItemDescp
                                ?
                                <div className="itm-descptns">
                                  {state?.item?.description}
                                  &nbsp;
                                  <span
                                    onClick={() => setShowItemDescp(false)}
                                    className="readMoreBtn">Show less</span>
                                </div>
                                :
                                <div className="itm-descptns">
                                  {state?.item?.description?.substr(0, 136)}
                                  &nbsp;
                                  <span
                                    onClick={() => setShowItemDescp(true)}
                                    className="readMoreBtn">Read more</span>
                                </div>}
                            </>
                        }
                      </>
                      : null

                  }
                </div>

                :

                <>
                  {
                    !showMod
                      ?
                      <div style={{ paddingLeft: '14%' }}>
                        <div className="hds-price">
                          {menu.restaurantInfo.monetary_symbol} {getActualsPrices()}
                        </div>

                        <div className='imgss-item'>
                          <img className='imgss' alt='Item-Img' src={isImageUrl(state.item.image_url) ? state.item.image_url : DemoFoodImage} />
                        </div>

                        <div className="tags-container">

                          {state?.item?.is_vegan == "1"
                            &&
                            <div style={{ backgroundColor: '#46965e' }} className="tgs">
                              Vegetarian
                            </div>
                          }

                          {state?.item?.is_hot == "1"
                            &&
                            <div style={{ backgroundColor: '#ce2100' }} className="tgs">
                              Hot
                            </div>
                          }

                          {state?.item?.nuts_free == "1"
                            &&
                            < div style={{ backgroundColor: '#e67935' }} className="tgs">
                              Nuts free
                            </div>
                          }

                          {state?.item?.lactose_free == "1"
                            &&
                            <div style={{ backgroundColor: '#e67935' }} className="tgs">
                              Lactose free
                            </div>
                          }

                          {state?.item?.gluten_free == "1"
                            &&
                            <div style={{ backgroundColor: '#46965e' }} className="tgs">
                              Gluten free
                            </div>
                          }

                          {state?.item?.is_reduced == "1"
                            &&
                            < div style={{ backgroundColor: '#d4a303' }} className="tgs">
                              Reduced fat
                            </div>
                          }
                        </div>

                        {
                          state?.item?.description
                            ?
                            <>
                              {
                                state?.item?.description?.length <= 124
                                  ?
                                  <div className="itm-descptns">
                                    {state?.item?.description}
                                  </div>
                                  :
                                  <>
                                    {showItemDescp
                                      ?
                                      <div className="itm-descptns">
                                        {state?.item?.description}
                                        &nbsp;
                                        <span
                                          onClick={() => setShowItemDescp(false)}
                                          className="readMoreBtn">Show less</span>
                                      </div>
                                      :
                                      <div className="itm-descptns">
                                        {state?.item?.description?.substr(0, 136)}
                                        &nbsp;
                                        <span
                                          onClick={() => setShowItemDescp(true)}
                                          className="readMoreBtn">Read more</span>
                                      </div>}
                                  </>
                              }
                            </>
                            : null
                        }

                        <div className="adds-container">
                          <Button
                            onClick={() => setShowMod(true)}
                            style={{
                              backgroundColor: "#000000",
                              color: "#fff",
                              width: "100%",
                              padding: "10px",
                              textTransform: "none",
                              fontSize: "15px",
                              borderRadius: "15px",
                              fontFamily: "Poppins",
                              letterSpacing: '0.7px',
                              height: '3.2rem',
                              marginBottom: '8px'
                            }}>
                            Add
                          </Button>
                        </div>
                      </div>

                      :

                      <ModalBody style={{
                        maxHeight: "400px",
                        overflowX: "hidden",
                        overflowY: "scroll",
                      }}>
                        <>
                          {
                            checkIsDeal?.linked_fm?.is_deal == "1"
                              ?
                              <>
                                <div style={{ paddingLeft: '14%', marginBottom: '15px' }}>
                                  <div style={{ backgroundColor: '#eeedf2', paddingTop: '20px', paddingBottom: '2px', borderRadius: '15px' }}>
                                    <div style={{ marginBottom: '10px' }} className="choose-cat">{checkIsDeal?.linked_fm?.name}</div>

                                    <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '20px' }}>
                                      <FormControl>
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          name="radio-buttons-group"
                                          value={radioBtnValue}
                                          onChange={handleChangeIsDealRadio}
                                        >
                                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {
                                              checkIsDeal?.linked_fm?.items?.map((item, i) => {
                                                return (
                                                  <>
                                                    <FormControlLabel
                                                      style={{
                                                        fontFamily: 'Poppins',
                                                        fontSize: '14px',
                                                        letterSpacing: '0.6px',
                                                        fontWeight: '500',
                                                        backgroundColor: '#fff',
                                                        color: '#000',
                                                        borderRadius: '20px',
                                                        marginBottom: '10px',
                                                        paddingRight: '8px',
                                                        marginRight: '22px'
                                                      }}
                                                      value={item?.fm_item_id} control={<Radio />} label={item?.fm_item_name} />
                                                  </>
                                                )
                                              })
                                            }
                                          </div>
                                        </RadioGroup>
                                      </FormControl>
                                    </div>

                                  </div>
                                </div>


                                {
                                  forcedModData?.map((item, i) => {
                                    return (
                                      <>
                                        <IsDealForcedMod
                                          item={item}
                                          radioBtnValue={radioBtnValue}
                                          isDealForTotalPrice={isDealForTotalPrice}
                                          setIsDealForTotalPrice={setIsDealForTotalPrice}
                                          lowestPricearray={state.selectedForcedModifier}
                                          onSelectionChange={handleForcedModifierSelectionChange}
                                        />



                                      </>
                                    )
                                  })
                                }

                                <OptionalModifiers
                                  getModifierPrice={getModifierPrice}
                                  currency={menu.restaurantInfo.monetary_symbol}
                                  optionalModifier={optionalModData}
                                  onSelectionChange={handleOptionalModifierSelectionChange}
                                  activeIsDeal={'active'}
                                  isDealfirstItm={radioBtnValue}
                                />

                                {/* {
                                optionalModData?.map((item, i) => {
                                  return (
                                    <div style={{ paddingLeft: '14%', marginBottom: '15px' }}>
                                      <div style={{ width: '100%', backgroundColor: '#eeedf2', paddingTop: '20px', paddingBottom: '2px', borderRadius: '15px' }}>
                                        <div className="choose-cat">{item?.om_cat_name}</div>

                                        <form style={{
                                          width: '100%',
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                          marginTop: '10px',
                                          paddingRight: '10px',
                                          paddingLeft: '6px'
                                        }}>
                                          {
                                            item?.items?.map((chitem, j) => {
                                              return (
                                                <span className="isdealss-cats">
                                                  <input
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                      // add to list
                                                      if (e.target.checked) {
                                                        // setisDealOptTotalPrice(Number(chitem?.price))

                                                        setisDealOptTotalPrice([
                                                          ...isDealOptTotalPrice,
                                                          {
                                                            price: Number(chitem?.price)
                                                          },
                                                        ]);
                                                      } else {
                                                        // remove from list
                                                        setisDealOptTotalPrice([]);
                                                      }
                                                    }}
                                                    value={isDealOptTotalPrice}
                                                  />
                                                  <span style={{ marginLeft: '10px' }}>
                                                    {chitem?.om_item_name}
                                                  </span>
                                                </span>
                                              )
                                            })
                                          }
                                        </form>
                                      </div>
                                    </div>
                                  )
                                })
                              } */}
                              </>

                              :

                              <>
                                <ForcedModifiers
                                  currency={menu.restaurantInfo.monetary_symbol}
                                  isPriceWithoutTax={isPriceWithoutTax()}
                                  item={state.item}
                                  forcedModifiers={getForcedModifiers()}
                                  detours={state.item.detour_ids}
                                  getModifierPrice={getModifierPrice}
                                  onSelectionChange={handleForcedModifierSelectionChange}
                                  lowestPricearray={state.selectedForcedModifier}
                                  activeLinkedFm={activeLinkedFm}
                                />


                                <OptionalModifiers
                                  getModifierPrice={getModifierPrice}
                                  currency={menu.restaurantInfo.monetary_symbol}
                                  optionalModifier={getOptionModifiers()}
                                  onSelectionChange={handleOptionalModifierSelectionChange}
                                  activeIsDeal={null}
                                  isDealfirstItm={null}
                                />
                              </>
                          }
                        </>
                      </ModalBody>

                  }
                </>
            }

          </div>

          {
            state.item.linked_modifiers == null
              &&
              state.item.forced_modifier == "0"
              &&
              state.item.optional_modifier == "0"
              ?
              <FooterModifier
                totalPrices={`${getTotalPrice(itemForFooter)}`}
                // totalPrices={`${getActualsPrices()}`}
                isDealTotalPrice={null}
                btnCls='cart'
                disabled={isConfirmationDisabled()}
                onClick={handleAddItem}
                menu={menu}
                itemsId={state?.item?.id}
                closeTheModal={closeTheModal}
                monetarySymbol={menu.restaurantInfo.monetary_symbol}
                itemCnt={itemCnt}
                setItemCnt={setItemCnt}
                setFltrssDatas={setFltrssDatas}
                allAboutItem={state}
              />
              :
              <>
                {
                  showMod
                    ?
                    <>
                      {checkIsDeal?.linked_fm?.is_deal == "1"
                        ?
                        <FooterModifier
                          // totalPrices={null}
                          // isDealTotalPrice={`${isDealTotalPrice}`}
                          totalPrices={`${getTotalPrice(itemForFooter)}`}
                          isDealTotalPrice={null}
                          btnCls='cart'
                          disabled={isConfirmationDisabled()}
                          onClick={handleAddItem}
                          menu={menu}
                          itemsId={state?.item?.id}
                          closeTheModal={closeTheModal}
                          monetarySymbol={menu.restaurantInfo.monetary_symbol}
                          itemCnt={itemCnt}
                          setItemCnt={setItemCnt}
                          setFltrssDatas={setFltrssDatas}
                          allAboutItem={state}
                        />
                        :
                        <FooterModifier
                          totalPrices={`${getTotalPrice(itemForFooter)}`}
                          isDealTotalPrice={null}
                          btnCls='cart'
                          disabled={isConfirmationDisabled()}
                          onClick={handleAddItem}
                          menu={menu}
                          itemsId={state?.item?.id}
                          closeTheModal={closeTheModal}
                          monetarySymbol={menu.restaurantInfo.monetary_symbol}
                          itemCnt={itemCnt}
                          setItemCnt={setItemCnt}
                          setFltrssDatas={setFltrssDatas}
                          allAboutItem={state}
                        />
                      }
                    </>
                    : null
                }
              </>
          }

        </Modal>
      </div>
    </>
  )
}

export default DishModal