import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ModalFooter, Button } from "reactstrap";
import { removeItem } from "../../../state-management/menu/actions";
import "./CssForModal.css";

const FooterModifier = ({
  btnCls = "",
  totalPrices,
  disabled = false,
  onClick,
  isDealTotalPrice,
  closeTheModal,
  menu,
  itemsId,
  monetarySymbol,
  itemCnt,
  setItemCnt,
  setFltrssDatas,
  allAboutItem
}) => {
  const dispatch = useDispatch()
  const [errMsg, setErrMsg] = useState(false)
  const [qtCnt, setQtCnt] = useState(Number(allAboutItem?.item?.min_qty))

  // console.log("itemsId", itemsId)
  // console.log("allAboutItem", allAboutItem)

  let fltrData = menu?.cart?.filter(n => n.id == itemsId)

  let qtyDemo = []
  let qtyzCount = fltrData?.length > 0 ? fltrData[0]?.qty : 0

  const dmrmv = () => {
    if (qtCnt > allAboutItem?.item?.min_qty){
      setQtCnt(qtCnt - 1)
    } else {
      setQtCnt(qtCnt)
    }
    
  }

  const dmAdd = () => {
    setQtCnt(qtCnt + 1)
  }

  const addCartBtn = () => {
    for (let i = 0; i < qtCnt; i++) {
      onClick();
    }
    closeTheModal()
  }

  const handleRemoveItem = () => {
    // console.log("item", fltrData[0]);
    if (Number(qtyzCount) > 1) {
      setItemCnt(Number(fltrData[0]?.qty) - 1);
      dispatch(removeItem(fltrData[0], fltrData[0].modifiers || null, 0, menu.restaurantInfo))
    } else {
      return
    }
  }


  // let calculatedTotals = ''

  const getTotalsAmount = (amt) => {
    let madeTtlDcml = '';
    if (amt.toString().includes('.')) {
      madeTtlDcml = amt?.toString()
    } else {
      madeTtlDcml = amt + '.00'
    }
    let splitTotalArr = madeTtlDcml?.split('.')

    if (splitTotalArr[1]?.length < 2) {
      return splitTotalArr[0] + '.' + splitTotalArr[1] + '0'
    } else {
      return splitTotalArr[0] + '.' + splitTotalArr[1]?.substring(0, 2)
    }
  }

  return (
    <ModalFooter>
      <div className='addModBtn-container'>
        <div>
          <div className="from-text">Total Price</div>
          <div className="item-price-text">
            {monetarySymbol}&nbsp;
            {/* {getTotalsAmount(
              fltrData?.length > 0
                ?
                fltrData[0]?.grandTotal
                :
                0
            )} */}
            {getTotalsAmount(
              totalPrices != null
              ?
              totalPrices * qtCnt
              :
              isDealTotalPrice * qtCnt
            )}
          </div>
        </div>

        <div className="itm-cnt-box">
          <div
            onClick={() => {
              dmrmv();
              setItemCnt(Number(fltrData?.length > 0 ? fltrData[0]?.qty : 0) - 1);
              setFltrssDatas(fltrData[0])
            }}
            // onClick={dmrmv}
            className="itm-cnt-change-btn"
            style={{ marginRight: '10px' }}>
            -
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '12px', fontSize: '18px' }}>
            {qtCnt}
          </div>

          <div
            onClick={() => {
              dmAdd();
              setItemCnt(Number(fltrData?.length > 0 ? fltrData[0]?.qty : 0) + 1);
              setFltrssDatas(fltrData[0])
            }}
            // onClick={dmAdd}
            className="itm-cnt-change-btn"
            style={{ marginLeft: '10px' }}>
            +
          </div>
        </div>
      </div>

      <div className="addtocart-container">
        <Button
          // className={`${disabled ? "disabled" : ""}`}
          onClick={addCartBtn}
          style={{
            backgroundColor: "#000000",
            color: "#fff",
            width: "100%",
            padding: "10px",
            textTransform: "none",
            fontSize: "15px",
            borderRadius: "15px",
            fontFamily: "Poppins",
            letterSpacing: '0.7px',
            height: '3.2rem',
            marginTop: '5px',
            fontWeight: '400'
          }}>
          Add to cart
        </Button>

      </div>
    </ModalFooter>
  )
};
export default FooterModifier;
