import React, { useState } from 'react'
import "./paymentOption.css";
import { useParams } from "react-router-dom";
import { BiRadioCircleMarked, BiRadioCircle } from 'react-icons/bi';
import { BsFillCreditCard2BackFill } from 'react-icons/bs';
import { FaCcApplePay } from 'react-icons/fa';
import { TbCreditCard } from 'react-icons/tb';
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import sha256 from "js-sha256";
import axios from "axios";
import { useEffect } from 'react';

const PaymentOption = () => {
    const History = useHistory()
    const params = useParams();
    const [cardBtn, setCardBtn] = useState(true)
    const [appleBtn, setAppleBtn] = useState(false)
    const [payLoader, setPayLoader] = useState(false)
    const [cardOptError, setCardOptError] = useState(false)

    const main = useSelector((state) => state.main)
    const menu = useSelector((state) => state.menu)

    useEffect(() => {
      console.log("params", params.orderid.slice(8))
    }, [])
    
    let filteredDataById = main?.fetchOpenOrder?.data?.filter(n => n.order_id == params.orderid.slice(8));

    // Pay Now
    const sendpaymentreq = async () => {
        // var errorurl = `https://ciboapp.com/feedmii/?/restId=${menu.restaurantInfo.restaurant_id}/paymentfailed`
        // var failedurl = `https://ciboapp.com/feedmii/?/restId=${menu.restaurantInfo.restaurant_id}/paymentfailed`
        // var accepturl = `https://ciboapp.com/feedmii/?/restId=${menu.restaurantInfo.restaurant_id}/ordersuccess?orderid=${params.orderid.slice(8)}`


        var errorurl = `https://ciboapp.com/restId=${menu.restaurantInfo.restaurant_id}/paymentfailed`

        // var failedurl = `https://ciboapp.com/restId=${menu.restaurantInfo.restaurant_id}/paymentfailed`
        var failedurl = `https://menu.ciboapp.com/restId=${menu.restaurantInfo.restaurant_id}/${main.tableId}/paymentfailed`
        
        var accepturl = `https://menu.ciboapp.com/PaySuccess/restid=${menu.restaurantInfo.restaurant_id}/tid=${main.tableId}`
        var callbackurl = "https://ciboapp.com/api/mobileApi/v2/app/callback" //old URL
        // var callbackurl = "https://api.ciboapp.com/api/selforder/qrCallBack" //new URL

        var mkey = menu.restaurantInfo.merchant_key
        var sec = menu.restaurantInfo.secret
        var crncy = main.selectedRestaurant.currency
        var userid = "123"
        var type = ""
        if (cardBtn) {
            setCardOptError(false)
            type = "1"
        } else {
            setCardOptError(true)
        }

        let str = `${filteredDataById[0].grand_total}${callbackurl}${crncy}${errorurl}${failedurl}${mkey}${params.orderid.slice(8)}${accepturl}${sec}`

        let mac = sha256(str)

        //live https://api.zotto.z-payments.com/api/payment-link
        //live merchant : 2533554646411
        //live secret : eAfg21swu0y211

        //test https://paymentz.z-pay.co.uk/api/payment-link
        //test merchant : 45455514421
        //test secret : eAfg21swu0y2

        const paymentdata = {
            name: filteredDataById[0].delivery_firstname,
            email: filteredDataById[0].delivery_email,
            phone: filteredDataById[0].delivery_mobile,
            user_id: userid,
            order_id: `${params.orderid.slice(8)}`,
            merchant_key: mkey,
            amount: `${filteredDataById[0].grand_total}`,
            currency: main.selectedRestaurant.currency,
            mac: mac,
            redirect_type: type,
            accept_url: accepturl,
            failed_url: failedurl,
            error_url: errorurl,
            callback_url: callbackurl
        }

        setPayLoader(true);
        axios
            .post("https://api.zotto.z-payments.com/api/payment-link", paymentdata)  // Live URL
            .then((response) => {
                // console.log("Payment Successful", response.data);
                setPayLoader(false);
                window.location.href = response.data.data.paymentLink
            }
            )
            .catch((error) => {
                console.log("we have payment error", error);
                setPayLoader(false);
                History.push(
                    `/paymentFailed/orderId=${params.orderid.slice(8)}`
                )
            })
    }

    return (
        <div>
            <div className="pay-container">
                <div className='pay-text'>
                    Payment
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div onClick={() => {
                        setCardBtn(true)
                        setCardOptError(false)
                    }}
                        className='payOpt-container'>
                        {cardBtn
                            ?
                            <BiRadioCircleMarked size={45} />
                            :
                            <BiRadioCircle size={45} />
                        }
                        <BsFillCreditCard2BackFill size={65} color="#feb800" style={{ marginLeft: '12px', marginRight: '15px' }} />
                        <div className='payBtnText'>Card Payment</div>
                    </div>
                </div>

                {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div onClick={() => {
                        setAppleBtn(true)
                        setCardBtn(false)
                    }}
                        className='payOpt-container'>
                        {appleBtn
                            ?
                            <BiRadioCircleMarked size={45} />
                            :
                            <BiRadioCircle size={45} />
                        }
                        <FaCcApplePay size={65} style={{ marginLeft: '12px', marginRight: '15px' }} />
                        <div className='payBtnText'>Apple Pay</div>
                    </div>
                </div> */}


                <div className='fxdBottoms'>
                    {
                        cardOptError
                            ?
                            <div className='selectionErrorText'>Please select a payment option.</div>
                            :
                            <div className='selectionErrorText'>&nbsp;</div>
                    }
                    <div className='BottomSideParent'>
                        <div className='BottomSidetext'>
                            Total
                        </div>
                        <div className='BottomSidetext'>
                            {menu?.restaurantInfo?.monetary_symbol}{" "}

                            {filteredDataById[0]?.grand_total.toString().length > 10
                                ?
                                filteredDataById[0]?.grand_total.toString().substring(0, 8) + `..`
                                :
                                filteredDataById[0]?.grand_total.toString().includes('.')
                                    ?
                                    filteredDataById[0]?.grand_total
                                    :
                                    filteredDataById[0]?.grand_total + `.00`
                            }


                            {/* {main?.fetchOrderDetails?.data?.grand_total.toString().length > 10
                                ?
                                main?.fetchOrderDetails?.data?.grand_total.toString().substring(0, 8) + `..`
                                :
                                main?.fetchOrderDetails?.data?.grand_total.toString().includes('.')
                                    ?
                                    main?.fetchOrderDetails?.data?.grand_total
                                    :
                                    main?.fetchOrderDetails?.data?.grand_total + `.00`
                            } */}

                        </div>
                    </div>

                    {cardBtn
                        ?
                        (
                            <Button
                                onClick={sendpaymentreq}
                                style={{
                                    backgroundColor: "#000000",
                                    color: "white",
                                    width: "100%",
                                    padding: "10px",
                                    textTransform: "none",
                                    fontSize: "17px",
                                    borderRadius: "10px",
                                    fontFamily: "Poppins",
                                    letterSpacing: '0.1rem',
                                    height: '3.7rem'
                                }}>
                                {payLoader
                                    ?
                                    <div className="loader"></div>
                                    :
                                    <>
                                        <TbCreditCard size={37} style={{ marginRight: "16px" }} />
                                        Proceed to pay
                                    </>
                                }
                            </Button>
                        )
                        :
                        (
                            <Button
                                onClick={() => setCardOptError(true)}
                                style={{
                                    backgroundColor: "#000000",
                                    color: "white",
                                    width: "100%",
                                    padding: "10px",
                                    textTransform: "none",
                                    fontSize: "17px",
                                    borderRadius: "10px",
                                    fontFamily: "Poppins",
                                    letterSpacing: '0.1rem',
                                    height: '3.7rem'
                                }}>
                                <TbCreditCard size={37} style={{ marginRight: "16px" }} />
                                Proceed to pay
                            </Button>
                        )
                    }
                </div>

            </div>

        </div>
    )
}

export default PaymentOption