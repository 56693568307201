import React from 'react'
import "./largeScreenErrorPage.css"
import { FcTwoSmartphones } from 'react-icons/fc';
import zottoIcon from "./zottoIcon.png"
import { useEffect } from 'react';
import { useState } from 'react';

const LargeScreenErrorPage = () => {
    const [rendering, setRendering] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setRendering(true)
        }, 1000);
    }, [])


    return (
        rendering
            ?
            < div className='parent-container' >
                {/* <p style={{ fontSize: "20px", textAlign: "center", marginTop: "20%", fontFamily: 'Poppins' }}>
                Sorry This website is just for mobile phones ! (refresh page once, if
                already using phone)
            </p> */}

                < div className='phoneIcon' >
                    <FcTwoSmartphones size={100} />
                </div >

                <div className='sorryText'>
                    <span className="fades-in" id="S">S</span>
                    <span className="fades-in" id="O">o</span>
                    <span className="fades-in" id="R1">r</span>
                    <span className="fades-in" id="R2">r</span>
                    <span className="fades-in" id="Y">y</span>
                    <span className="fades-in" id="dot1">.</span>
                    <span className="fades-in" id="dot2">.</span>
                </div>

                <div className='lastMsgs'>
                    This website is just for mobile phones. Please, refresh the page once, if already using phone.
                </div>
            </div >
            : null
    )
}

export default LargeScreenErrorPage