import React from 'react'
import "./notFound404Page.css"
import sad404 from "./sad404.svg"
import zottoIcon from "./zottoIcon.png"

const NotFound404Page = () => {
  return (
    <div className='parent'>
      <div className='img-div'>
        <img className='sadImg' alt='NotFound-Img' src={sad404} />
      </div>

      <div className='errs'>
        <span className="fade-in" id="digit1">P</span>
        <span className="fade-in" id="digit2">A</span>
        <span className="fade-in" id="digit3">G</span>
        <span className="fade-in" id="digit4">E</span>

        <span className="fade-in" id="digit5">4</span>
        <span className="fade-in" id="digit6">0</span>
        <span className="fade-in" id="digit7">4</span>
      </div>

      <div className='errs-msgs'>
        The page you were looking for could not be found.
      </div>

      <div className="zotto-logos">
          <img
            className='logo-measurements'
            src={zottoIcon}
            alt='zotto-Logo'
          />
        </div>
    </div>
  )
}

export default NotFound404Page