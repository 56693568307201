export const FETCH_RESTURANT_INFORMATION_REQUEST =
  "FETCH_RESTURANT_INFORMATION_REQUEST";
export const FETCH_RESTURANT_INFORMATION_SUCCESS =
  "FETCH_RESTURANT_INFORMATION_SUCCESS";
export const FETCH_RESTURANT_INFORMATION_FAILURE =
  "FETCH_RESTURANT_INFORMATION_FAILURE";
export const FETCH_CATEGORIES_REQUEST = "FETCH_CATEGORIES_REQUEST";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";
export const FETCH_MENU_ITEMS_REQUEST = "FETCH_MENU_ITEMS_REQUEST";
export const FETCH_MENU_ITEMS_SUCCESS = "FETCH_MENU_ITEMS_SUCCESS";
export const FETCH_MENU_ITEMS_FAILURE = "FETCH_MENU_ITEMS_FAILURE";

// PIZZA'S
export const FETCH_PIZZAS_REQUEST = "FETCH_PIZZAS_REQUEST";
export const FETCH_PIZZAS_SUCCESS = "FETCH_PIZZAS_SUCCESS";
export const FETCH_PIZZAS_FAILURE = "FETCH_PIZZAS_FAILURE";
export const DISPLAY_PIZZAS = "DISPLAY_PIZZAS";
export const FETCH_TOPPNGS_REQUEST = "FETCH_TOPPNGS_REQUEST";
export const FETCH_TOPPNGS_SUCCESS = "FETCH_TOPPNGS_SUCCESS";
export const FETCH_TOPPNGS_FAILURE = "FETCH_TOPPNGS_FAILURE";

export const SET_LOADING_DISPLAY = "SET_LOADING_DISPLAY";
export const SET_OVERLAY_DISPLAY = "SET_OVERLAY_DISPLAY";
export const FILTER_MENU_ITEMS = "FILTER_MENU_ITEMS";
export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SET_SELECTED_CATEGORY_ID = "SET_SELECTED_CATEGORY_ID";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const FETCH_ALL_FORCED_MODIFIERS_REQUEST =
  "FETCH_ALL_FORCED_MODIFIERS_REQUEST";
export const FETCH_ALL_FORCED_MODIFIERS_SUCCESS =
  "FETCH_ALL_FORCED_MODIFIERS_SUCCESS";
export const FETCH_ALL_FORCED_MODIFIERS_FAILURE =
  "FETCH_ALL_FORCED_MODIFIERS_FAILURE";

export const FETCH_ALL_OPTIONAL_MODIFIERS_REQUEST =
  "FETCH_ALL_OPTIONAL_MODIFIERS_REQUEST";
export const FETCH_ALL_OPTIONAL_MODIFIERS_SUCCESS =
  "FETCH_ALL_OPTIONAL_MODIFIERS_SUCCESS";
export const FETCH_ALL_OPTIONAL_MODIFIERS_FAILURE =
  "FETCH_ALL_OPTIONAL_MODIFIERS_FAILURE";

export const PLACE_ORDER_REQUEST = "PLACE_ORDER_REQUEST";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILURE = "PLACE_ORDER_FAILURE";

export const INITIALIZE_STORE_STATE = "INITIALIZE_STORE_STATE";
export const CLEAR_STATE = "CLEAR_STATE";
export const CLEAR_MENU_STATE = "CLEAR_MENU_STATE";

// fetch happy hours
export const FETCH_HAPPY_HOURS_REQUEST = "FETCH_HAPPY_HOURS_REQUEST";
export const FETCH_HAPPY_HOURS_SUCCESS = "FETCH_HAPPY_HOURS_SUCCESS";
export const FETCH_HAPPY_HOURS_FAILURE = "FETCH_HAPPY_HOURS_FAILURE";
export const ADD_HAPPY_HOURS_ITEMS = "ADD_HAPPY_HOURS_ITEMS";
export const DISPLAY_HAPPY_HOURS = "DISPLAY_HAPPY_HOURS";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";

export const DISPLAY_ADDRESS_MODAL = "DISPLAY_ADDRESS_MODAL";
export const SET_COMMENT = "SET_COMMENT";
export const CHANGE_CHOOSEN_CATEGORY = "CHANGE_CHOOSEN_CATEGORY";

export const FETCH_COUPONS_REQUEST = "FETCH_COUPONS_REQUEST";
export const FETCH_COUPONS_SUCCESS = "FETCH_COUPONS_SUCCESS";
export const FETCH_COUPONS_FAILURE = "FETCH_COUPONS_FAILURE";

export const CHECK_COUPONS_REQUEST = "CHECK_COUPONS_REQUEST";
export const CHECK_COUPONS_SUCCESS = "CHECK_COUPONS_SUCCESS";
export const CHECK_COUPONS_FAILURE = "CHECK_COUPONS_FAILURE";

export const REDEEM_COUPON_REQUEST = "REDEEM_COUPON_REQUEST";
export const REDEEM_COUPON_SUCCESS = "REDEEM_COUPON_SUCCESS";
export const REDEEM_COUPON_FAILURE = "REDEEM_COUPON_FAILURE";
export const PLACE_ORDER_SUCCESSFUL = "PLACE_ORDER_SUCCESSFUL";
export const CLEAR_CART = "CLEAR_CART";

