export const FETCH_RESTURANT_INFORMATION_REQUEST =
  "FETCH_RESTURANT_INFORMATION_REQUEST";
export const FETCH_RESTURANT_INFORMATION_SUCCESS =
  "FETCH_RESTURANT_INFORMATION_SUCCESS";
export const FETCH_RESTURANT_INFORMATION_FAILURE =
  "FETCH_RESTURANT_INFORMATION_FAILURE";
export const SET_SELECTED_RESTAURANT = "SET_SELECTED_RESTAURANT";

// fetch restaurant list
export const FETCH_RESTURANT_LIST_REQUEST = "FETCH_RESTURANT_LIST_REQUEST";
export const FETCH_RESTURANT_LIST_SUCCESS = "FETCH_RESTURANT_LIST_SUCCESS";
export const FETCH_RESTURANT_LIST_FAILURE = "FETCH_RESTURANT_LIST_FAILURE";

// fetch restaurant Delivery range
export const FETCH_RESTURANT_DELIVERY_RANGE_REQUEST =
  "FETCH_RESTURANT_DELIVERY_RANGE_REQUEST";
export const FETCH_RESTURANT_DELIVERY_RANGE_SUCCESS =
  "FETCH_RESTURANT_DELIVERY_RANGE_SUCCESS";
export const FETCH_RESTURANT_DELIVERY_RANGE_FAILURE =
  "FETCH_RESTURANT_DELIVERY_RANGE_FAILURE";

export const SET_COMMENT_FOR_RESTAURANT = "SET_COMMENT_FOR_RESTAURANT";

export const SET_DELIVERY_COST = "SET_DELIVERY_CHARGES";

export const SET_PHONE_CODE = "SET_PHONE_CODE";

export const FETCH_CLOSED_INFORMATION_REQUEST =
  "FETCH_CLOSED_INFORMATION_REQUEST";
export const FETCH_CLOSED_INFORMATION_SUCCESS =
  "FETCH_CLOSED_INFORMATION_SUCCESS";
export const FETCH_CLOSED_INFORMATION_FAILURE =
  "FETCH_CLOSED_INFORMATION_FAILURE";

export const SET_DESTINATION_COORDINATES = "SET_DESTINATION_COORDINATES";

export const FETCH_OPEN_ORDER = "FETCH_OPEN_ORDER";

export const FETCH_ORDER_DETAILS = "FETCH_ORDER_DETAILS";

export const STORE_OPEN_ORDER_ID = "STORE_OPEN_ORDER_ID";

export const STORE_FILTER_DRAW_DATA = "STORE_FILTER_DRAW_DATA";

export const CHECK_ITS_BACK_FROM_LOGO = "CHECK_ITS_BACK_FROM_LOGO";

export const STORE_TABLE_ID = "STORE_TABLE_ID";

export const FIRST_TOKEN = "FIRST_TOKEN";

export const ALL_TABLE_NAMES_INFO = "ALL_TABLE_NAMES_INFO";





