import React from "react"
import { useSelector, useDispatch } from "react-redux"
import zottoLogo from "./zottoLogo.png"
import zottoIcon from "./zottoIcon.png"
import FacebookIcon from "./facebook@3x.png"
import TwitterIcon from "./twitter@3x.png"
import InstagramIcon from "./ig@3x.png"
import {
  setUserLoggedOut,
  showLoginFormMethod,
  showRegisterFormMethod
} from "../../state-management/user/actions"

import "./Footer.css"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { Facebook, Instagram, Phone, Twitter } from "@material-ui/icons"

const Footer = () => {
  const menu = useSelector((state) => state.menu)
  const main = useSelector((state) => state.main)
  const dispatch = useDispatch()
  var d = new Date()
  var n = d.getFullYear()


  function renderBusinessHours() {
    return (
      menu.restaurantInfo.business_hours &&
      menu.restaurantInfo.business_hours.map((business, i) => {
        const businessFromSplit = business.from.split(":")
        const businessToSplit = business.to.split(":")

        const businessFrom = `${businessFromSplit[0]}:${businessFromSplit[1]}`
        const businessTo = `${businessToSplit[0]}:${businessToSplit[1]}`

        let businessHour = `${businessFrom} - ${businessTo}`

        if (businessHour === "00:00 - 00:00") {
          businessHour = "Closed"
        }
        return (
          <div className="footer-normal-text" style={{ display: "flex", justifyContent: "space-between", width: "70%" }} key={i}>
            <div>{business.day_name}</div>
            <div>{businessHour}</div>
          </div>
        )
      })
    )
  }

  const showLogin = () => {
    dispatch(showLoginFormMethod())
  }

  const showRegister = () => {
    dispatch(showRegisterFormMethod())
  }

  return (
    <footer style={{ background: "#F3F2F7" }}>

      <div className='foot-divs'>
        <div className="footer-headings">
          Powered by 
        </div>

        <div className="zotto-logos-div">
          <img
            className='logo-measurements'
            src={zottoIcon}
            alt='zotto-Logo'
          />
        </div>

        {/* <div className="footer-headings" style={{ marginTop: "2.4rem" }}>
          Terms and conditions
        </div> */}

        {/* <div className="footer-headings" style={{ marginTop: "2.4rem" }}>
          ADDRESS
        </div>

        <div className="footer-normal-text" style={{ marginTop: "12px" }}>
          <LocationOnIcon /> {menu.restaurantInfo.address} {menu.restaurantInfo.city}
        </div>

        <div className="footer-normal-text" style={{ marginLeft: "30px" }}>
          {menu.restaurantInfo.country}
        </div>

        <div className="footer-normal-text" style={{ marginLeft: "30px" }}>
          {menu.restaurantInfo.zipcode}
        </div>

        <div className="footer-headings" style={{ marginTop: "2.4rem" }}>
          CONTACT
        </div>

        <div className="footer-normal-text" style={{ marginTop: "12px" }}>
          <Phone /> +{menu.restaurantInfo.phone_code}-{menu.restaurantInfo.phone}
        </div>

        <div className="footer-headings" style={{ marginTop: "2.4rem", marginBottom: "12px" }}>
          BUSINESS HOURS
        </div>
        {renderBusinessHours()} */}

        {/* <div style={{ display: "flex", justifyContent: "center", marginTop: "3rem" }}>
          <div>
            <img
              src={FacebookIcon}
              style={{ height: "25px", width: "25px", marginRight: "12px" }}
            />

            <img
              src={TwitterIcon}
              style={{ height: "25px", width: "25px", marginRight: "12px" }}
            />

            <img
              src={InstagramIcon}
              style={{ height: "25px", width: "25px" }}
            />
          </div>
        </div> */}
      </div>

    </footer>
  )
}

export default Footer
