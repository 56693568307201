import React, { useState } from "react";
import { Checkbox } from "antd";
import { defaultMemoize } from "reselect";
import "./OptionalModifiers.css";
import "../../Modals/PizzaModal/PizzaModal.css";

export const OptionalModifier = ({
  optionalModifier,
  currency,
  getModifierPrice,
  onSelectionChange,
  activeIsDeal,
  isDealfirstItm
}) => {

  const [selectedOptions, setSelectedOptions] = useState([]);

  function getTotalPrice(updatedItems) {
    return updatedItems.reduce(
      (acc, item) => acc + (item.isFree ? 0 : Number(item.price)),
      0
    );
  }

  function isMaxLimit(selectedModifiers, catDetails) {
    if (Number(catDetails.maxLimit) === 0) {
      return false;
    }

    let counter = 0;

    for (let i = 0; i < selectedModifiers.length; i++) {
      if (selectedModifiers[i].omCatId === catDetails.omCatId) {
        counter++;
      }
    }
    if (counter >= catDetails.maxLimit) {
      return true;
    }

    return false;
  }

  function setFreeLimitPrices(allItems) {
    // update free limit items prices
    let sortedFilterItems = [];

    const priceSortedItems = allItems.sort((a, b) => a.price - b.price);

    optionalModifier &&
      optionalModifier.map((op) => {
        const freeLimit = Number(op.free_limit);
        const items = priceSortedItems.filter(
          (i) => i.omCatId === op.om_cat_id
        );

        const limit = freeLimit > items.length ? items.length : freeLimit;

        for (let i = 0; i < limit; i++) {
          items[i].isFree = true;
        }
        for (let i = limit; i < items.length; i++) {
          items[i].isFree = false;
        }
        sortedFilterItems = [...sortedFilterItems, ...items];
      });

    return sortedFilterItems;
  }

  const getDealTotalPrice = (item) => {
    let totals = 0
    item.map((val) => {
      totals += Number(val?.price)
    })

    let selectedMod = []

    for (var i = 0; i < item.length; i++) {
      for (var j = 0; j < item[i].addon_price.length; j++) {
        selectedMod.push(item[i].addon_price[j])
      }
    }

    let findItmById = selectedMod.filter(object => object.fm_item_id == isDealfirstItm);

    findItmById.map((val) => {
      totals += Number(val?.fm_item_price)
    })

    // console.log("its item here", totals)

    return totals
  }

  const handleChange = (checked, modifier) => {
    let updatedItems = [];

    let withFreeLimitItems = [];

    if (checked) {
      updatedItems = [...selectedOptions, modifier];
    } else {
      updatedItems = selectedOptions.filter(({ id }) => id !== modifier.id);
    }

    withFreeLimitItems = setFreeLimitPrices(updatedItems);

    console.log("its forcedModifiers L here", activeIsDeal)

    setSelectedOptions(withFreeLimitItems);

    // check if max limit has crossed for optional category items

    if (activeIsDeal == "active") {
      onSelectionChange(updatedItems, getDealTotalPrice(updatedItems));
    } else {
      isMaxLimit(withFreeLimitItems, modifier);
      onSelectionChange(withFreeLimitItems, getTotalPrice(withFreeLimitItems));
    }
  };

  return (
    optionalModifier &&
    optionalModifier.map((op, i) => (
      <div className="opt-parent">
        <div className="opt-child">
          <div
            key={i}
            style={{ paddingTop: "5px" }}
          >
            {/* {console.log("op", op)} */}
            <p className="opt-head">{op?.om_cat_name}</p>

            <p
              className="modifier-category-instruction"
              style={{
                fontSize: "14px",
                marginTop: "-10px",
                color: "#968EA1",
                fontFamily: 'Poppins'
              }}
            >
              {op.free_limit > 0
                ? `Cheapest ${op.free_limit} options will be free`
                : null}
              {op.free_limit > 0 && op.max_limit > 0 ? ", " : null}
              {op.max_limit > 0
                ? `You can choose max upto ${op.max_limit} options`
                : null}
            </p>

            <OptionalModifierOptions
              currency={currency}
              getModifierPrice={getModifierPrice}
              isMaxLimitReached={isMaxLimit}
              selectedOptions={selectedOptions}
              cat={op}
              onChange={handleChange}
            />
            <br />
          </div>
        </div>
      </div>
    ))
  );
};

const OptionalModifierOptions = (props) => {
  const catDetails = {
    omCatId: props.cat.om_cat_id,
    maxLimit: props.cat.max_limit,
  };

  const isMaxLimitReached = props.isMaxLimitReached(
    props.selectedOptions,
    catDetails
  );

  return (
    <div style={{ width: '113%' }}>

      {props.cat.items.map((op, i) => {
        const canSetDisabled =
          isMaxLimitReached &&
          !props.selectedOptions.some(
            ({ id, omCatId }) => id === op.id && omCatId === op.omCatId
          );
        const currentItem = props.selectedOptions.find(
          (item) => item.id === op.id
        );

        const strikeOutFree =
          currentItem && currentItem.isFree ? "free-amount" : "";

        return (
          <Checkbox
            style={{
              fontFamily: 'Poppins',
              fontSize: '14px',
              letterSpacing: '0.6px',
              fontWeight: '500',
              backgroundColor: '#fff',
              color: '#000',
              borderRadius: '20px',
              paddingTop: '6px',
              paddingBottom: '6px',
              paddingLeft: '5px',
              paddingRight: '2px',
              marginLeft: '5px',
              marginBottom: '6px',
              marginTop: '6px',
            }}
            disabled={canSetDisabled}
            onChange={({ target: { checked } }) =>
              props.onChange(checked, JSON.parse(JSON.stringify(op)))
            }
          >
            {op.name || op.om_item_name}
          </Checkbox>
        );
      })}
    </div>
  );
};
export default OptionalModifier;




// old code
{/* <section
  key={i}
  style={{
    paddingTop: "10px",
    paddingBottom: "10px",
  }}
>

  <Checkbox
    style={{ fontFamily: 'Poppins', fontSize: '15px', letterSpacing: '0.5px' }}
    disabled={canSetDisabled}
    onChange={({ target: { checked } }) =>
      props.onChange(checked, JSON.parse(JSON.stringify(op)))
    }
  >
    {op.name}
  </Checkbox>

  <span
    style={{ float: 'right', marginRight: "15px", fontFamily: 'Poppins', fontSize: '15px', letterSpacing: '0.5px' }}
    className={`modfier-amount ${strikeOutFree}`}
  >
    ({props.currency} {props.getModifierPrice(op.price)})
  </span>
</section> */}