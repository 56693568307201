import React, { useEffect, useState } from 'react'
import "./paymentsOptionsPage.css"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom";
import { clearMenuState } from "../../state-management/menu/actions"
import { BiRadioCircleMarked, BiRadioCircle } from 'react-icons/bi';
import { BsFillCreditCard2BackFill } from 'react-icons/bs';
import { FaCcApplePay } from 'react-icons/fa';
import { TbCreditCard } from 'react-icons/tb';
import Button from "@material-ui/core/Button";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import alertImg from "./alert.png"
import { useHistory } from "react-router";
import sha256 from "js-sha256";
import axios from "axios";
import { fetchOrderDetails, firstToken } from '../../state-management/main/actions';
import { CgClose } from 'react-icons/cg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 330,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 3,
    borderRadius: '20px',
};

const PaymentsOptionsPage = () => {
    const History = useHistory()
    const dispatch = useDispatch()
    const params = useParams();
    const main = useSelector((state) => state.main)
    const menu = useSelector((state) => state.menu)

    const [cardButton, setCardButton] = useState(true)
    const [appleBtn, setAppleBtn] = useState(false)
    const [payLoader, setPayLoader] = useState(false)
    const [cardSelectionError, setCardSelectionError] = useState(false)
    const [open, setOpen] = useState(false);
    const [ordersession, setordersession] = useState()
    const [orderid, setorderid] = useState()
    const [waitforthedata, setwaitforthedata] = useState(true)
    const [cancelBtnLoader, setCancelBtnLoader] = useState(false)
    const [openOrdersData, setOpenOrdersData] = useState([])
    const [keepOrdrLdr, setKeepOrdrLdr] = useState(false)

    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => setOpen(false);

    // console.log("menussssssss", menu);

    const getOpenTableOrders = () => {
        var axios = require('axios');
        var data = JSON.stringify({
            "table_id": main.tableId,
            "order_status": "OPEN"
        });

        var config = {
            method: 'post',
            url: 'https://api.ciboapp.com/api/selforder/getordersqr',
            headers: {
                'Authorization': 'Basic Wm90dG86MTIzNDU=',
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setOpenOrdersData(response?.data?.data?.filter(n => n.order_id == params.orderid.slice(8)));
                // dispatch(fetchOpenOrder(response.data));
                setwaitforthedata(false)
                // setTimeout(() => {
                //     setwaitforthedata(false);
                // }, 500);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    // const getOpenOrdersDetails = () => {
    //     var axios = require('axios');
    //     var data = JSON.stringify({
    //         "orderId": params.orderid.slice(8),
    //         "restId": main.restId
    //     });

    //     var config = {
    //         method: 'post',
    //         url: 'https://api.ciboapp.com/api/selforder/getOrderById',
    //         headers: {
    //             'Authorization': 'Basic Wm90dG86MTIzNDU=',
    //             'Content-Type': 'application/json'
    //         },
    //         data: data
    //     };

    //     axios(config)
    //         .then(function (response) {
    //             console.log("fetch openorders", response.data);
    //             dispatch(fetchOrderDetails(response.data));
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    useEffect(() => {
        // console.log("checking", main)
        setTimeout(async function () {
            // getOpenOrdersDetails();
            getOpenTableOrders();

            const urlParams = new URLSearchParams(window.location.search)
            var order_session = ""
            for (const entry of urlParams.entries()) {
                order_session = entry[1].slice("30")
            }
            setorderid(order_session)


            // dispatch(clearMenuState())
            localStorage.removeItem("paymentType")
            localStorage.removeItem("deliveryType")
            localStorage.removeItem("checkoutState")
            localStorage.removeItem("dtime")
            localStorage.removeItem("ptime")
        }, 5000)
    }, [])

    // Pay Now
    const sendpaymentrequest = async () => {
        // var errorurl = `https://ciboapp.com/feedmii/?/restId=${menu.restaurantInfo.restaurant_id}/paymentfailed`
        // var failedurl = `https://ciboapp.com/feedmii/?/restId=${menu.restaurantInfo.restaurant_id}/paymentfailed`
        // var accepturl = `https://ciboapp.com/feedmii/?/restId=${menu.restaurantInfo.restaurant_id}/ordersuccess?orderid=${main.fetchOrderDetails.data.order_id}`


        var errorurl = `https://ciboapp.com/restId=${menu.restaurantInfo.restaurant_id}/paymentfailed`

        // var failedurl = `https://ciboapp.com/restId=${menu.restaurantInfo.restaurant_id}/paymentfailed`
        var failedurl = `https://menu.ciboapp.com/restId=${menu.restaurantInfo.restaurant_id}/${main.tableId}/paymentfailed`

        var accepturl = `https://menu.ciboapp.com/PaySuccess/restid=${menu.restaurantInfo.restaurant_id}/tid=${main.tableId}`
        var callbackurl = "https://ciboapp.com/api/mobileApi/v2/app/callback" //old URL
        // var callbackurl = "https://api.ciboapp.com/api/selforder/qrCallBack" //new URL

        var mkey = menu.restaurantInfo.merchant_key
        var sec = menu.restaurantInfo.secret
        var crncy = main.selectedRestaurant.currency
        var userid = "123"
        var type = ""
        if (cardButton) {
            setCardSelectionError(false)
            type = "1"
        } else {
            setCardSelectionError(true)
        }

        let str = `${openOrdersData[0]?.grand_total}${callbackurl}${crncy}${errorurl}${failedurl}${mkey}${openOrdersData[0]?.order_id}${accepturl}${sec}`

        let mac = sha256(str)

        //live https://api.zotto.z-payments.com/api/payment-link
        //live merchant : 2533554646411
        //live secret : eAfg21swu0y211

        //test https://paymentz.z-pay.co.uk/api/payment-link
        //test merchant : 45455514421
        //test secret : eAfg21swu0y2

        // console.log("Its mac add", mac)

        const paymentdata = {
            name: openOrdersData[0]?.delivery_firstname,
            email: openOrdersData[0]?.delivery_email,
            phone: openOrdersData[0]?.delivery_mobile,
            user_id: userid,
            order_id: `${openOrdersData[0]?.order_id}`,
            merchant_key: mkey,
            amount: `${openOrdersData[0]?.grand_total}`,
            currency: main.selectedRestaurant.currency,
            mac: mac,
            redirect_type: type,
            accept_url: accepturl,
            failed_url: failedurl,
            error_url: errorurl,
            callback_url: callbackurl,
            // back_url: backurl
        }

        setPayLoader(true);
        dispatch(firstToken({ "newToken": '' }));
        localStorage.setItem('localNewToken', '');
        dispatch(clearMenuState())
        axios
            .post("https://api.zotto.z-payments.com/api/payment-link", paymentdata)  // Live URL
            .then((response) => {
                // console.log("Payment Successful", response.data);
                setPayLoader(false);
                dispatch(clearMenuState())
                window.location.href = response.data.data.paymentLink
            }
            )
            .catch((error) => {
                console.log("we have payment error", error);
                setPayLoader(false);
                History.push(
                    `/paymentFailed/orderId=${params.orderid.slice(8)}`
                )
            })
    }






    // const sendpaymentrequestDemo = async () => {var errorurl = `https://menu.ciboapp.com/restId=${menu.restaurantInfo.restaurant_id}/paymentfailed`
    //     var failedurl = `https://menu.ciboapp.com/restId=${menu.restaurantInfo.restaurant_id}/paymentfailed`
    //     var backurl = `https://www.google.com`
    //     var accepturl = `https://menu.ciboapp.com/restId=${menu.restaurantInfo.restaurant_id}/ordersuccess?orderid=${main.fetchOrderDetails.data.order_id}`
    //     var callbackurl = "https://menu.ciboapp.com/api/mobileApi/v2/app/callback" //old URL
    //     // var callbackurl = "https://api.ciboapp.com/api/selforder/qrCallBack" //new URL

    //     var mkey = menu.restaurantInfo.merchant_key
    //     var sec = menu.restaurantInfo.secret
    //     var crncy = main.selectedRestaurant.currency
    //     var userid = "123"
    //     var type = ""
    //     if (cardButton) {
    //         setCardSelectionError(false)
    //         type = "1"
    //     } else {
    //         setCardSelectionError(true)
    //     }

    //     let str = `${main.fetchOrderDetails.data.grand_total}${callbackurl}${crncy}${errorurl}${failedurl}${mkey}${main.fetchOrderDetails.data.order_id}${accepturl}${sec}`

    //     let mac = sha256(str)

    //     const paymentdata = {
    //         name: main.fetchOrderDetails.data.delivery_firstname,
    //         email: main.fetchOrderDetails.data.delivery_email,
    //         phone: main.fetchOrderDetails.data.delivery_mobile,
    //         user_id: userid,
    //         order_id: `${main.fetchOrderDetails.data.order_id}`,
    //         merchant_key: mkey,
    //         amount: `${main.fetchOrderDetails.data.grand_total}`,
    //         currency: main.selectedRestaurant.currency,
    //         mac: mac,
    //         redirect_type: type,
    //         accept_url: accepturl,
    //         failed_url: failedurl,
    //         error_url: errorurl,
    //         callback_url: callbackurl,
    //     }

    //     setPayLoader(true);
    //     axios
    //         .post("https://api.zotto.z-payments.com/api/payment-link", paymentdata)  // Live URL
    //         .then((response) => {
    //             // console.log("Payment Successful", response.data);
    //             setPayLoader(false);    
    //             window.location.href = response.data.data.paymentLink
    //         }
    //         )
    //         .catch((error) => {
    //             console.log("we have payment error", error);
    //             setPayLoader(false);
    //             History.push(
    //                 `/paymentFailed/orderId=${main.fetchOrderDetails.data.order_id}`
    //             )
    //         })
    // }






    const orderCancelation = () => {
        var axios = require('axios');
        var data = JSON.stringify({
            "order_id": params.orderid.slice(8)
        });

        var config = {
            method: 'post',
            url: 'https://api.ciboapp.com/api/selforder/cancelOrderQR',
            headers: {
                'Authorization': 'Basic Wm90dG86MTIzNDU=',
                'Content-Type': 'application/json'
            },
            data: data
        };

        setOpen(false);
        setCancelBtnLoader(true);
        dispatch(clearMenuState())
        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                setCancelBtnLoader(false);
                // alert("Order Canceled.");
                // History.goBack()
                History.push(
                    `/orderCancelled/restid=${main.restId}/tid=${main.tableId}/orderid=${params.orderid.slice(8)}`
                )
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const keepTheOrder = () => {
        var axios = require('axios');
        var data = JSON.stringify({
            "order_id": params.orderid.slice(8)
        });

        var config = {
            method: 'post',
            url: 'https://api.ciboapp.com/api/selforder/cancelOrderQR',
            headers: {
                'Authorization': 'Basic Wm90dG86MTIzNDU=',
                'Content-Type': 'application/json'
            },
            data: data
        };

        setKeepOrdrLdr(true);
        axios(config)
            .then(function (response) {
                setKeepOrdrLdr(false);
                handleModalClose()
                History.push(
                    `/restId=${menu.restaurantInfo.restaurant_id}&tid=${main.tableId}/checkout`
                )
            })
            .catch(function (error) {
                console.log(error);
                History.push(
                    `/restId=${menu.restaurantInfo.restaurant_id}&tid=${main.tableId}/checkout`
                )
            });
    }

    const getTotalsAmt = (prc) => {
        let madeTtlDcml = '';
        if (prc.toString().includes('.')) {
            madeTtlDcml = prc?.toString()
        } else {
            madeTtlDcml = prc + '.00'
        }
        let splitTotalArr = madeTtlDcml?.split('.')

        if (splitTotalArr[1]?.length < 2) {
            return splitTotalArr[0] + '.' + splitTotalArr[1] + '0'
        } else {
            return splitTotalArr[0] + '.' + splitTotalArr[1]?.substring(0, 2)
        }
    }

    return (
        <>
            {
                waitforthedata
                    ?
                    <div className="wait-ldr-parent">
                        <div className="wait-ldr-txt-div">
                            <div class="itsLoader"></div>

                            {/* <div className="ring">
                                Please wait
                                <span className='itsNewLdr'></span>
                            </div> */}

                            <div style={{ marginTop: '20px', fontSize: "28px", fontWeight: 600, fontFamily: 'Poppins', letterSpacing: '0.8px' }}>
                                Please wait ...!
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div>
                            <Modal
                                style={{ backgroundColor: '#f6f6f6' }}
                                open={open}
                                onClose={handleModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description">
                                <Box sx={style}>
                                    <CgClose onClick={handleModalClose} size={25} />
                                    <div className='alerts-img-cont'>
                                        <img src={alertImg} className='alt-imgs' />
                                    </div>

                                    <div className="modals-alt-msgs">
                                        Do you want to end the current process?
                                    </div>

                                    <div className="modal-msgs">
                                        Your order will not be placed. If you end the current process.
                                    </div>

                                    <div style={{ marginTop: '2rem' }}>
                                        <Button
                                            onClick={keepTheOrder}
                                            style={{
                                                backgroundColor: "#000000",
                                                color: "white",
                                                width: "100%",
                                                padding: "10px",
                                                textTransform: "none",
                                                fontSize: "15px",
                                                borderRadius: "15px",
                                                fontFamily: "Poppins",
                                                letterSpacing: '0.7px',
                                                height: '3.2rem'
                                            }}>
                                            {keepOrdrLdr
                                                ?
                                                <div className="cancelBtnLdr"></div>
                                                :
                                                "Keep the order"}
                                        </Button>

                                        <Button
                                            onClick={orderCancelation}
                                            style={{
                                                backgroundColor: "#ffffff",
                                                color: "#000000",
                                                width: "100%",
                                                padding: "10px",
                                                textTransform: "none",
                                                fontSize: "15px",
                                                borderRadius: "15px",
                                                marginTop: '6px',
                                                fontFamily: "Poppins",
                                                letterSpacing: '0.7px',
                                                height: '3.2rem'
                                            }}>
                                            Cancel anyway
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>
                        </div>

                        <div className="payPage-container">
                            <div className='payPage-text'>
                                Payment
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div onClick={() => {
                                    setCardButton(true)
                                    setCardSelectionError(false)
                                }}
                                    className='payPageOpts-container'>
                                    {cardButton
                                        ?
                                        <BiRadioCircleMarked size={45} />
                                        :
                                        <BiRadioCircle size={45} />
                                    }
                                    <BsFillCreditCard2BackFill size={65} color="#feb800" style={{ marginLeft: '12px', marginRight: '15px' }} />
                                    <div className='payBtnTexts'>Card Payment</div>
                                </div>
                            </div>

                            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div onClick={() => {
                        setAppleBtn(true)
                        setCardButton(false)
                    }}
                        className='payPageOpts-container'>
                        {appleBtn
                            ?
                            <BiRadioCircleMarked size={45} />
                            :
                            <BiRadioCircle size={45} />
                        }
                        <FaCcApplePay size={65} style={{ marginLeft: '12px', marginRight: '15px' }} />
                        <div className='payBtnTexts'>Apple Pay</div>
                    </div>
                </div> */}


                            <div className='payPageFxdBottoms'>
                                {
                                    cardSelectionError
                                        ?
                                        <div className='slctnErrText'>Please select a payment option.</div>
                                        :
                                        <div className='slctnErrText'>&nbsp;</div>
                                }
                                <div className='BottomTtlParents'>
                                    <div className='BottomTtlText'>
                                        Total
                                    </div>
                                    <div className='BottomTtlText'>
                                        {menu?.restaurantInfo?.monetary_symbol}
                                        {getTotalsAmt(openOrdersData[0]?.grand_total)}

                                        {/* {openOrdersData[0]?.grand_total.toString().length > 10
                                            ?
                                            openOrdersData[0]?.grand_total.toString().substring(0, 8) + `..`
                                            :
                                            openOrdersData[0]?.grand_total.toString().includes('.')
                                                ?
                                                openOrdersData[0]?.grand_total
                                                :
                                                openOrdersData[0]?.grand_total + `.00`
                                        } */}
                                    </div>
                                </div>

                                {cardButton
                                    ?
                                    (
                                        <Button
                                            onClick={sendpaymentrequest}
                                            style={{
                                                backgroundColor: "#000000",
                                                color: "white",
                                                width: "100%",
                                                padding: "10px",
                                                textTransform: "none",
                                                fontSize: "17px",
                                                borderRadius: "10px",
                                                fontFamily: "Poppins",
                                                letterSpacing: '0.1rem',
                                                height: '3.7rem'
                                            }}>
                                            {payLoader
                                                ?
                                                <div className="loader"></div>
                                                :
                                                <>
                                                    <TbCreditCard size={37} style={{ marginRight: "16px" }} />
                                                    Proceed to pay
                                                </>
                                            }
                                        </Button>
                                    )
                                    :
                                    (
                                        <Button
                                            onClick={() => setCardSelectionError(true)}
                                            style={{
                                                backgroundColor: "#000000",
                                                color: "white",
                                                width: "100%",
                                                padding: "10px",
                                                textTransform: "none",
                                                fontSize: "17px",
                                                borderRadius: "10px",
                                                fontFamily: "Poppins",
                                                letterSpacing: '0.1rem',
                                                height: '3.7rem'
                                            }}>
                                            <TbCreditCard size={37} style={{ marginRight: "16px" }} />
                                            Proceed to pay
                                        </Button>
                                    )
                                }

                                <Button
                                    onClick={handleModalOpen}
                                    style={{
                                        backgroundColor: "#ffffff",
                                        color: "#000000",
                                        width: "100%",
                                        padding: "10px",
                                        textTransform: "none",
                                        fontSize: "17px",
                                        borderRadius: "10px",
                                        fontFamily: "Poppins",
                                        letterSpacing: '0.1rem',
                                        height: '3.7rem',
                                        marginTop: '5px'
                                    }}>
                                    {cancelBtnLoader
                                        ?
                                        <div className="cancelBtnLdr"></div>
                                        :
                                        "Cancel"}
                                </Button>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default PaymentsOptionsPage