import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import "./PaymentFailed.css"
import Button from "@material-ui/core/Button";
import { AiFillExclamationCircle } from 'react-icons/ai';
import { useParams } from "react-router-dom";
import payFailed from "./payFailed.png"

const PaymentFailed = () => {
  const menu = useSelector((state) => state.menu)
  const history = useHistory()
  const params = useParams();


  return (
    <>
      <div className="pay-failed-parent">
        <div className="scs-icon">
          <img src={payFailed} style={{ width: '66%', height: '66%' }} />
        </div>

        <div className="odr-failed-text-div">
          <p className="oops-text">
            Oops !
          </p>

          <div className="odr-fail-msg">
            Your payment could not be processed.
          </div>

          <Button
            onClick={() => history.goBack()}
            style={{
              backgroundColor: "#000000",
              color: "white",
              fontSize: "22px",
              height: "4rem",
              width: "100%",
              padding: "10px",
              textTransform: "none",
              borderRadius: "20px",
              marginTop: "40%",
              fontFamily: 'Poppins',
              letterSpacing: '0.1rem'
            }}
          >
            Go Back
          </Button>

        </div>
      </div>

    </>
  )
}
export default PaymentFailed
