import React, { useEffect, useState } from "react"
import ModifierCategory from "../ForcedModifiers/ModifiersCategory/ModifierCategory"
import { getTaxes } from "../../../state-management/menu/operations"
import { truncateDecimal } from "../../../state-management/menu/utils"
import { useSelector } from "react-redux"

export const ForcedModifier = ({
  item,
  forcedModifiers,
  detours,
  currency,
  getModifierPrice,
  onSelectionChange,
  lowestPricearray,
  activeLinkedFm
}) => {
  // console.log("its forcedModifiers here", forceModifier)
  // console.log("its forcedModifiers L here", lowestPricearray)

  // return
  const [selectedForceModifiers, setSelectedForceModifiers] = useState([
    ...lowestPricearray
  ])
  const menu = useSelector((state) => state.menu)


  function getTotalPrice(updatedForcedModifiers) {
    let selectedLinkedId = updatedForcedModifiers.filter(object => object?.addon_price == undefined)[0]?.fm_item_id
    let tempMod = updatedForcedModifiers.filter(object => object?.addon_price != undefined)
    let selectedMod = []

    for (var i = 0; i < tempMod.length; i++) {
      for (var j = 0; j < tempMod[i].addon_price.length; j++) {
        if (tempMod[i].addon_price[j].fm_item_id == selectedLinkedId) {
          selectedMod.push(tempMod[i].addon_price[j])
        }
      }
    }

    let totals = 0
    updatedForcedModifiers.map((val) => {
      totals += Number(val?.price)
    })

    selectedMod.map((val) => {
      totals += Number(val?.fm_item_price)
    })

    return totals
  }

  // function getTotalPrice(updatedForcedModifiers) {
  //   console.log("its forceModifier common here", updatedForcedModifiers)

  //   return updatedForcedModifiers.reduce(
  //     (acc, item) => acc + Number(item.price),
  //     0
  //   )
  // }

  let updatedModifiers = [...lowestPricearray]

  const handleSelectionChange = (forceModifier) => {
    updatedModifiers.push(forceModifier)
    let findItmByCatId = forcedModifiers.find(x => x.fm_cat_id === forceModifier.categoryId)

    let commonEleId = ""
    if (activeLinkedFm != undefined) {
      for (var i = 0; i < lowestPricearray.length; i++) {
        for (var j = 0; j < findItmByCatId.items.length; j++) {
          if (lowestPricearray[i].fm_item_id == findItmByCatId.items[j].fm_item_id) {
            commonEleId = lowestPricearray[i].fm_item_id
          }
        }
      }
      updatedModifiers = updatedModifiers.filter(object => object.fm_item_id !== commonEleId);
    } else {
      for (var i = 0; i < lowestPricearray.length; i++) {
        for (var j = 0; j < findItmByCatId.items.length; j++) {

          if (lowestPricearray[i].id == findItmByCatId.items[j].id) {
            commonEleId = lowestPricearray[i].id

          }
        }
      }
      updatedModifiers = updatedModifiers.filter(object => object.id !== commonEleId);
    }

    onSelectionChange &&
      onSelectionChange(updatedModifiers, getTotalPrice(updatedModifiers))
  }

  // const handleSelectionChange = (forceModifier) => {
  //   console.log("its forceModifier here", forceModifier)

  //   let updatedModifiers = [...lowestPricearray]
  //   lowestPricearray?.map((item, index) => {
  //     if (item.fm_cat_id === forceModifier.fm_cat_id) {
  //       // console.log("index in fm is", index, lowestPricearray)
  //       updatedModifiers.splice(index, 1)
  //       updatedModifiers.push(forceModifier)
  //     }
  //   })
  //   onSelectionChange &&
  //     onSelectionChange(updatedModifiers, getTotalPrice(updatedModifiers))
  // }

  return (
    forcedModifiers &&
    forcedModifiers?.map((forceModifier, i) => {
      var priceArr = ""
      var temp = []


      let min = Math.min.apply(
        null,
        forceModifier?.items?.map(function (item) {
          return item.price
        })
      )

      forceModifier?.items?.map((curr) => {
        if (min == curr.price) {
          priceArr = curr.id ? curr.id : curr.fm_item_id
          temp.push(curr)
        }
      })

      //new
      let selectedId = forceModifier?.items[0]?.id ? forceModifier?.items[0].id : forceModifier?.items[0]?.fm_item_id
      // console.log("its forcedModifiers here", forceModifier)

      return (
        <>
          {
            forceModifier.items != undefined
              &&
              forceModifier.items.length > 1
              ?
              <ModifierCategory
                key={i}
                forceModifier={forceModifier}
                detours={detours}
                currency={currency}
                getModifierPrice={getModifierPrice}
                onSelectionChange={handleSelectionChange}
                full_item={item}
                // lowestModifierId={priceArr}
                lowestModifierId={selectedId}
                activeLinkedFm={activeLinkedFm}
              />
              :
              ""
          }

          {/* <ModifierCategory
            key={i}
            forceModifier={forceModifier}
            detours={detours}
            currency={currency}
            getModifierPrice={getModifierPrice}
            onSelectionChange={handleSelectionChange}
            full_item={item}
            // lowestModifierId={priceArr}
            lowestModifierId={selectedId}
            activeLinkedFm={activeLinkedFm}
          /> */}
        </>
      )
    })
  )
}
export default ForcedModifier
