import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchMyOrderList,
  fetchMyOrderDetails
} from "../../state-management/user/asyncActions"
import checkImg from "./checkImg.png"
import "./OrderSuccess.css"
import icon from "./dersucces-icon.png"
import { clearMenuState } from "../../state-management/menu/actions"
import RestrictUser from "../RestrictUser/RestrictUser"
import { useHistory } from "react-router"
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import { checkItsBackFromLogo, storeOpenOrderId } from "../../state-management/main/actions"

const OrderSuccess = () => {
  const History = useHistory()
  const dispatch = useDispatch()
  const menu = useSelector((state) => state.menu)
  const user = useSelector((state) => state.user)
  const [restrict, setRestrict] = useState(false)
  const [ordersession, setordersession] = useState()
  const [orderid, setorderid] = useState()
  const params = useParams();
  const [orderNo, setOrderNo] = useState('')

  const [state, setstate] = useState({
    showOrderDetails: false,
    loadingData: false,
    showOverlay: false,
    errorMessage: false,
    requestSuccess: null
  })
  var ordersList = []
  var ordersData = []
  const [orderCoompleteDetails, setorderCoompleteDetails] = useState({})
  const [savedcouponamount, setsavedcouponamount] = useState()
  const [waitfordata, setwaitfordata] = useState(true)
  const [happyHour, sethappyHour] = useState()

  const fetchdata = async () => {
    setstate({ ...state, loadingData: true })
    // console.log("clientid", user.user.clientId)
    const response = await dispatch(fetchMyOrderList(user.user.clientId))
    const { payload } = await response
    // console.log("payload is", payload)

    setstate({ ...state, loadingData: false })
    let dataSource = []
    if (payload.status == "200") {
      //setstate({ ...state, ordersList: payload.data });
      ordersList = payload.data
      console.log(
        ordersList.filter(
          (order) => order.restaurant_id === menu.restaurantInfo.restaurant_id
        )
      )

      ordersList
        .filter(
          (order) => order.restaurant_id === menu.restaurantInfo.restaurant_id
        )
        .map((order, i) => {
          let data = {}
          data["key"] = `${i}`
          data["order_no"] = order.order_id
          data["order_date"] = order.order_date
          data["paid"] = order.paid === "1" ? "Yes" : "No"
          data["status"] = order.order_status
          // console.log(data);
          dataSource.push(data)
          // console.log(this.state.ordersData);
        })
      ordersData = dataSource

      var oid = ordersList[0].order_id
      // console.log("orders data", oid)
    }

    const urlParams = new URLSearchParams(window.location.search)
    var order_session = ""
    for (const entry of urlParams.entries()) {
      order_session = entry[1].slice("30")
    }
    // console.log("order session in 78", order_session)
    const response_2 = await dispatch(fetchMyOrderDetails(order_session))
    const { payload_again } = await response_2

    // console.log("response_2 in ordersuccess", response_2)

    var i = 0

    if (response_2.payload.status == 200) {
      setorderCoompleteDetails(response_2.payload.data[0])
      setsavedcouponamount(response_2.payload.data[0].savings)
      sethappyHour(response.payload.data[0].happy_hours_discount)
      dispatch(clearMenuState())
      setwaitfordata(false)
    } else if (response_2.payload.status == 201) {
      setTimeout(async function () {
        var order_session_for_card = ""
        for (const entry of urlParams.entries()) {
          order_session_for_card = entry[1].slice("30")
        }
        // console.log("after 20 seconds", order_session_for_card)
        const response_2_for_payments = await dispatch(
          fetchMyOrderDetails(order_session_for_card)
        )
        setorderCoompleteDetails(response_2_for_payments.payload.data[0])
        setsavedcouponamount(response_2_for_payments.payload.data[0].savings)
        dispatch(clearMenuState())
        setwaitfordata(false)
        var order_session_for_card = ""
      }, 25000)
    }
    // console.log("complete details", orderCoompleteDetails)
    var order_session = ""
    // console.log("order session in 89", order_session)
  }

  useEffect(() => {
    setOrderNo(params.orderid.slice(8));
    setTimeout(async function () {
      const urlParams = new URLSearchParams(window.location.search)
      var order_session = ""
      for (const entry of urlParams.entries()) {
        order_session = entry[1].slice("30")
      }
      setorderid(order_session)

      setwaitfordata(false)

      dispatch(clearMenuState())
      localStorage.removeItem("paymentType")
      localStorage.removeItem("deliveryType")
      localStorage.removeItem("checkoutState")
      localStorage.removeItem("dtime")
      localStorage.removeItem("ptime")
    }, 5000)
  }, [])

  const okButton = () => {
    dispatch(checkItsBackFromLogo({ "itsBackFromLogo": "no" }));
    History.push(
      `/viewDetails/restid=${params.restId.slice(7)}/tid=${params.tid.slice(4)}/orderid=${orderNo}`
    )


    // History.push(
    //   `/restId=${params.restId.slice(7)}&tid=${params.tid.slice(4)}/tableDetails`
    // )
  }

  return (
    <>
      {restrict ? (
        <RestrictUser />
      ) : (
        <>
          {waitfordata ? (
            <div className="order-success-parent">
              <div className="thankU-text-div">
                <p style={{ fontSize: "28px", marginTop: "150px", fontWeight: 700, fontFamily: 'Poppins', }}>
                  Order Confirmed. !
                </p>

                <p style={{
                  textAlign: "center",
                  fontSize: "28px",
                  fontWeight: 600,
                  fontFamily: 'Poppins',
                }}>
                  We are fetching your details Please Wait !
                </p>

                <div class="loaderr"></div>

                {/* <div class='lds-roller-orderSuccess'>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div> */}
              </div>


            </div>
          ) : (
            <>
              {/* <AppHeader /> */}

              <div className="order-success-parent">
                <div className="scs-icon">
                  <img src={checkImg} style={{ width: '68%', height: '68%' }} />
                </div>

                <div className="thankU-text-div">
                  <p className="thankU-text">
                    Thank You !
                  </p>

                  <div className="odrNo-div">
                    <div className="odrs-no"> Order Number</div>
                    <div className="odrs-no">{orderNo}</div>
                  </div>

                  <div className="msg-div">
                    <div className="msg">Your order is placed successfully.</div>
                    <div className="msg">Your food will be sent to your table soon.</div>
                  </div>

                  <Button
                    onClick={okButton}
                    style={{
                      backgroundColor: "#000000",
                      color: "white",
                      fontSize: "22px",
                      height: "4rem",
                      width: "100%",
                      padding: "10px",
                      textTransform: "none",
                      borderRadius: "20px",
                      marginTop: "16%",
                      fontFamily: 'Poppins',
                      letterSpacing: '0.1rem'
                    }}>
                    Done
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
export default OrderSuccess
