import React, { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import MenuTable from "../../components/MenuTable/MenuTable"
import PizzaMenuTable from "../../components/MenuTable/PizzaMenuTable"
import { getTaxes } from "../../state-management/menu/operations"
import { getFilterredList } from "../../state-management/menu/selectors"
import Skeleton from "react-loading-skeleton"
import { addItem, clearCart, removeItem } from "../../state-management/menu/actions"
import { displayHappyHours } from "../../state-management/menu/actions"
import img6 from "./demo-slider-1.jpg"
import backAlertImg from "./alert.png"
import { Link } from "react-scroll";
import "./menu-items.css";
import { HiMenu } from 'react-icons/hi';
import { useParams } from "react-router-dom";
import { checkItsBackFromLogo, storeFilterDrawData } from "../../state-management/main/actions"

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useHistory } from "react-router"
import { CgClose } from 'react-icons/cg';
import { Drawer } from "@material-ui/core"
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 330,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 3,
  borderRadius: '20px',
};

const MenuItems = React.memo(
  ({
    onAddItem,
    onRemoveItem,
    categories,
    pizzas,
    happyhours,
    selectedCategoryId,
    menuItems,
    restaurantInfo,
    searchQuery,
    loading,
    status,
    tableId,
    setOpensDrawer,
    setPicUrl,
    setDrawName,
    setDrawPrice,
    setDrawDescriptiion,
    restLogo,
    restId
  }) => {
    //! [x] Need to refractored, should create a seperate Table (presentational component)
    //! Should filter isOnline isHappyhour active and need to implement details page for menun with subcategory,
    //! Should implement modifiers for the menu items,
    //? Need code splitting, load lazily because this is data intensive operation, create a suspense in main route.

    //? Need to filter Pizzas and HappyHours based on isActive and isOnline
    const menu = useSelector((state) => {
      return state.menu
    })
    const state = useSelector((state) => state)
    const [visible, setvisible] = useState()
    const dispatch = useDispatch()
    const allforcedModifiers = menu.allForcedModifier
    var array_of_ids = []
    var tem_arr_cname = []
    var list = (menuItems = getFilterredList(state))
    const params = useParams();
    const History = useHistory()
    const main = useSelector((state) => state.main)
    const modal = useSelector((state) => state.modal)

    const [openFilterDrawer, setOpenFilterDrawer] = React.useState(false)
    const [open, setOpen] = React.useState(false);

    const [filterChkBtn, setFilterChkBtn] = useState(false)
    const [getFilterItems, setGetFilterItems] = useState('')
    const [filterChkNuts, setFilterChkNuts] = useState(false)
    const [filterChkGluten, setFilterChkGluten] = useState(false)
    const [filterChkVegan, setFilterChkVegan] = useState(false)
    const [filterChkLactose, setFilterChkLactose] = useState(false)
    const [filterChkReduced, setFilterChkReduced] = useState(false)
    const [filterChkHot, setFilterChkHot] = useState(false)

    const [fltrDrawBtn, setFltrDrawBtn] = useState(false)
    const [filterSelectedCategory, setFilterSelectedCategory] = useState()
    const [noDataFound, setNoDataFound] = useState(false)

    const [nutsbtnshow, setnutsbtnshow] = useState()
    const [glutenbtnshow, setGlutenbtnshow] = useState()
    const [veganbtnshow, setVeganbtnshow] = useState()
    const [lactosebtnshow, setLactosebtnshow] = useState()
    const [reducedbtnshow, setReducedbtnshow] = useState()
    const [hotbtnshow, setHotbtnshow] = useState()

    const [getfltData, setGetfltData] = useState({
      nuts: "",
      gluten: "",
      vegan: "",
      lactose: "",
      reduced: "",
      hot: "",
    })

    const [getfltDataForOptimise, setGetfltDataForOptimise] = useState({
      nuts: "0",
      gluten: "0",
      vegan: "0",
      lactose: "0",
      reduced: "0",
      hot: "0",
    })

    let skeletonArray = [1, 2, 3, 4];
    let filterItemsCounts = 0

    const func = () => {
      var tem_arr = []
      var dish_subcat_id = []
      var dish_cname = []
      var drink_subcat_id = []
      var drink_cname = []

      if (menu.categoriesList[0]) {
        for (let i = 0; i < menu.categoriesList[0].sub_category.length; i++) {
          dish_subcat_id.push(
            menu.categoriesList[0].sub_category[i].category_id
          )
          dish_cname.push(menu.categoriesList[0].sub_category[i].cname)
        }
        array_of_ids.push(dish_subcat_id)
        tem_arr_cname.push(dish_cname)
      }

      if (menu.categoriesList[1]) {
        for (let i = 0; i < menu.categoriesList[1].sub_category?.length; i++) {
          drink_subcat_id.push(
            menu.categoriesList[1].sub_category[i].category_id
          )

          dish_cname.push(menu.categoriesList[1].sub_category[i].cname)
        }
      }
      array_of_ids.push(drink_subcat_id)
      tem_arr_cname.push(drink_cname)

      for (let j = 0; j < dish_subcat_id.length; j++) {
        tem_arr.push(
          menu.menuItems.filter(
            ({ category_id: cid, online }) =>
              cid === dish_subcat_id[j] && online === "1"
          )
        )
      }

      for (let j = 0; j < drink_subcat_id.length; j++) {
        tem_arr.push(
          menu.menuItems.filter(
            ({ category_id: cid, online }) =>
              cid === drink_subcat_id[j] && online === "1"
          )
        )
      }

      tem_arr = tem_arr.filter(function (x) {
        return x.length
      })

      return tem_arr
    }

    const getSelectedCategory = (cname) => {
      var selectedCategoryId
      if (cname.length > 0) {
        selectedCategoryId = cname[0].category_id
      }
      if (menu.categoriesList.length) {
        const category = menu.categoriesList.find(
          (category) =>
            category["sub_category"] &&
            category["sub_category"].find(
              ({ category_id: cId }) => cId === selectedCategoryId
            )
        )
        const subCategory =
          category &&
          category["sub_category"] &&
          category["sub_category"].find(
            ({ category_id: cId }) => cId === selectedCategoryId
          )

        return subCategory ? subCategory : ""
      }

      if (menu.selectedCategoryId === -1) {
        return "Happy Hours"
      } else if (menu.selectedCategoryId === -2) {
        return "Pizza's"
      }
    }

    const isPriceWithoutTax = () => {
      return Number(menu.restaurantInfo["price_without_tax_flag"])
    }

    const getActualPrice = (item) => {
      if (menu.restaurantInfo) {
        return isPriceWithoutTax()
          ? item.price
          : (
            Number(item.price) +
            Number(getTaxes(item, item.price, menu.restaurantInfo).tax)
          ).toFixed(2)
      }

      return 0
    }

    var a = 1

    const removefromcart = (item) => {
      dispatch(removeItem(item, item.modifiers || null, 0, menu.restaurantInfo))
    }

    useEffect(() => {
      if (window.screen.width > 450) {
        setvisible(false)
      } else {
        setvisible(true)
      }
      dispatch(displayHappyHours())
    }, [])

    // ITEM NAME SCROLLING IN JQUERY
    // useEffect(() => {
    //   const script = document.createElement("script");

    //   script.src = require('./animation');
    //   script.async = true;

    //   document.body.appendChild(script);
    // }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const goHomeBack = () => {
      // dispatch(clearCart())
      handleClose()
      window.location.href = `https://menu.ciboapp.com/restId=${restId}&tid=${tableId}/tableDetails`
      // window.location.href = `http://localhost:3000/restId=${restId}&tid=${tableId}/tableDetails`

      // History.push(
      //   `/restId=${restId}&tid=${tableId}/tableDetails`
      // )
      // window.location.reload()
      // dispatch(checkItsBackFromLogo({ "itsBackFromLogo": "yes" }))
    }

    // Checking values are comming to filter or not
    useEffect(() => {
      let pushFltrDatas = [];
      for (let i = 0; i < func().length; i++) {
        let fltrDatas = func()[i].filter(n => n.nuts_free == "1")
        fltrDatas.length != 0 && pushFltrDatas.push(fltrDatas)
        setnutsbtnshow(pushFltrDatas)
      }
    }, [loading == false])

    useEffect(() => {
      let pushFltrDatas = [];
      for (let i = 0; i < func().length; i++) {
        let fltrDatas = func()[i].filter(n => n.gluten_free == "1")
        fltrDatas.length != 0 && pushFltrDatas.push(fltrDatas)
        setGlutenbtnshow(pushFltrDatas)
      }
    }, [loading == false])

    useEffect(() => {
      let pushFltrDatas = [];
      for (let i = 0; i < func().length; i++) {
        let fltrDatas = func()[i].filter(n => n.is_vegan == "1")
        fltrDatas.length != 0 && pushFltrDatas.push(fltrDatas)
        setVeganbtnshow(pushFltrDatas)
      }
    }, [loading == false])

    useEffect(() => {
      let pushFltrDatas = [];
      for (let i = 0; i < func().length; i++) {
        let fltrDatas = func()[i].filter(n => n.lactose_free == "1")
        fltrDatas.length != 0 && pushFltrDatas.push(fltrDatas)
        setLactosebtnshow(pushFltrDatas)
      }
    }, [loading == false])

    useEffect(() => {
      let pushFltrDatas = [];
      for (let i = 0; i < func().length; i++) {
        let fltrDatas = func()[i].filter(n => n.is_reduced == "1")
        fltrDatas.length != 0 && pushFltrDatas.push(fltrDatas)
        setReducedbtnshow(pushFltrDatas)
      }
    }, [loading == false])

    useEffect(() => {
      let pushFltrDatas = [];
      for (let i = 0; i < func().length; i++) {
        let fltrDatas = func()[i].filter(n => n.is_hot == "1")
        fltrDatas.length != 0 && pushFltrDatas.push(fltrDatas)
        setHotbtnshow(pushFltrDatas)
      }
    }, [loading == false])

    const showFilteredItemsBtn = () => {
      if (main.storeFilterDrawData.length != 0) {
        setOpenFilterDrawer(false);
        setFltrDrawBtn(true);
      } else {
        setNoDataFound(true);
        setTimeout(() => {
          setNoDataFound(false);
        }, 3000);
      }
    }

    const clearBtnDrawer = () => {
      dispatch(storeFilterDrawData([]))
      setFilterSelectedCategory([])
      setGetfltData(0);
      setFltrDrawBtn(false);
      setFilterChkNuts(false);
      setFilterChkGluten(false);
      setFilterChkVegan(false);
      setFilterChkLactose(false);
      setFilterChkReduced(false);
      setFilterChkHot(false);
      setCtgrQt(0);
    }

    useEffect(() => {
      if (main?.storeFilterDrawData?.length < 1) {
        setFltrDrawBtn(false);
      } else {
        return
      }
    }, [main?.storeFilterDrawData])




    // useEffect(() => {
    //   console.log("getfltDataForOptimise", getfltDataForOptimise)

    //   // Six items filter
    //     console.log("You choosen value is", "nuts:", getfltDataForOptimise.nuts, "gluten:", getfltDataForOptimise.gluten, "vegan:", getfltDataForOptimise.vegan, "lactose:", getfltDataForOptimise.lactose, "reduced:", getfltDataForOptimise.reduced, "hot:", getfltDataForOptimise.hot);
    //     let pushFltrData = [];
    //     for (let i = 0; i < func().length; i++) {
    //       // let fltrData = func()[i].filter(n => n.nuts_free == getfltDataForOptimise.nuts && n.gluten_free == getfltDataForOptimise.gluten && n.is_vegan == getfltDataForOptimise.vegan && n.lactose_free == getfltDataForOptimise.lactose && n.is_reduced == getfltDataForOptimise.reduced && n.is_hot == getfltDataForOptimise.hot)
    //       // fltrData.length != 0 && pushFltrData.push(fltrData)
    //       console.log("pushFltrData", func()[i].filter(n => n.nuts_free == getfltDataForOptimise.nuts && n.gluten_free == getfltDataForOptimise.gluten && n.is_vegan == getfltDataForOptimise.vegan && n.lactose_free == getfltDataForOptimise.lactose && n.is_reduced == getfltDataForOptimise.reduced && n.is_hot == getfltDataForOptimise.hot) )

    //       // dispatch(storeFilterDrawData(pushFltrData))
    //     }
    // }, [getfltDataForOptimise])

    const [ctgrQt, setCtgrQt] = useState(0)

    const dmrmv = () => {
      setCtgrQt(ctgrQt - 1)
    }

    const dmAdd = () => {
      setCtgrQt(ctgrQt + 1)
    }


    // Conditions for filter the items
    useEffect(() => {
      // Six items filter
      if (getfltData.nuts == 'nuts' && getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose' && getfltData.reduced == 'reduced' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['nuts', 'gluten', 'vegan', 'lactose', 'reduced', 'hot'])
        console.log("You choose nuts-gluten-vegan-lactose-reduced-hot and value is", getfltData.nuts, getfltData.gluten, getfltData.vegan, getfltData.lactose, getfltData.reduced, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.gluten_free == "1" && n.is_vegan == "1" && n.lactose_free == "1" && n.is_reduced == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      // Five items filter
      if (getfltData.nuts == 'nuts' && getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['nuts', 'gluten', 'vegan', 'lactose', 'reduced'])
        console.log("You choose nuts-gluten-vegan-lactose-reduced and value is", getfltData.nuts, getfltData.gluten, getfltData.vegan, getfltData.lactose, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.gluten_free == "1" && n.is_vegan == "1" && n.lactose_free == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      if (getfltData.nuts == 'nuts' && getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['nuts', 'gluten', 'vegan', 'lactose', 'hot'])
        console.log("You choose nuts-gluten-vegan-lactose-hot and value is", getfltData.nuts, getfltData.gluten, getfltData.vegan, getfltData.lactose, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.gluten_free == "1" && n.is_vegan == "1" && n.lactose_free == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      if (getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose' && getfltData.reduced == 'reduced' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['gluten', 'vegan', 'lactose', 'reduced', 'hot'])
        console.log("You choose gluten-vegan-lactose-reduced-hot and value is", getfltData.gluten, getfltData.vegan, getfltData.lactose, getfltData.reduced, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.gluten_free == "1" && n.is_vegan == "1" && n.lactose_free == "1" && n.is_reduced == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      if (getfltData.nuts == 'nuts' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose' && getfltData.reduced == 'reduced' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['nuts', 'vegan', 'lactose', 'reduced', 'hot'])
        console.log("You choose nuts-vegan-lactose-reduced-hot and value is", getfltData.nuts, getfltData.gluten, getfltData.vegan, getfltData.lactose, getfltData.reduced, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.is_vegan == "1" && n.lactose_free == "1" && n.is_reduced == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      // Four items filter
      if (getfltData.nuts == 'nuts' && getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['nuts', 'gluten', 'vegan', 'lactose'])
        console.log("You choose nuts-gluten-vegan-lactose and value is", getfltData.nuts, getfltData.gluten, getfltData.vegan, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.gluten_free == "1" && n.is_vegan == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      if (getfltData.nuts == 'nuts' && getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['nuts', 'gluten', 'vegan', 'reduced'])
        console.log("You choose nuts-gluten-vegan-reduced and value is", getfltData.nuts, getfltData.gluten, getfltData.vegan, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.gluten_free == "1" && n.is_vegan == "1" && n.is_reduced == "1")
          console.log("fltrData", fltrData)
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      if (getfltData.nuts == 'nuts' && getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['nuts', 'gluten', 'vegan', 'hot'])
        console.log("You choose nuts-gluten-vegan-hot and value is", getfltData.nuts, getfltData.gluten, getfltData.vegan, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.gluten_free == "1" && n.is_vegan == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      if (getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['gluten', 'vegan', 'lactose', 'reduced'])
        console.log("You choose gluten-vegan-lactose-reduced and value is", getfltData.gluten, getfltData.vegan, getfltData.lactose, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.gluten_free == "1" && n.is_vegan == "1" && n.lactose_free == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      if (getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['gluten', 'vegan', 'lactose', 'hot'])
        console.log("You choose gluten-vegan-lactose-hot and value is", getfltData.gluten, getfltData.vegan, getfltData.lactose, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.gluten_free == "1" && n.is_vegan == "1" && n.lactose_free == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      if (getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose' && getfltData.reduced == 'reduced' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['vegan', 'lactose', 'reduced', 'hot'])
        console.log("You choose vegan-lactose-reduced-hot and value is", getfltData.vegan, getfltData.lactose, getfltData.reduced, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_vegan == "1" && n.lactose_free == "1" && n.is_reduced == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      if (getfltData.nuts == 'nuts' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['nuts', 'vegan', 'lactose', 'reduced'])
        console.log("You choose nuts-vegan-lactose-reduced and value is", getfltData.nuts, getfltData.vegan, getfltData.lactose, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.is_vegan == "1" && n.lactose_free == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      if (getfltData.nuts == 'nuts' && getfltData.lactose == 'lactose' && getfltData.reduced == 'reduced' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['nuts', 'lactose', 'reduced', 'hot'])
        console.log("You choose nuts-lactose-reduced-hot and value is", getfltData.nuts, getfltData.lactose, getfltData.reduced, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.lactose_free == "1" && n.is_reduced == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      // Three items filter
      if (getfltData.nuts == 'nuts' && getfltData.vegan == 'vegan' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['nuts', 'vegan', 'hot'])
        console.log("You choose nuts-vegan-hot and value is", getfltData.nuts, getfltData.vegan, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.is_vegan == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      if (getfltData.nuts == 'nuts' && getfltData.vegan == 'vegan' && getfltData.gluten == 'gluten') {
        setFilterSelectedCategory(['nuts', 'vegan', 'gluten'])
        console.log("You choose nuts-vegan-gluten and value is", getfltData.nuts, getfltData.vegan, getfltData.gluten);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.gluten_free == "1" && n.is_vegan == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.nuts == 'nuts' && getfltData.gluten == 'gluten' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['nuts', 'gluten', 'hot'])
        console.log("You choose nuts-gluten-hot and value is", getfltData.nuts, getfltData.gluten, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.gluten_free == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['gluten', 'vegan', 'lactose'])
        console.log("You choose gluten-vegan-lactose and value is", getfltData.gluten, getfltData.vegan, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.gluten_free == "1" && n.is_vegan == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['gluten', 'vegan', 'hot'])
        console.log("You choose gluten-vegan-hot and value is", getfltData.gluten, getfltData.vegan, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.gluten_free == "1" && n.is_vegan == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.nuts == 'nuts' && getfltData.gluten == 'gluten' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['nuts', 'gluten', 'lactose'])
        console.log("You choose nuts-gluten-lactose and value is", getfltData.nuts, getfltData.gluten, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.gluten_free == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.nuts == 'nuts' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['nuts', 'vegan', 'lactose'])
        console.log("You choose nuts-vegan-lactose and value is", getfltData.nuts, getfltData.vegan, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.is_vegan == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.nuts == 'nuts' && getfltData.gluten == 'gluten' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['nuts', 'gluten', 'reduced'])
        console.log("You choose nuts-gluten-reduced and value is", getfltData.nuts, getfltData.gluten, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.gluten_free == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.vegan == 'vegan' && getfltData.gluten == 'gluten' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['vegan', 'gluten', 'reduced'])
        console.log("You choose vegan-gluten-reduced and value is", getfltData.vegan, getfltData.gluten, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_vegan == "1" && n.gluten_free == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.reduced == 'reduced' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['reduced', 'vegan', 'lactose'])
        console.log("You choose reduced-vegan-lactose and value is", getfltData.reduced, getfltData.vegan, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_reduced == "1" && n.is_vegan == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.hot == 'hot' && getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['hot', 'vegan', 'lactose'])
        console.log("You choose hot-vegan-lactose and value is", getfltData.hot, getfltData.vegan, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_hot == "1" && n.is_vegan == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.nuts == 'nuts' && getfltData.lactose == 'lactose' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['nuts', 'lactose', 'reduced'])
        console.log("You choose nuts-lactose-reduced and value is", getfltData.nuts, getfltData.lactose, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.lactose_free == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.nuts == 'nuts' && getfltData.hot == 'hot' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['nuts', 'hot', 'reduced'])
        console.log("You choose nuts-hot-reduced and value is", getfltData.nuts, getfltData.hot, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.is_hot == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.hot == 'hot' && getfltData.lactose == 'lactose' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['hot', 'lactose', 'reduced'])
        console.log("You choose hot-lactose-reduced and value is", getfltData.hot, getfltData.lactose, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_hot == "1" && n.lactose_free == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.reduced == 'reduced' && getfltData.gluten == 'gluten' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['reduced', 'gluten', 'lactose'])
        console.log("You choose reduced-gluten-lactose and value is", getfltData.reduced, getfltData.gluten, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_reduced == "1" && n.gluten_free == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.reduced == 'reduced' && getfltData.gluten == 'gluten' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['reduced', 'gluten', 'hot'])
        console.log("You choose reduced-gluten-hot and value is", getfltData.reduced, getfltData.gluten, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_reduced == "1" && n.gluten_free == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.reduced == 'reduced' && getfltData.vegan == 'vegan' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['reduced', 'vegan', 'hot'])
        console.log("You choose reduced-vegan-hot and value is", getfltData.reduced, getfltData.vegan, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_reduced == "1" && n.is_vegan == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.nuts == 'nuts' && getfltData.vegan == 'vegan' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['nuts', 'vegan', 'reduced'])
        console.log("You choose nuts-vegan-reduced and value is", getfltData.nuts, getfltData.vegan, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.is_vegan == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      // Double Items filter
      else if (getfltData.nuts == 'nuts' && getfltData.gluten == 'gluten') {
        setFilterSelectedCategory(['nuts', 'gluten'])
        console.log("You choose nuts-gluten and value is", getfltData.nuts, getfltData.gluten);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.gluten_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.nuts == 'nuts' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['nuts', 'lactose'])
        console.log("You choose nuts-lactose and value is", getfltData.nuts, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.nuts == 'nuts' && getfltData.vegan == 'vegan') {
        setFilterSelectedCategory(['nuts', 'vegan'])
        console.log("You choose nuts-vegan and value is", getfltData.nuts, getfltData.vegan);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.is_vegan == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.nuts == 'nuts' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['nuts', 'reduced'])
        console.log("You choose nuts-reduced and value is", getfltData.nuts, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.nuts == 'nuts' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['nuts', 'hot'])
        console.log("You choose nuts-hot and value is", getfltData.nuts, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.gluten == 'gluten' && getfltData.vegan == 'vegan') {
        setFilterSelectedCategory(['gluten', 'vegan'])
        console.log("You choose gluten-vegan and value is", getfltData.gluten, getfltData.vegan);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.gluten_free == "1" && n.is_vegan == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.gluten == 'gluten' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['gluten', 'lactose'])
        console.log("You choose gluten-lactose and value is", getfltData.gluten, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.gluten_free == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.gluten == 'gluten' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['gluten', 'reduced'])
        console.log("You choose gluten-reduced and value is", getfltData.gluten, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.gluten_free == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.gluten == 'gluten' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['gluten', 'hot'])
        console.log("You choose gluten-hot and value is", getfltData.gluten, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.gluten_free == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.vegan == 'vegan' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['vegan', 'lactose'])
        console.log("You choose vegan-lactose and value is", getfltData.vegan, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_vegan == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.vegan == 'vegan' && getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['vegan', 'reduced'])
        console.log("You choose vegan-reduced and value is", getfltData.vegan, getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_vegan == "1" && n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.vegan == 'vegan' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['vegan', 'hot'])
        console.log("You choose vegan-hot and value is", getfltData.vegan, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_vegan == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.reduced == 'reduced' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['reduced', 'lactose'])
        console.log("You choose reduced-lactose and value is", getfltData.reduced, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_reduced == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.hot == 'hot' && getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['hot', 'lactose'])
        console.log("You choose hot-lactose and value is", getfltData.hot, getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_hot == "1" && n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.reduced == 'reduced' && getfltData.hot == 'hot') {
        setFilterSelectedCategory(['reduced', 'hot'])
        console.log("You choose reduced-hot and value is", getfltData.reduced, getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_reduced == "1" && n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      // Single Items filter
      else if (getfltData.nuts == 'nuts') {
        setFilterSelectedCategory(['nuts'])
        console.log("You choose nuts and value is", getfltData.nuts);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.nuts_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.gluten == 'gluten') {
        setFilterSelectedCategory(['gluten'])
        console.log("You choose gluten and value is", getfltData.gluten);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.gluten_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.vegan == 'vegan') {
        setFilterSelectedCategory(['vegan'])
        console.log("You choose vegan and value is", getfltData.vegan);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_vegan == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.lactose == 'lactose') {
        setFilterSelectedCategory(['lactose'])
        console.log("You choose lactose and value is", getfltData.lactose);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.lactose_free == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.reduced == 'reduced') {
        setFilterSelectedCategory(['reduced'])
        console.log("You choose reduced and value is", getfltData.reduced);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_reduced == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else if (getfltData.hot == 'hot') {
        setFilterSelectedCategory(['hot'])
        console.log("You choose hot and value is", getfltData.hot);
        let pushFltrData = [];
        for (let i = 0; i < func().length; i++) {
          let fltrData = func()[i].filter(n => n.is_hot == "1")
          fltrData.length != 0 && pushFltrData.push(fltrData)
          dispatch(storeFilterDrawData(pushFltrData))
        }
      }

      else {
        dispatch(storeFilterDrawData([]))
      }
    }, [getfltData])

    let tableNameById = main?.allTableNamesInfo?.filter((n => n.table_id == main?.tableId))

    return (
      <>
        <div>
          <Modal
            style={{ backgroundColor: '#f6f6f6' }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <CgClose onClick={handleClose} size={25} />

              <div className='alert-img-parrent'>
                <img src={backAlertImg} className='alt-img' />
              </div>

              <div className="modal-alt-msg">
                Are you sure you want
              </div>
              <div style={{ marginBottom: '17px' }} className="modal-alt-msg">
                to leave this page?
              </div>

              {
                menu.cart.length >= 1
                &&
                <>
                  <div className="modal-msg">
                    The current items in the cart
                  </div>
                  <div className="modal-msg">
                    will be discarded.
                  </div>
                </>
              }

              <div style={{ marginTop: '2rem' }}>
                <Button
                  onClick={goHomeBack}
                  style={{
                    backgroundColor: "#000000",
                    color: "white",
                    width: "100%",
                    padding: "10px",
                    textTransform: "none",
                    fontSize: "15px",
                    borderRadius: "15px",
                    fontFamily: "Poppins",
                    letterSpacing: '0.7px',
                    height: '3.2rem'
                  }}>
                  Yes, I want to leave
                </Button>

                <Button
                  onClick={handleClose}
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    width: "100%",
                    padding: "10px",
                    textTransform: "none",
                    fontSize: "15px",
                    borderRadius: "15px",
                    marginTop: '6px',
                    fontFamily: "Poppins",
                    letterSpacing: '0.7px',
                    height: '3.2rem'
                  }}>
                  Keep order
                </Button>
              </div>
            </Box>
          </Modal>
        </div>

        {loading ? (
          <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: "5%" }}>
              <div><Skeleton height={85} width={85} style={{ borderRadius: "20px" }} /></div>

              <div>
                <div><Skeleton height={30} width={150} style={{ borderRadius: "20px", marginBottom: "10%" }} /></div>
                <div><Skeleton height={30} width={150} style={{ borderRadius: "20px" }} /></div>
              </div>

              <div><Skeleton height={85} width={85} style={{ borderRadius: "20px" }} /></div>
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Skeleton height={32} width={370} style={{ borderRadius: "20px", marginBottom: "5%" }} />
            </div>

            {
              skeletonArray.map((i) => (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Skeleton height={180} width={160} style={{ borderRadius: "20px", marginBottom: "22%" }} />
                  <Skeleton height={180} width={160} style={{ borderRadius: "20px", marginBottom: "22%" }} />
                </div>
              ))
            }
          </>
        ) : (
          <>
            {
              status
                ?
                (
                  <>

                    <>
                      {/* <div style={{ position: 'sticky', top: '0px', backgroundColor: '#fff', paddingTop: '15px' }}> */}
                      <div className="fxds-top">
                        <div className="menuPage-top">
                          <div onClick={handleOpen} className='rest-logo'>
                            <img src={restLogo} className='img-rest' />
                          </div>

                          <div style={{ marginLeft: '12px' }}>
                            <div className='table-no-headings'>Table name</div>
                            <div className='table-no'>{
                              tableNameById == undefined
                                ?
                                main.tableId
                                :
                                tableNameById[0]?.table_name === ''
                                  ?
                                  tableNameById[0]?.table_id
                                  :
                                  tableNameById[0]?.table_name
                            }
                            </div>
                          </div>

                          {/* <HiMenu size={34} style={{ opacity: '0' }} /> */}
                          <span
                            onClick={() => setOpenFilterDrawer(true)}
                            className="filter-txt">
                            {/* Filter({filterSelectedCategory ? filterSelectedCategory.length : 0})ctgrQt */}
                            Filter({ctgrQt})

                          </span>
                        </div>

                        {/* <div style={{ width: '100%', textAlign: 'end', marginTop: '2px', marginBottom: '4px' }}>
                        <span
                          onClick={() => setOpenFilterDrawer(true)}
                          className="filter-txt">
                          Filter({filterSelectedCategory ? filterSelectedCategory.length : 0})
                        </span>
                      </div> */}

                        <div className='item-types-btn'>
                          {func().map((val) => {
                            let catNameCharRemove = getSelectedCategory(val).cname.replace(/[^a-zA-Z0-9 ]/g, '');
                            let cattNames = catNameCharRemove.split(' ');
                            return (
                              <Link activeClass="active" className="item-types" smooth spy to={getSelectedCategory(val).category_id}>
                                {cattNames.slice(0, 1)}
                                <span className='y'>y</span>
                                {cattNames.slice(1, 2)}
                              </Link>
                            )
                          })}
                        </div>
                        <hr />
                      </div>
                    </>





                    <div style={{ paddingTop: '8.8rem' }}>
                      {
                        fltrDrawBtn
                          ?
                          (
                            <>
                              {main.storeFilterDrawData.map((val) => {
                                return (
                                  <MenuTable
                                    symbol={restaurantInfo.monetary_symbol}
                                    actualPrice={getActualPrice}
                                    list={val}
                                    onAddItem={onAddItem}
                                    onRemoveItem={onRemoveItem}
                                    loading={loading}
                                    itemId={getSelectedCategory(val).category_id}
                                    setOpensDrawer={setOpensDrawer}
                                    setPicUrl={setPicUrl}
                                    setDrawName={setDrawName}
                                    setDrawPrice={setDrawPrice}
                                    setDrawDescriptiion={setDrawDescriptiion}
                                  />
                                )
                              })}
                            </>
                          )
                          :
                          (
                            <>
                              {func().map((val) => {
                                return (
                                  <MenuTable
                                    symbol={restaurantInfo.monetary_symbol}
                                    actualPrice={getActualPrice}
                                    list={val}
                                    onAddItem={onAddItem}
                                    onRemoveItem={onRemoveItem}
                                    loading={loading}
                                    itemId={getSelectedCategory(val).category_id}
                                    setOpensDrawer={setOpensDrawer}
                                    setPicUrl={setPicUrl}
                                    setDrawName={setDrawName}
                                    setDrawPrice={setDrawPrice}
                                    setDrawDescriptiion={setDrawDescriptiion}
                                  />
                                )
                              })}
                            </>
                          )
                      }
                    </div>
                  </>
                ) : null
            }

            {
              menu.pizzas.length >= 1 && selectedCategoryId !== -1 ? (
                <PizzaMenuTable
                  symbol={restaurantInfo.monetary_symbol}
                  category_name='Pizza'
                  list={pizzas}
                  loading={loading}
                  removeforpizza={removefromcart}
                />
              ) : null
            }
          </>
        )}

        {/* Filter Drawer */}
        <Drawer
          anchor={'bottom'}
          open={openFilterDrawer}
          onClose={() => setOpenFilterDrawer(false)}>

          {/* <Box style={{backgroundColor: 'red', borderRadius: '15px'}}> */}
          <div className="filter-drawer-parent">
            <CgClose style={{ paddingBottom: '10px' }} size={30} onClick={() => setOpenFilterDrawer(false)} />
            <div className="filter-drawer-text">
              Filter
            </div>
            <div
              onClick={clearBtnDrawer}
              className="clr-btns">
              Clear
            </div>
          </div>

          {
            nutsbtnshow?.length < 1 && veganbtnshow < 1 && glutenbtnshow < 1 && lactosebtnshow < 1 && reducedbtnshow < 1 && hotbtnshow < 1
              ?
              <div style={{ marginTop: '12px' }} className='noDataFound'>No items found to filter.</div>
              :
              < div className='filter-chk-btn'>

                {
                  nutsbtnshow?.length > 0
                  &&
                  <>
                    {
                      filterChkNuts
                        ?
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkNuts(false)
                            setGetfltData({ ...getfltData, nuts: '' })

                            setGetfltDataForOptimise({ ...getfltDataForOptimise, nuts: '0' })
                            setFilterSelectedCategory([])
                            dmrmv();
                          }}>
                          <MdCheckBox size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Nuts free
                          </span>
                        </div>
                        :
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkNuts(true)
                            setGetfltData({ ...getfltData, nuts: 'nuts' })
                            setGetfltDataForOptimise({ ...getfltDataForOptimise, nuts: '1' })
                            dmAdd();
                          }}>
                          <MdCheckBoxOutlineBlank size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Nuts free
                          </span>
                        </div>
                    }
                  </>
                }

                {
                  lactosebtnshow?.length > 0
                  &&
                  <>
                    {
                      filterChkLactose
                        ?
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkLactose(false)
                            setGetfltData({ ...getfltData, lactose: '' })

                            setGetfltDataForOptimise({ ...getfltDataForOptimise, lactose: '0' })
                            setFilterSelectedCategory([])
                            dmrmv();
                          }}>
                          <MdCheckBox size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Lactose free
                          </span>
                        </div>
                        :
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkLactose(true)
                            setGetfltData({ ...getfltData, lactose: 'lactose' })

                            setGetfltDataForOptimise({ ...getfltDataForOptimise, lactose: '1' })
                            dmAdd();
                          }}>
                          <MdCheckBoxOutlineBlank size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Lactose free
                          </span>
                        </div>
                    }
                  </>
                }

                {
                  glutenbtnshow?.length > 0
                  &&
                  <>
                    {
                      filterChkGluten
                        ?
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkGluten(false)
                            setGetfltData({ ...getfltData, gluten: '' })

                            setGetfltDataForOptimise({ ...getfltDataForOptimise, gluten: '0' })
                            setFilterSelectedCategory([])
                            dmrmv();
                          }}>
                          <MdCheckBox size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Gluten free
                          </span>
                        </div>
                        :
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkGluten(true)
                            setGetfltData({ ...getfltData, gluten: 'gluten' })

                            setGetfltDataForOptimise({ ...getfltDataForOptimise, gluten: '1' })
                            dmAdd();
                          }}>
                          <MdCheckBoxOutlineBlank size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Gluten free
                          </span>
                        </div>
                    }
                  </>
                }

                {
                  veganbtnshow?.length > 0
                  &&
                  <>
                    {
                      filterChkVegan
                        ?
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkVegan(false)
                            setGetfltData({ ...getfltData, vegan: '' })

                            setGetfltDataForOptimise({ ...getfltDataForOptimise, vegan: '0' })
                            setFilterSelectedCategory([])
                            dmrmv();
                          }}>
                          <MdCheckBox size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Vegetarian
                          </span>
                        </div>
                        :
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkVegan(true)
                            setGetfltData({ ...getfltData, vegan: 'vegan' })

                            setGetfltDataForOptimise({ ...getfltDataForOptimise, vegan: '1' })
                            dmAdd();
                          }}>
                          <MdCheckBoxOutlineBlank size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Vegetarian
                          </span>
                        </div>
                    }
                  </>
                }

                {
                  hotbtnshow?.length > 0
                  &&
                  <>
                    {
                      filterChkHot
                        ?
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkHot(false)
                            setGetfltData({ ...getfltData, hot: '' })

                            setGetfltDataForOptimise({ ...getfltDataForOptimise, hot: '0' })
                            setFilterSelectedCategory([])
                            dmrmv();
                          }}>
                          <MdCheckBox size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Hot
                          </span>
                        </div>
                        :
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkHot(true)
                            setGetfltData({ ...getfltData, hot: 'hot' })

                            setGetfltDataForOptimise({ ...getfltDataForOptimise, hot: '1' })
                            dmAdd();
                          }}>
                          <MdCheckBoxOutlineBlank size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Hot
                          </span>
                        </div>
                    }
                  </>
                }

                {
                  reducedbtnshow?.length > 0
                  &&
                  <>
                    {
                      filterChkReduced
                        ?
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkReduced(false)
                            setGetfltData({ ...getfltData, reduced: '' })

                            setGetfltDataForOptimise({ ...getfltDataForOptimise, reduced: '0' })
                            setFilterSelectedCategory([])
                            dmrmv();
                          }}>
                          <MdCheckBox size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Reduced fat
                          </span>
                        </div>
                        :
                        <div
                          className="chks-btns"
                          onClick={() => {
                            setFilterChkReduced(true)
                            setGetfltData({ ...getfltData, reduced: 'reduced' })

                            setGetfltDataForOptimise({ ...getfltDataForOptimise, reduced: '1' })
                            dmAdd();
                          }}>
                          <MdCheckBoxOutlineBlank size={20} />
                          <span style={{ marginLeft: "10px", fontFamily: "Poppins" }}>
                            Reduced fat
                          </span>
                        </div>
                    }
                  </>
                }
              </div>
          }

          <div ><hr /></div>
          <div className="fltr-result-btn">
            {
              main?.storeFilterDrawData.length != 0
                ?
                <Button
                  onClick={showFilteredItemsBtn}
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "3.8rem",
                    width: "100%",
                    padding: "10px",
                    textTransform: "none",
                    fontSize: "18px",
                    borderRadius: "15px",
                    fontFamily: "Poppins",
                    letterSpacing: '0.7px'
                  }}>
                  {
                    main.storeFilterDrawData?.map((val) => {
                      filterItemsCounts += val?.length
                    })
                  }
                  Show results ({filterItemsCounts ? filterItemsCounts : 0} items)
                </Button>
                :
                <Button
                  onClick={() => setOpenFilterDrawer(false)}
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "3.8rem",
                    width: "100%",
                    padding: "10px",
                    textTransform: "none",
                    fontSize: "18px",
                    borderRadius: "15px",
                    fontFamily: "Poppins",
                    letterSpacing: '0.7px'
                  }}>
                  Show results
                </Button>
            }
          </div>
          {/* </Box> */}
        </Drawer>
      </>
    )
  }
)

export default MenuItems
