import React, { useEffect, useState } from 'react';
import "./view-details.css";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderDetails, storeOpenOrderId } from '../../state-management/main/actions';
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router";
import axios from "axios";
import sha256 from "js-sha256";
import { ImUser } from 'react-icons/im';
import { IoIosArrowBack } from 'react-icons/io';
import LargeScreenErrorPage from '../largeScreenErrorPage/LargeScreenErrorPage';

const ViewDetails = () => {
    const History = useHistory()
    const [visible, setVisible] = useState();
    const dispatch = useDispatch()
    const params = useParams();
    const [loader, setLoader] = useState(false)
    const [cancelLoader, setCancelLoader] = useState(false)
    const [viewDetailsInstead, setViewDetailsInstead] = useState(false)

    let skeletonArray = [1, 2, 3, 4, 5, 6, 7];

    const orderId = useSelector((state) => state)
    const main = useSelector((state) => state.main)
    const menu = useSelector((state) => state.menu)

    useEffect(async () => {
        if (window.screen.width > 450) {
            setVisible(false)
        } else {
            setVisible(true)
        }
        getOpenTableOrder()
    }, [])

    const getOpenTableOrder = () => {
        var axios = require('axios');
        var data = JSON.stringify({
            "orderId": params.orderid.slice(8),
            "restId": params.restid.slice(7)
        });

        var config = {
            method: 'post',
            url: 'https://api.ciboapp.com/api/selforder/getOrderById',
            headers: {
                'Authorization': 'Basic Wm90dG86MTIzNDU=',
                'Content-Type': 'application/json'
            },
            data: data
        };

        setLoader(true);
        axios(config)
            .then(function (response) {
                // console.log("fetch openorders", response.data);
                dispatch(fetchOrderDetails(response.data));
                setLoader(false);
                setViewDetailsInstead(false);
            })
            .catch(function (error) {
                console.log("viewDetails Error", error);
                setLoader(false);
                setViewDetailsInstead(true);
            });
    }

    const orderCancel = () => {
        var axios = require('axios');
        var data = JSON.stringify({
            "order_id": params.orderid.slice(8)
        });

        var config = {
            method: 'post',
            url: 'https://api.ciboapp.com/api/selforder/cancelOrderQR',
            headers: {
                'Authorization': 'Basic Wm90dG86MTIzNDU=',
                'Content-Type': 'application/json'
            },
            data: data
        };

        setCancelLoader(true);
        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                setCancelLoader(false);
                // alert("Order Canceled.");
                // History.goBack()
                History.push(
                    `/orderCancelled/restid=${params.restid.slice(7)}/tid=${params.tid.slice(4)}/orderid=${params.orderid.slice(8)}`
                )
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    let filteredDataById = main?.fetchOpenOrder?.data?.filter(n => n.order_id == params.orderid.slice(8));

    const goToPaymentOption = () => {
        History.push(
            `/paymentOption/orderid=${params.orderid.slice(8)}`
        )
    }

    const goHomeBack = () => {
        History.push(
            `/restId=${menu.restaurantInfo.restaurant_id}&tid=${main.tableId}/tableDetails`
        )
        // History.goBack()
    }

    const storeOrderId = () => {
        dispatch(storeOpenOrderId({ "order_id": params.orderid, "order_id_status": "update" }));
        History.push(
            `/restId=${menu.restaurantInfo.restaurant_id}&tid=${main.tableId}/menu`
        )
    }

    let demoItems = {
        "data": [
            { name: "Biriyani", price: '90.88' },
            { name: "Paneer Tikka", price: '80.98' },
            { name: "Malai Kofta", price: '60.56' },
            { name: "Veg Korma", price: '55.36' },
            { name: "Chcken Soup", price: '34.36' },
            { name: "Salad", price: '55.96' },
            { name: "Dal Makhni", price: '58.96' },
            { name: "Samosha", price: '89.98' },
            { name: "Chocken Lolippop", price: '46.36' }
        ]
    }

    return (
        <>
            <input style={{height: '0px', color: '#fff', borderStyle: 'hidden'}} autofocus></input>
            {visible
                ?
                (
                    <div className='parent-div'>
                        {loader
                            ?
                            (
                                <div style={{ marginTop: "10%" }}>
                                    {
                                        skeletonArray.map((i) => {
                                            return (
                                                <div key={i} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "10%" }}>
                                                    <Skeleton height={40} width={155} />
                                                    <Skeleton height={40} width={155} />
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        [1, 2, 3].map((i) => {
                                            return (
                                                <div style={{ marginBottom: "10%" }} key={i}>
                                                    <Skeleton height={50} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                            :
                            (
                                <div>
                                    <div className="fxd-top">
                                        <div className='order-no'>
                                            <IoIosArrowBack size={27} onClick={goHomeBack} />
                                            <div style={{ width: '90%', textAlign: 'center' }}>
                                                Order #
                                                {
                                                    viewDetailsInstead
                                                        ?
                                                        filteredDataById[0]?.order_id
                                                        :
                                                        main?.fetchOrderDetails?.data?.order_id
                                                }
                                            </div>
                                        </div>

                                        <div className='all-details-parent'>
                                            <div className='unpaid-text'>
                                                Unpaid order
                                            </div>
                                            <div className='dtls-text'>
                                                {
                                                    viewDetailsInstead
                                                        ?
                                                        `${filteredDataById[0]?.order_date?.substring(0, 10)} at ${filteredDataById[0]?.order_time?.substring(0, 5)}`
                                                        :
                                                        `${main?.fetchOrderDetails?.data?.order_date?.substring(0, 10)} at ${main?.fetchOrderDetails?.data?.order_time?.substring(0, 5)}`
                                                }
                                            </div>
                                        </div>

                                        <div className='all-details-parent'>
                                            <div style={{ display: 'flex', alignContent: 'center' }}>
                                                <ImUser size={20} />&nbsp;&nbsp;
                                                <div className='dtls-text'>
                                                    {
                                                        viewDetailsInstead
                                                            ?
                                                            filteredDataById[0]?.delivery_firstname && filteredDataById[0]?.delivery_firstname
                                                            :
                                                            main?.fetchOrderDetails?.data?.delivery_firstname && main?.fetchOrderDetails?.data?.delivery_firstname
                                                    }
                                                </div>
                                            </div>
                                            <div className='dtls-text'>
                                                {
                                                    viewDetailsInstead
                                                        ?
                                                        filteredDataById[0]?.delivery_mobile
                                                        :
                                                        main?.fetchOrderDetails?.data?.delivery_mobile
                                                }
                                                {/* {main?.fetchOrderDetails?.data?.delivery_mobile} */}
                                            </div>
                                        </div>
                                        {
                                            !viewDetailsInstead
                                            &&
                                            <div className='item'>Items</div>
                                        }
                                    </div>

                                    <div style={{ paddingTop: '15rem', paddingBottom: '22px' }}>
                                        {main?.fetchOrderDetails?.data?.product?.map((val, i) => {
                                            return (
                                                <div key={i}>
                                                    <div className='itemsList'>
                                                        <div className='items-text'>
                                                            {val?.quantity}&nbsp;
                                                            <span style={{ fontSize: '20px' }}>&#215;</span>
                                                            &nbsp;{val?.name}
                                                        </div>
                                                        <div className='items-text'>
                                                            {menu?.restaurantInfo?.monetary_symbol}
                                                            {val?.sub_total.toString().includes('.')
                                                                ?
                                                                val?.sub_total
                                                                :
                                                                val?.sub_total + `.00`
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>


                                    <div className='fxd-btn'>
                                        <div className='all-details-parent'>
                                            <div className='dtls-text'>
                                                Total
                                            </div>
                                            <div className='dtls-text'>
                                                {menu?.restaurantInfo?.monetary_symbol}

                                                {
                                                    viewDetailsInstead
                                                        ?
                                                        (
                                                            filteredDataById[0]?.grand_total.toString().length > 10
                                                                ?
                                                                filteredDataById[0]?.grand_total.toString().substring(0, 8) + `..`
                                                                :
                                                                filteredDataById[0]?.grand_total.toString().includes('.')
                                                                    ?
                                                                    filteredDataById[0]?.grand_total
                                                                    :
                                                                    filteredDataById[0]?.grand_total + `.00`
                                                        )
                                                        :
                                                        (
                                                            main?.fetchOrderDetails?.data?.grand_total.toString().length > 10
                                                                ?
                                                                main?.fetchOrderDetails?.data?.grand_total.toString().substring(0, 8) + `..`
                                                                :
                                                                main?.fetchOrderDetails?.data?.grand_total.toString().includes('.')
                                                                    ?
                                                                    main?.fetchOrderDetails?.data?.grand_total
                                                                    :
                                                                    main?.fetchOrderDetails?.data?.grand_total + `.00`
                                                        )
                                                }
                                            </div>
                                        </div>

                                        <Button
                                            onClick={goToPaymentOption}
                                            style={{
                                                backgroundColor: "#000000",
                                                color: "white",
                                                width: "100%",
                                                padding: "10px",
                                                textTransform: "none",
                                                fontSize: "17px",
                                                borderRadius: "10px",
                                                fontFamily: "Poppins",
                                                letterSpacing: '0.1rem',
                                                height: '3.7rem'
                                            }}>
                                            Pay Now
                                        </Button>

                                        <Button
                                            onClick={orderCancel}
                                            style={{
                                                backgroundColor: "#fff",
                                                color: "#000000",
                                                width: "100%",
                                                padding: "10px",
                                                textTransform: "none",
                                                fontSize: "17px",
                                                borderRadius: "10px",
                                                marginTop: '20px',
                                                fontFamily: "Poppins",
                                                letterSpacing: '0.1rem',
                                                height: '3.7rem'
                                            }}>
                                            {cancelLoader
                                                ?
                                                <div className="loader"></div>
                                                :
                                                "Cancel"}
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
                :
                (
                    <LargeScreenErrorPage />
                    // <p style={{ fontSize: "20px", textAlign: "center", marginTop: "20%", fontFamily: 'Poppins' }}>
                    //     Sorry This website is just for mobile phones ! (refresh page once, if
                    //     already using phone)
                    // </p>
                )
            }
        </>
    )
}

export default ViewDetails