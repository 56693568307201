import React, { useEffect, useState } from 'react'
import IsDealForcedModCategory from '../../Modifiers/ForcedModifiers/ModifiersCategory/IsDealForcedModCategory'
import "./DishModal.css"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const IsDealForcedMod = ({
    item,
    radioBtnValue,
    isDealForTotalPrice,
    setIsDealForTotalPrice,
    lowestPricearray,
    onSelectionChange
}) => {
    const [value, setValue] = React.useState(item?.items[0]?.fm_item_id);
    const [priceCalData, setPriceCalData] = useState([])
    const [totalprc, setTotalprc] = useState()

    function getTotalPrice(updatedForcedModifiers) {
        let selectedDealId = updatedForcedModifiers.filter(object => object?.addon_price == undefined)[0]?.fm_item_id
        let tempMod = updatedForcedModifiers.filter(object => object?.addon_price != undefined)
        let selectedMod = []
    
        for (var i = 0; i < tempMod.length; i++) {
          for (var j = 0; j < tempMod[i].addon_price.length; j++) {
            if (tempMod[i].addon_price[j].fm_item_id == selectedDealId) {
              selectedMod.push(tempMod[i].addon_price[j])
            }
          }
        }

        console.log("its selectedMod here", selectedMod)

        console.log("its selectedMod u here", updatedForcedModifiers)
    
        let totals = 0
        updatedForcedModifiers.map((val) => {
          totals += Number(val?.price)
        })
    
        selectedMod.map((val) => {
          totals += Number(val?.fm_item_price)
        })
    
        return totals
      }

    const onOptionChange = (event) => {
        console.log("its value item here", item)
        console.log("its value L here", lowestPricearray)

        let updatedModifiers = []

        let changedItemCatId = item?.items?.filter((n => n.fm_item_id == event.target.value))[0].fm_cat_id
        let dltchangedItem = lowestPricearray.filter(object => object.fm_cat_id !== changedItemCatId);

        for (var i = 0; i < dltchangedItem.length; i++) {
            updatedModifiers.push(dltchangedItem[i])
        }

        let newItem = item?.items?.filter((n => n.fm_item_id == event.target.value))

        updatedModifiers.push(newItem[0])

        console.log("its value single here", updatedModifiers)

        onSelectionChange &&
        onSelectionChange(updatedModifiers, getTotalPrice(updatedModifiers))

        setValue(event.target.value);
    };

    useEffect(() => {
        setIsDealForTotalPrice([])

        setPriceCalData(item?.items?.filter((n => n.fm_item_id == value)))
    }, [value])

    useEffect(() => {
        let addonsPrice = priceCalData[0]?.addon_price?.filter((n => n.fm_item_id == radioBtnValue))

        if (priceCalData?.length >= 1 && addonsPrice != undefined) {
            // console.log("TotaladdonsPrice", Number(priceCalData[0]?.price) + Number(addonsPrice[0]?.fm_item_price));
            setTotalprc(priceCalData[0]?.price + addonsPrice[0]?.fm_item_price);

            setIsDealForTotalPrice([
                ...isDealForTotalPrice,
                {
                    price: Number(priceCalData[0]?.price) + Number(addonsPrice[0]?.fm_item_price)
                },
            ]);
        } else {
            console.log("nothing")
        }

    }, [priceCalData])



    return (
        <>
            <div style={{ paddingLeft: '14%', marginBottom: '15px' }}>
                <div style={{ backgroundColor: '#eeedf2', paddingTop: '20px', paddingBottom: '2px', borderRadius: '15px' }}>
                    <div className="choose-cat">{item?.fm_cat_name}</div>

                    <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '20px', marginTop: '10px' }}>

                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={onOptionChange}
                            >
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {
                                        item?.items?.map((chitem, j) => {
                                            return (
                                                <>
                                                    <FormControlLabel
                                                        style={{
                                                            fontFamily: 'Poppins',
                                                            fontSize: '12px',
                                                            letterSpacing: '0.6px',
                                                            fontWeight: '500',
                                                            backgroundColor: '#fff',
                                                            color: '#000',
                                                            borderRadius: '20px',
                                                            marginBottom: '10px',
                                                            paddingRight: '8px',
                                                            marginRight: '22px'
                                                        }}
                                                        value={chitem?.fm_item_id} control={<Radio />} label={chitem?.fm_item_name} />
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </RadioGroup>
                        </FormControl>

                    </div>

                </div>
            </div>
        </>
    )
}

export default IsDealForcedMod