import Appheader from "../AppHeader/AppHeader"
import "../Checkout/Checkout.css"
// import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import {
  setDeliveryCost,
  setIsTakeAway,
  setPickupTime
} from "../../state-management/user/actions"
import {
  isHappyHourStillActive,
  setTimer
} from "../../state-management/menu/utils"
import {
  addItem,
  removeItem,
  clearMenuState,
  placeOrderRequest
} from "../../state-management/menu/actions"
import { TimePicker } from "antd"
import { notification } from "antd"
import "antd/dist/antd.css"
import { getFormattedRequestPayload } from "../Checkout/utils"
import PaymentForm from "../Checkout/PaymentForm"
import {
  checkCoupons,
  placeOrder,
  couponRedeem
} from "../../state-management/menu/asyncActions"
import axios from "axios"
import sha256 from "js-sha256"
import { ArrowBack, CompassCalibrationOutlined } from "@material-ui/icons"
import { useHistory } from "react-router"
import { getTaxes } from "../../state-management/menu/operations"
import WaitingOverlay from "../WaitingOverlay/WaitingOverlay"
import AppHeader from "../AppHeader/AppHeader"
import Footer from "../Footer/Footer"
import RenderModifiers from "../../containers/Modifiers/RenderModifiers"
import { modalNames } from "../AppModal/constants"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import { closeModal, openModal } from "../../state-management/modal/actions"
import { setComment } from "../../state-management/menu/actions"
import { makeStyles } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { Box, Drawer, ListItem, SwipeableDrawer } from "@material-ui/core"
import LoadingBar from "../../components/LoadingBar/LoadingBar"
import DemoFoodImage from "./demo-food.png"

import RestrictUser from "../RestrictUser/RestrictUser"
import { firstToken } from "../../state-management/main/actions"
import LargeScreenErrorPage from "../largeScreenErrorPage/LargeScreenErrorPage"
const useStyle = makeStyles({
  list: {
    width: 450
  },
  "@media (max-width:768px)": {
    list: {
      width: 250
    }
  }
})

const Checkout = () => {
  const History = useHistory()

  const main = useSelector((state) => state.main)
  const menu = useSelector((state) => state.menu)
  const user = useSelector((state) => state.user)

  const [couponisapplied, setcouponisapplied] = useState(false)
  const [amountaftercouponapplied, setamountaftercouponapplied] = useState()
  const [couponappliedname, setcouponappliedname] = useState()
  const [couponamount, setcouponamount] = useState()
  const [couponId, setcouponId] = useState()
  const [couponredeemed, setcouponredeemed] = useState(false)
  const [couponredeemedmessage, setcouponredeemedmessage] = useState()
  const [coupontypeisdiscount, setcoupontypeisdiscount] = useState(false)
  const [paymentfailed, setpaymentfailed] = useState(false)
  const [placeOrderLoader, setPlaceOrderLoader] = useState(false)

  const checkTokenBack = () => {
    window.location.href = `https://menu.ciboapp.com/restId=${main.selectedRestaurant.restaurant_id}&tid=${main.tableId}/tableDetails`
  }

  useEffect(() => {

    if (main?.firstToken?.newToken == null) {
      checkTokenBack()
    } else if (localStorage.getItem('localNewToken') == null) {
      checkTokenBack()
    } else if (main?.firstToken?.newToken == '') {
      checkTokenBack()
    } else if (localStorage.getItem('localNewToken') == '') {
      checkTokenBack()
    } else if (localStorage.getItem('localNewToken') != main?.firstToken?.newToken) {
      checkTokenBack()
    } else {
      return
    }

  }, [])

  const backToMenu = () => {
    History.push(
      `/restId=${menu.restaurantInfo.restaurant_id}&tid=${main.tableId}/menu`
    )
  }

  useEffect(() => {
    if (menu.cart.length < 1) {
      backToMenu()
    } else {
      return
    }
  }, [menu.cart.length])


  const dispatch = useDispatch()
  let refIndex = -1
  const timeOutRef = Array.from({ length: 100 }, () => React.createRef())
  const DELIVERY_TYPE = {
    DEFAULT: "Delivery",
    TAKE_AWAY: "PickUp",
    HOME_DELIVERY: "Delivery",
    EAT_IN: "EatIn"
  }
  const [data, setdata] = useState({
    // displayloader: false,
    deliveryType: user.isTakeAway
      ? DELIVERY_TYPE.TAKE_AWAY
      : DELIVERY_TYPE.HOME_DELIVERY,
    //selectedAddress: user.distance >= 0 ? user.selectedAddress : null,

    isAddressesFetched: false,
    paymentMethod: "",
    // I wonder why we need these 3 states - this needs a lot of refactoring
    openingBusinesHours: main.opening,
    closingBusinesHours: main.closing,
    businessHours: main.businessHour,
    isTakeAway: user.isTakeAway,
    orderButtonClicked: false,
    // addresses: [],
    paymentOptions: [],
    deliveryCharges: "",
    hasDeliveryOption: false,
    hasEatInOption: false,
    hasPickupOption: false,
    pickupTime:
      moment().add(30, "minutes").format("HH:mm") >= main.opening &&
        moment().add(30, "minutes").format("HH:mm") <= main.closing
        ? moment().add(30, "minutes")
        : moment(main.opening, "HH:mm")
  })
  const [show, setshow] = useState(false)
  let isDecimalAmount = false
  const [state, setState] = useState({
    showAddress: false,
    deliveryType: ""
  })

  const truncateDecimal = (number) => {
    return Number.isInteger(Number(number))
      ? Number(number)
      : Number(number).toFixed(2)
  }
  const grandTotal = Number(getGrandTotal())
  const [billPercentAmount, setbillPercentAmount] = useState()
  const [restrict, setRestrict] = useState(false)
  const [visible, setvisible] = useState()

  useEffect(() => {
    if (window.screen.width > 450) {
      setvisible(false)
    } else {
      setvisible(true)
    }

    if (!user.user.isUserLoggedIn) {
      setRestrict(true)
    }
    if (main.deliveryRange)
      console.log("xyz", data.pickupTime)

    const deliveryOptions = !(
      main.selectedRestaurant.order_option === "" ||
      main.selectedRestaurant.order_option === null ||
      main.selectedRestaurant.order_option === undefined
    )

    if (main.selectedRestaurant.order_option) {
      data.hasDeliveryOption = main.selectedRestaurant.order_option
        .split(",")
        .includes("delivery")
      data.hasEatInOption = main.selectedRestaurant.order_option
        .split(",")
        .includes("eatin")
      data.hasPickupOption = main.selectedRestaurant.order_option
        .split(",")
        .includes("pickup")
    }

    data.paymentOptions = main.selectedRestaurant.payment_option_ids.split(",")

    setdata({
      ...data
    })
    // console.log("dtaa is", data)
    setbillPercentAmount(getBillAmount() / 100)
  }, [])

  const isPriceWithoutTax = () => {
    // console.log(
    //   "price without tax",
    //   menu.restaurantInfo["price_without_tax_flag"]
    // )
    return Number(menu.restaurantInfo["price_without_tax_flag"])
  }

  function getItemPrice(item, isStillActive) {
    // console.log("getItem item is", item)
    if (item.happyHourItem && isStillActive) {
      return isPriceWithoutTax
        ? truncateDecimal(item.happyHourItem.grandTotal)
        : truncateDecimal(item.happyHourItem.grandTotal)
    } else {
      return isPriceWithoutTax ? item.grandTotal : item.grandTotal

    }
  }

  const getActualPrice = (item) => {
    if (menu.restaurantInfo) {
      return isPriceWithoutTax()
        ? item.price
        : (
          Number(item.price) +
          Number(getTaxes(item, item.price, menu.restaurantInfo).tax)
        ).toFixed(2)
    }

    return 0
  }

  function getSubTotal() {
    return menu.cart.length
      ? truncateDecimal(
        menu.cart.reduce((acc, item) => {
          if (
            isHappyHourStillActive(item, menu.restaurantInfo.timezone)
              .isActive &&
            item.happyHourItem
          ) {
            return (
              acc +
              (item.happyHourItem.subTotal
                ? Number(item.happyHourItem.subTotal)
                : Number(item.qty) * Number(item.price))
            )
          }

          return (
            acc +
            (item.subTotal ? item.subTotal : item.qty * Number(item.price))
          )
        }, 0)
      )
      : ""
  }

  function getSubTaxTotal() {
    return menu.cart.length
      ? truncateDecimal(
        menu.cart.reduce((acc, item) => {
          if (
            isHappyHourStillActive(item, menu.restaurantInfo.timezone)
              .isActive &&
            item.happyHourItem
          ) {
            return (
              acc +
              (item.happyHourItem.tax
                ? item.happyHourItem.tax
                : item.tax || 0)
            )
          }

          return acc + Number(item.tax || 0)
        }, 0)
      )
      : ""
  }

  function getGrandTotal() {
    return menu.cart.length
      ? truncateDecimal(
        menu.cart.reduce((acc, item) => {
          if (
            isHappyHourStillActive(item, menu.restaurantInfo.timezone)
              .isActive &&
            item.happyHourItem
          ) {
            return (
              acc +
              Number(
                item.happyHourItem.grandTotal
                  ? item.happyHourItem.grandTotal
                  : item.happyHourItem.subTotal
              )
            )
          }

          return acc + (item.grandTotal ? item.grandTotal : item.subTotal)
        }, 0)
      )
      : ""
  }

  const getBillAmount = () => {
    var finalAmount
    if (couponisapplied) {
      var finalAmount =
        (Number(user.delivery_cost) + Number(getGrandTotal())).toFixed(2) -
        couponamount
    } else {
      var finalAmount = (
        Number(user.delivery_cost) + Number(getGrandTotal())
      ).toFixed(2)
    }
    const showAmountInDecimal =
      Number(menu.restaurantInfo["show_prices_in_decimal_flag"]) === 1

    if (showAmountInDecimal) {
      isDecimalAmount = true
    } else {
      isDecimalAmount = finalAmount % 1 === 0
    }

    return !showAmountInDecimal && finalAmount % 1 !== 0
      ? finalAmount
      : finalAmount
  }

  const getDeliveryCharges = () => {
    if (!main.deliveryRange) return
    const cost = main.deliveryRange.cost
    const range = main.deliveryRange.range
    const isTakeAway = user.isTakeAway
    const distance = user.distance

    const freeDeliveryRangeAmount = cost["free_delivery_eligible_amount"]
    const stdDeliveryCost = cost["std_delivery_cost"] || 0

    const isFreeDelivery =
      grandTotal >= Number(cost["free_delivery_eligible_amount"]) &&
      parseInt(freeDeliveryRangeAmount) !== 0

    if (isFreeDelivery || isTakeAway) {
      dispatch(setDeliveryCost(0))
      return 0
    }

    if (distance === -1 || !range) {
      if (stdDeliveryCost) {
        dispatch(setDeliveryCost(Number(stdDeliveryCost)))
      }
      return Number(stdDeliveryCost)
    }

    if (range) {
      const selectedRange = range.find((rng) => {
        const from = Number(rng["range_from"])
        const to = Number(rng["range_to"])

        return distance >= from && distance <= to
      })

      if (selectedRange) {
        if (selectedRange["delivery_cost"]) {
          dispatch(setDeliveryCost(selectedRange["delivery_cost"]))
        } else {
          dispatch(setDeliveryCost(0))
        }
      } else {
        dispatch(setDeliveryCost(Number(stdDeliveryCost)))
      }
      return selectedRange
        ? Number(selectedRange["delivery_cost"] || 0)
        : Number(stdDeliveryCost)
    }

    return 0
  }

  const handleAddItem = (item) => {
    // console.log("item", item);
    dispatch(
      addItem(item, item.modifiers || 0, item.subTotal, menu.restaurantInfo)
    )
  }

  const handleRemoveItem = (item) => {
    // console.log("item", item);

    dispatch(removeItem(item, item.modifiers || null, 0, menu.restaurantInfo))
  }

  const getSavedAmount = () => {
    return menu.cart.length
      ? truncateDecimal(
        menu.cart.reduce((acc, item) => {
          if (
            isHappyHourStillActive(item, menu.restaurantInfo.timezone)
              .isActive &&
            item.happyHourItem
          ) {
            return (
              acc +
              (item.grandTotal -
                ((item.happyHourItem && item.happyHourItem.grandTotal) || 0))
            )
          }

          return acc
        }, 0)
      )
      : ""
  }

  const savedAmount = Math.abs(getSavedAmount())
  var toSendOrderId = ""

  // Generating orderID
  const getOrderId = () => {
    if (main?.storeOpenOrderId?.order_id_status === "new") {
      return new Date().getTime().toString()
    } else {
      // console.log("Its a update request", main?.storeOpenOrderId?.order_id)
      return main?.storeOpenOrderId?.order_id
    }
  }

  function placeOrder(order) {
    // console.log("Place Orderrrrrrrrrrrr: ", order)
    return async (dispatch) => {
      dispatch(placeOrderRequest())
      // const OLDurl = "https://ciboapp.com/api/mobileApi/v2/app/sendOrderSync"
      // const url = "https://api.ciboapp.com/api/selforder/QrOrderSync"

      var axios = require('axios');
      var data = JSON.stringify(
        order
      );

      var config = {
        method: 'post',
        url: 'https://ciboapp.com/api/mobileApi/v2/app/sendOrderSync',
        // url: "https://api.ciboapp.com/api/selforder/QrOrderSync",
        // headers: {
        //   'Authorization': 'Basic Wm90dG86MTIzNDU=',
        //   'Content-Type': 'application/json'
        // },
        data: data
      };

      // console.log("orderData", order)

      // return

      setPlaceOrderLoader(true);
      axios(config)
        .then(function (response) {
          // console.log("Order Success Message is", response.data.message);
          // dispatch(firstToken({ "newToken": '' }));
          // localStorage.setItem('localNewToken', '');
          setPlaceOrderLoader(false);
          History.push(
            `/PaymentsOptions/orderid=${toSendOrderId}`
          )

          // History.push(
          //   `/restId=${main.restId}/tid=${main.tableId}/ordersuccess/orderid=${toSendOrderId}`
          // )
        })
        .catch(function (error) {
          console.log("Errrrrrrrrroooorrrrrrrrrrr", error);
          setPlaceOrderLoader(false);
          alert("Something Went Wrong. Try again.");
        });
    }
  }

  const handleCheckout = async (deliveryDetails) => {
    var cpamount = couponamount
    var cpid = couponId

    setdata({ ...state, displayloader: true })

    const orderId = getOrderId()
    toSendOrderId = orderId

    var savedAmount = "1"
    const response_format = getFormattedRequestPayload(
      user,
      user.selectedPickUpTime,
      menu.restaurantInfo,
      deliveryDetails,
      orderId,
      getSubTotal(),
      getSubTaxTotal(),
      getBillAmount(),
      menu.cart,
      user.distance,
      Math.abs(getSavedAmount()),
      getDeliveryCharges(),
      user.user.phonecode,
      user.selectedDeliveryTime,
      cpid,
      cpamount,
      main.tableId
    )

    const response = await dispatch(
      placeOrder(
        getFormattedRequestPayload(
          // props.phone_code,
          user,
          user.user.selectedPickUpTime,
          menu.restaurantInfo,
          deliveryDetails,
          orderId,
          getSubTotal(),
          getSubTaxTotal(),
          getBillAmount(),
          menu.cart,
          user.distance,
          Math.abs(getSavedAmount()),
          getDeliveryCharges(),
          user.user.phonecode,
          user.user.selectedDeliveryTime,
          cpid,
          cpamount,
          main.tableId
        ),
        user.user.token
      )
    )
  }

  function showPizzaDetails(pizza) {
    dispatch(
      openModal(modalNames.RENDER_PIZZADETAILS_CHECKOUT, {
        item: {
          ...pizza
        }
      })
    )
  }

  const [comm, setcomm] = useState()
  function handleComments(e) {
    dispatch(setComment(e.target.value))
    setcomm(e.target.value)
  }

  const [discountnumber, setdiscountnumber] = useState()
  const [maxdiscountnumber, setmaxdiscountnumber] = useState()
  useEffect(() => {
    if (coupontypeisdiscount) {
      var amt = (Number(user.delivery_cost) + Number(getGrandTotal())).toFixed(
        2
      )
      var fp = amt / 100
      var cp = truncateDecimal(fp * discountnumber)
      if (maxdiscountnumber > cp || maxdiscountnumber == 0) {
        setcouponamount(cp)
      } else if (maxdiscountnumber < cp) {
        setcouponamount(maxdiscountnumber)
      }
    }
  }, [getBillAmount()])

  const [draweropen, setdraweropen] = useState(false)
  const classes = useStyle()

  const redeemCoupon = async (code, couponId) => {
    setdata({ ...state, displayloader: true })
    const response = await dispatch(
      checkCoupons(
        user.user.clientId,
        moment().format("yyyy-MM-DD"),
        menu.restaurantInfo.restaurant_id,
        code
      )
    )

    if (response.data.status == "200") {
      if (response.data.data.type == "amount") {
        setcoupontypeisdiscount(false)
        setamountaftercouponapplied(getBillAmount() - response.data.data.amount)
        setcouponisapplied(true)
        setcouponappliedname(response.data.data.coupon_name)
        setcouponamount(response.data.data.amount)
        setcouponId(response.data.data.coupon_id)
        setcouponredeemed(false)
      } else if (response.data.data.type == "discount") {
        setcoupontypeisdiscount(true)
        setdiscountnumber(response.data.data.discount)

        var billPercentAmount = getBillAmount() / 100
        var percent_amount = truncateDecimal(
          response.data.data.discount * billPercentAmount
        )

        var max_amount = truncateDecimal(response.data.data.max_discount)
        setmaxdiscountnumber(truncateDecimal(response.data.data.max_discount))

        if (max_amount > percent_amount || max_amount == 0) {
          setcouponisapplied(true)
          setcouponappliedname(response.data.data.coupon_name)
          setcouponamount(percent_amount)
          setcouponId(response.data.data.coupon_id)
          setcouponredeemed(false)
        } else if (max_amount < percent_amount) {
          setcouponisapplied(true)
          setcouponappliedname(response.data.data.coupon_name)
          setcouponamount(max_amount)
          setcouponId(response.data.data.coupon_id)
          setcouponredeemed(false)
        }
      }
    } else if (response.data.status == "201") {
      var amount = 0
      setcouponisapplied(false)
      setcouponredeemed(true)
      setcouponamount(amount)

      setcouponredeemedmessage(response.data.message)
    }
    setdraweropen(false)
    setdata({ ...state, displayloader: false })
  }


  var nowIsActive = ''
  var totals = 0;
  let calculatedTotals = ''

  const getTotalsAmount = (amt) => {
    // console.log("amt", amt.toFixed(2))

    let amts = amt.toFixed(2)
    let madeTtlDcml = '';
    if (amts.toString().includes('.')) {
      madeTtlDcml = amts?.toString()
    } else {
      madeTtlDcml = amts + '.00'
    }
    let splitTotalArr = madeTtlDcml?.split('.')

    if (splitTotalArr[1]?.length < 2) {
      calculatedTotals = splitTotalArr[0] + '.' + splitTotalArr[1] + '0'
    } else {
      calculatedTotals = splitTotalArr[0] + '.' + splitTotalArr[1]?.substring(0, 2)
    }

  }

  const getOnlyItemPrc = (prc) => {

    let madeTtlDcml = '';
    if (prc.toString().includes('.')) {
      madeTtlDcml = prc?.toString()
    } else {
      madeTtlDcml = prc + '.00'
    }
    let splitTotalArr = madeTtlDcml?.split('.')

    if (splitTotalArr[1]?.length < 2) {
      return splitTotalArr[0] + '.' + splitTotalArr[1] + '0'
    } else {
      return splitTotalArr[0] + '.' + splitTotalArr[1]?.substring(0, 2)
    }
  }

  // console.log("menu.cart", menu.cart)

  return (
    <>
      {visible ? (
        <>
          <div
            className='container margin_60_35'
            style={{
              transform: "none"
            }}
          >
            <div className='row' style={{ transform: "none" }}>
              <div
                className='col-lg-5 hide-on-deskt
                '
                id='sidebar'
                style={{
                  position: "relative",
                  overflow: "visible",
                  boxSizing: "border-box",
                  minHeight: "1px"
                }}>
                <div
                  className='theiaStickySidebar'
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "1px",
                    position: "static",
                    transform: "none"
                  }}
                >
                  <p
                    onClick={backToMenu}
                  >
                    <ArrowBack />
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "22px",
                      fontWeight: "700",
                      fontFamily: 'Poppins',
                      color: "black",
                      marginTop: "-10%"
                    }}
                  >
                    My Cart
                  </p>


                  {/* New Design */}
                  <div
                    id='cart-box'
                    style={{
                      marginBottom: "3%",
                      paddingBottom: "0",
                      width: "100%",
                      borderRadius: "20px",
                      height: "420px",
                      overflow: "auto"
                    }}
                  >
                    <table
                      className='table table_summary'
                      style={{ width: "100%" }}
                    >
                      <tbody>
                        {menu.cart.map((val) => {
                          if (val.isHappyHourActive) {
                            const result = isHappyHourStillActive(
                              val,
                              menu.restaurantInfo.timezone
                            )
                            var isStillActive = result.isActive
                            nowIsActive = result.isActive
                            if (isStillActive) {
                              refIndex++
                              setTimer(result.distance, timeOutRef[refIndex])
                            }
                          }
                          const isImageUrl = require("is-image-url")
                          var checkImageUrl = isImageUrl(val.image_url)

                          getTotalsAmount(totals += Number(getItemPrice(val, isStillActive)))  // actual price

                          // getTotalsAmount(totals += val?.price == "0.01" ? val?.price * val?.qty : Number(getItemPrice(val, isStillActive)))  // actual price

                          // getTotalsAmount(totals += Number(truncateDecimal(getItemPrice(val, isStillActive))))  // rounded price - truncated


                          return (
                            <>
                              <div className="itm-cntnr">
                                <div className='itms-lst'>
                                  <div className='itms-name'>
                                    {val?.name}
                                  </div>
                                  <div className='itms-price'>
                                    {`${menu.restaurantInfo.monetary_symbol}`}
                                    {
                                      getOnlyItemPrc(
                                        // val?.price == "0.01"
                                        //   ?
                                        //   val?.price * val?.qty
                                        //   :
                                          truncateDecimal(getItemPrice(val, isStillActive)).toString().includes('.')
                                            ?
                                            truncateDecimal(getItemPrice(val, isStillActive))
                                            :
                                            truncateDecimal(getItemPrice(val, isStillActive)) + `.00`
                                      )
                                    }
                                  </div>
                                </div>

                                <div className="mdfrs-texts">
                                  {val?.modifiers?.forcedModifier?.map((forModData, j) => {
                                    // console.log("forModData", forModData)
                                    return (
                                      <span key={j}>
                                        {forModData?.fm_item_name || forModData?.name}
                                        {(forModData?.fm_item_name || forModData?.name) && <>,&nbsp;</>}
                                      </span>
                                    )
                                  })}
                                  {val?.modifiers?.optionalModifier?.map((optModData, k) => {
                                    // console.log("optModData", optModData)

                                    return (
                                      <span key={k}>
                                        {optModData?.om_item_name || optModData?.name}
                                        {(optModData?.om_item_name || optModData?.name) && <>,&nbsp;</>}
                                      </span>
                                    )
                                  })}
                                </div>

                                <div className="itm-count-box">
                                  <div onClick={() => handleRemoveItem(val)}
                                    className="itm-count-change-btn"
                                    style={{ marginRight: '10px' }}>
                                    -
                                  </div>

                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '12px', fontSize: '18px' }}>
                                    {val.qty}
                                  </div>

                                  <div onClick={() => handleAddItem(val)}
                                    className="itm-count-change-btn"
                                    style={{ marginLeft: '10px' }}>
                                    +
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>

                  {/* Old Design */}
                  {/* <div
                    id='cart-box'
                    style={{
                      marginBottom: "3%",
                      paddingBottom: "0",
                      width: "100%",
                      borderRadius: "20px",
                      height: "420px",
                      overflow: "auto"
                    }}
                  >
                    <table
                      className='table table_summary'
                      style={{ width: "100%" }}
                    >
                      <tbody>
                        {menu.cart.map((val) => {
                          if (val.isHappyHourActive) {
                            const result = isHappyHourStillActive(
                              val,
                              menu.restaurantInfo.timezone
                            )
                            var isStillActive = result.isActive
                            nowIsActive = result.isActive
                            if (isStillActive) {
                              refIndex++
                              setTimer(result.distance, timeOutRef[refIndex])
                            }
                          }
                          const isImageUrl = require("is-image-url")
                          var checkImageUrl = isImageUrl(val.image_url)
                          return (
                            <>
                              <div
                                style={{
                                  backgroundColor: "#f3f2f7",
                                  width: "100%",
                                  height: "auto",
                                  display: "flex",
                                  borderRadius: "10px",
                                  marginBottom: "4%"
                                }}
                              >
                                <div className='image-cropper'>
                                  <img
                                    alt='Avatar'
                                    src={
                                      checkImageUrl
                                        ? val.image_url
                                        : DemoFoodImage
                                    }
                                  />
                                </div>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "40%",
                                    position: "relative"
                                  }}
                                >
                                  <h7 className='food-item-name'>
                                    {val?.name}
                                  </h7>
                                  <div>
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "15px",
                                        color: "#bd1e44",
                                        fontWeight: "600"
                                      }}
                                    >
                                      <span
                                        className='float-right'>
                                        {`${menu.restaurantInfo.monetary_symbol}`}
                                        {` ${truncateDecimal(
                                          getItemPrice(val, isStillActive)
                                        )}`}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className='qty-table'
                                  style={{ width: "30%", position: "relative" }}
                                >
                                  <div
                                    className='main-qty'
                                    style={{
                                      position: "absolute",
                                      bottom: "10%",
                                      fontFamily: 'Poppins',
                                    }}
                                  >
                                    <div
                                      className='plus'
                                      onClick={() => handleAddItem(val)}
                                    >
                                      +
                                    </div>
                                    <div
                                      className='minus'
                                      onClick={() => handleRemoveItem(val)}
                                    >
                                      -
                                    </div>
                                    <div className='qty'>{val.qty}</div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                  </div> */}

                </div>
              </div>

              <div className='col-lg-7'>
                <table className='table table_summary'>
                  <tbody>
                    <tr>
                      <td className='total-parent'>
                        <span className='total-text'>TOTAL</span>
                        <span className='total-text'>
                          {menu.restaurantInfo.monetary_symbol}{" "}{calculatedTotals}
                        </span>
                      </td>
                    </tr>
                    <br />
                  </tbody>
                </table>
                <PaymentForm
                  storeOpenOrderId={main?.storeOpenOrderId?.order_id_status}
                  comm={comm}
                  onHandleCheckout={handleCheckout}
                  deliveryCharges={getDeliveryCharges}
                  placeOrderLoader={placeOrderLoader}
                  totalsAmt={totals}
                />
              </div>
            </div>
          </div>
          <Footer />{" "}
        </>
      ) : (
        <LargeScreenErrorPage />
        // <p style={{ fontSize: "20px", textAlign: "center", marginTop: "20%", fontFamily: 'Poppins' }}>
        //   Sorry This website is just for mobile phones ! (refresh page once, if
        //   already using phone)
        // </p>
      )}
    </>
  )
}
export default Checkout
