import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import { animateScroll as scroll } from "react-scroll"
import MenuItems from "../../MenuItems/MenuItems"
import Footer from "../../../components/Footer/Footer"
import "./Information.css"
import Button from "@material-ui/core/Button"
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { CgClose } from 'react-icons/cg';
import { Drawer } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { showLoginFormMethod } from "../../../state-management/user/actions"
import img1 from "./demo-slider-1.jpg"

const useStyle = makeStyles({
  list: {
    width: 360,
    height: 250
  }
})

const Information = (props) => {
  const menu = useSelector((state) => state.menu)
  const user = useSelector((state) => state.user)
  const main = useSelector((state) => state.main)
  const modal = useSelector((state) => state.main)

  const dispatch = useDispatch()
  const [showdish, setshowdish] = useState(true)
  const [freeEligible, setfreeEligible] = useState()
  const [timings, settimings] = useState()

  const [opensDrawer, setOpensDrawer] = React.useState(false)
  const [drawName, setDrawName] = useState()
  const [picUrl, setPicUrl] = useState()
  const [drawPrice, setDrawPrice] = useState()
  const [drawDescriptiion, setDrawDescriptiion] = useState()
  let commonDescription = "Sweet summer corn, stone-ground cornmeal, and a touch of jalapeno. Cornbread with a kick of personality - just like Grandma Dot."
  const [noAnyItem, setNoAnyItem] = useState(false)

  const toggleVisible = () => {
    // const scrolled = document.documentElement.scrollTop
    // if (scrolled >= 1000) {
    //   setVisible(true)
    // } else {
    //   setVisible(false)
    // }
  }

  window.addEventListener("scroll", toggleVisible)
  const [visible, setvisible] = useState()

  useEffect(() => {
    if (window.screen.width > 450) {
      setvisible(false)
    } else {
      setvisible(true)
    }
    if (main.isClosed) {
      settimings("closed")
    } else {
      settimings(main.businessHour)
    }
  }, [])

  const checkTokenBack = () => {
    window.location.href = `https://menu.ciboapp.com/restId=${main.selectedRestaurant.restaurant_id}&tid=${main.tableId}/tableDetails`
  }

  useEffect(() => {
    if(main?.firstToken?.newToken == null){
      checkTokenBack()
    }else if (localStorage.getItem('localNewToken') == null){
      checkTokenBack()
    }else if(main?.firstToken?.newToken == ''){
      checkTokenBack()
    }else if (localStorage.getItem('localNewToken') == ''){
      checkTokenBack()
    }else if (localStorage.getItem('localNewToken') != main?.firstToken?.newToken) {
      checkTokenBack()
    }else{
      return
    }

  }, [])


  const History = useHistory()

  const moveToCart = () => {
    if (menu.cart.length >= 1) {
      History.push(
        `/restId=${menu.restaurantInfo.restaurant_id}&tid=${main.tableId}/checkout`
      )
    } else {
      setNoAnyItem(true);
      setTimeout(() => {
        setNoAnyItem(false);
      }, 3000);
    }
  }

  var x = main.opening
  var arr = []
  arr.push(menu.restaurantInfo?.order_option?.split(","))
  var totals = 0;

  return (
    <>
      <>
        <div className='container margin_60_35'>
          <div className='row customised_row '>
            <div className='col-lg-6'>
              <div
                className='box_style_2 full-width-mobile'
                id='main_menu'
                style={{ marginTop: "-25px" }}>

                <MenuItems
                  status={showdish}
                  pizzas={menu.pizzas}
                  selectedCategoryId={menu.selectedCategoryId}
                  menuItems={menu.menuItems}
                  restaurantInfo={menu.restaurantInfo}
                  searchQuery={menu.searchQuery}
                  categories={menu.categoriesList}
                  happyhours={menu.happyHours}
                  onAddItem={props.onAddItem}
                  onRemoveItem={props.onRemoveItem}
                  loading={props.loading}
                  tableId={main.tableId}
                  setOpensDrawer={setOpensDrawer}
                  setPicUrl={setPicUrl}
                  setDrawName={setDrawName}
                  setDrawPrice={setDrawPrice}
                  setDrawDescriptiion={setDrawDescriptiion}
                  restLogo={main.selectedRestaurant.logo}
                  restId={main.selectedRestaurant.restaurant_id}
                />
              </div>
            </div>
          </div>
        </div>
      </>

      {menu.cart.length >= 1 ? (
        <div class='fixed-bottom'>
          <Button
            onClick={() =>
              History.push(
                `/restId=${menu.restaurantInfo.restaurant_id}&tid=${main.tableId}/checkout`
              )
            }
            style={{
              backgroundColor: "#000000",
              color: "white",
              height: "3.8rem",
              width: "86%",
              textTransform: "none",
              fontSize: "16px",
              borderRadius: "15px",
              marginBottom: "7px",
              fontFamily: 'Poppins',
              letterSpacing: '0.7px'
            }}
          >
            <AiOutlineShoppingCart size={32} style={{ marginRight: "10px" }} />
            <span class="badge">
              {
                menu?.cart?.map((val) => {
                  totals += val?.qty
                })
              }
              {totals ? totals : 0}
            </span>

            View cart and pay
          </Button>
        </div>
      ) : null}

      <Drawer
        anchor={'bottom'}
        open={opensDrawer}
        onClose={() => setOpensDrawer(false)}>
        <div className="draw-header-parent">
          <CgClose onClick={() => setOpensDrawer(false)}
            style={{ marginTop: "-34px" }} size={34} />
          <div className='draw-header'>
            <p className='draw-header-text'>
              {drawName}
            </p>
          </div>
        </div>

        <div className='img-menu-item'>
          <img className='img-menu' alt='Item-Img' src={picUrl} />
        </div>

        <div style={{ marginLeft: "40px", marginRight: "40px" }}>
          <div className='btn-container'>
            <Button
              style={{
                backgroundColor: "#46965e",
                color: "white",
                width: "38%",
                padding: "10px",
                textTransform: "none",
                fontSize: "14px",
                borderRadius: "20px",
                fontWeight: "600",
                fontFamily: "Poppins",
                letterSpacing: '0.1rem'
              }}>
              Vegan
            </Button>

            <Button
              style={{
                backgroundColor: "#e67935",
                color: "white",
                width: "54%",
                padding: "10px",
                fontWeight: "600",
                textTransform: "none",
                fontSize: "14px",
                borderRadius: "20px",
                fontFamily: "Poppins",
                letterSpacing: '0.1rem'
              }}>
              Contains nuts
            </Button>
          </div>
        </div>

        <div style={{ marginLeft: "40px", marginRight: "40px" }}>
          <p className="drawer-quotes">

            {
              drawDescriptiion
                ?
                drawDescriptiion
                :
                ''
            }


            {/* {
              drawDescriptiion?.length != 0
                ?
                drawDescriptiion
                :
                commonDescription
            } */}
          </p>
        </div>

        {noAnyItem ?
          <div className="noAnyItem">
            Please go to menu & select an item.
          </div>
          :
          <div className="noAnyItem">
            &nbsp;
          </div>
        }

        <div className='addCart-container'>
          <div>
            <p className="from-text">Price</p>

            <div className="item-price-text">
              <span style={{ fontFamily: "Poppins" }}>
                {
                  drawPrice?.length > 5
                    ?
                    drawPrice?.substring(0, 7)
                    :
                    drawPrice
                }
              </span>
            </div>
          </div>

          <Button
            onClick={moveToCart}
            style={{
              backgroundColor: "#000",
              color: "white",
              height: "4.6rem",
              width: "65%",
              padding: "10px",
              textTransform: "none",
              fontSize: "18px",
              borderRadius: "20px",
              fontFamily: "Poppins",
              letterSpacing: '0.1rem'
            }}>
            Proceed to Cart
          </Button>
        </div>
      </Drawer>

      <Footer />
    </>
  )
}

export default Information



















