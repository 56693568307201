import React from 'react'
import "./paySuccess.css"
import Button from "@material-ui/core/Button";
import checkImgs from "./checkImg.png"
import { useParams } from "react-router-dom";
import { useHistory } from "react-router"



const PaySuccess = () => {
    const params = useParams();
    const History = useHistory()


    const okButton = () => {
        History.push(
            `/restId=${params.restid.slice(7)}&tid=${params.tid.slice(4)}/tableDetails`
        )
        window.location.reload(false)
    }

    return (
        <div className="order-success-parent">
            {console.log("restid", params.restid.slice(7))}
            {console.log("tidd", params.tid.slice(4))}
            <div className="scs-icon">
                <img src={checkImgs} style={{ width: '68%', height: '68%' }} />
            </div>

            <div className="thankU-text-div">
                <p className="thankU-text">
                    Thank You !
                </p>

                {/* <div className="odrNo-div">
                    <div className="odrs-no"> Order Number</div>
                    <div className="odrs-no">32654726357</div>
                </div> */}

                <div className="msg-div">
                    <div className="msg">Your order is placed successfully.</div>
                    <div className="msg">Your food will be sent to your table soon.</div>
                </div>

                <Button
                    onClick={okButton}
                    style={{
                        backgroundColor: "#000000",
                        color: "white",
                        fontSize: "22px",
                        height: "4rem",
                        width: "100%",
                        padding: "10px",
                        textTransform: "none",
                        borderRadius: "20px",
                        marginTop: "16%",
                        fontFamily: 'Poppins',
                        letterSpacing: '0.1rem'
                    }}>
                    Done
                </Button>
            </div>
        </div>
    )
}

export default PaySuccess