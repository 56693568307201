import React from 'react'
import "./order-cancel-success.css";
import Button from "@material-ui/core/Button";
import odrCancelImg from "./cancelledorder.png"
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux"
import { checkItsBackFromLogo } from '../../state-management/main/actions';

const OrderCancelSuccess = () => {
    const History = useHistory()
    const params = useParams();
    const dispatch = useDispatch()

    var orderId = params.orderid.slice(8)
    var restId = params.restid.slice(7)
    var tid = params.tid.slice(4)

    const goHome = () => {
        dispatch(checkItsBackFromLogo({ "itsBackFromLogo": "no" }));
        History.push(
            `/restId=${restId}&tid=${tid}/tableDetails`
        )
    }

    return (
        <div className="order-cancel-parent">
            <div className="cancel-icon">
                <img src={odrCancelImg} style={{ width: '60%', height: '60%' }} />
            </div>

            <div className="text-divs">
                <p className="cancel-text">
                    Order Cancelled
                </p>

                <div>
                    <div className="odrs-nos"> Order number</div>
                    <div className="odrs-nos">{orderId}</div>
                </div>

                {/* <div className="msgs-div">
                    <div className="msgs">Your order</div>
                    <div className="msgs">has been cancelled.</div>
                </div> */}

                <Button
                    onClick={goHome}
                    style={{
                        backgroundColor: "#000000",
                        color: "white",
                        fontSize: "22px",
                        height: "4rem",
                        width: "100%",
                        padding: "10px",
                        textTransform: "none",
                        borderRadius: "20px",
                        marginTop: "44%",
                        fontFamily: 'Poppins',
                        letterSpacing: '0.1rem'
                    }}>
                    Go Back
                </Button>
            </div>
        </div>
    )
}

export default OrderCancelSuccess