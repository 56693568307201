import React from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import Button from "@material-ui/core/Button"
import "../Checkout/Checkout.css"
import { useEffect, useState } from "react"
import {
  setDeliveryCost,
  setSelectedAddress,
  setIsTakeAway,
  setPickupTime,
  setDeliveryTime,
  setDefaultAddress,
  setDeliveryDistance
} from "../../state-management/user/actions"
import { TimePicker } from "antd"
import { notification } from "antd"
import Address from "../ManageAddress/Address"
import { fetchAddressesList } from "../../state-management/user/asyncActions"
import GoogleMap from "../GoogleMap/GoogleMap"
import Geocode from "react-geocode"
import AddAddress from "../ChooseAddress/AddAddress"
import { TextField, useTheme } from "@material-ui/core"
import { truncateDecimal } from "../../state-management/menu/utils"
import {
  isHappyHourStillActive,
  setTimer
} from "../../state-management/menu/utils"

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


import ChooseAddress from "../ChooseAddress/ChooseAddress"
import { openModal, closeModal } from "../../state-management/modal/actions"
import { displayAddressModal } from "../../state-management/menu/actions"
import DeliveryType from "./DeliveryType"
import TakeAddress from "./TakeAddress"
import SelectTimingsForDelivery from "./SelectTimingsForDelivery"
import SelectTimingsForPickup from "./SelectTimingsForPickup"
import { width } from "@mui/system"
Geocode.setApiKey("AIzaSyCMTj6FEwu3Kh0tSdgp6hh4QOKgIJF74rs")


const PaymentForm = (props) => {
  const main = useSelector((state) => state.main)
  const menu = useSelector((state) => state.menu)
  const user = useSelector((state) => state.user)
  const modal = useSelector((state) => state.modal)
  const [listofpayment, setlistofpayment] = useState([])
  const [getpaymentoption, setgetpaymentoption] = useState()
  const [code, setCode] = useState('');
  const [getDets, setgetDets] = useState({
    name: "",
    phone: "",
    email: ""
  })

  const dispatch = useDispatch()
  const [preName, setPreName] = useState()
  const [prePhone, setPrePhone] = useState()
  const [countryCodes, setCountryCodes] = useState()

  let countryCodeArry = [menu.restaurantInfo.phone_code, 93, 355, 213, '1-684', 376, 244, 672, '1-264', 54, 374, '1-268', 994, 61, 43, 297, 973, 880, '1-242', 375, 32, '1-246', 501, 229, '1-441', 591, 387, 975, 267, 246, 55, '1-284', 226, 359, 673, 235, 236, 237, 238, 257, 1, 855, '1-345', 56, 57, 61, 86, 269, 506, 682, 53, 599, 385, 357, 420, 243, 45, 253, '1-767', 670, 593, 20, '1-809', '1-829', '1-849', 503, 240, 291, 372, 251, 298, 500, 679, 33, 358, 689, 49, 241, 220, 995, 299, 233, 350, 30, '1-473', '1-671', 502, 224, 245, '44-1481', 36, 592, 509, 504, 852, 91, 62, 98, 354, 964, 972, 353, '44-1624', 225, 39, 81, '1-876', 962, 7, '44-1534', 254, 686, 965, 996, 856, 383, 371, 218, 231, 366, 961, 352, 372, 423, 389, 261, 265, 692, 60, 960, 233, 356, 222, 230, 262, 52, 691, 373, 377, 382, 976, 212, 258, 264, 95, '1-664', 599, 977, 31, 674, 505, 64, 227, 234, 687, 850, 47, '1-670', 92, 680, 970, 968, 507, 675, 595, 351, 51, 64, 48, '1-787', '1-939', 242, 262, 40, 7, 50, 290, 590, '1-869', 508, '1-758', 239, 685, 378, '1-784', 221, 381, 966, 65, 232, 248, 677, 386, 421, 27, 211, 82, 252, 94, 34, 249, 597, 41, 46, 47, 268, 963, 992, 255, 886, 66, 690, 676, 228, '1-868', 216, 90, 993, '1-649', 30, 688, 256, '1-340', 1, 44, 598, 998, 379, 58, 84, 678, 212, 967, 681, 260, 263]

  useEffect(() => {
    if (props.storeOpenOrderId === "update") {
      setPreName(main.fetchOrderDetails.data[0].delivery_firstname)
      setPrePhone(main.fetchOrderDetails.data[0].delivery_mobile)
    } else {
      setCode(menu.restaurantInfo.phone_code)
      // setgetDets({ ...getDets, phone: "+" + menu.restaurantInfo.phone_code })
      return
    }

    const deliveryOptions = !(
      main.selectedRestaurant.order_option === "" ||
      main.selectedRestaurant.order_option === null ||
      main.selectedRestaurant.order_option === undefined
    )

    setlistofpayment(main.selectedRestaurant.payment_option_ids.split(","))
    dispatch(setDeliveryCost(0))
    let z = JSON.parse(window.localStorage.getItem("paymentType"))
  }, [])

  const paymentOptionChange = (e) => {
    setgetpaymentoption(e)
    localStorage.setItem("paymentType", JSON.stringify(e))
  }

  const [error, setError] = useState()

  const onClick = () => {
    if (menu.cart.length < 1) {
      setError("Please go to menu & select an item.");
    } else if (props.totalsAmt < 0.30) {
      setError("Order amount must be at least 0.30");
      setTimeout(() => {
        setError("")
      }, 3000);
    } else if (!getDets.name) {
      setError("Please enter your name.")
    } else if (!getDets.phone) {
      setError("Please enter your phone number.")
    } else {
      let data = {
        customer_name_for_qrorder: getDets.name,
        customer_email_for_qrorder: getDets.email,
        customer_phn_for_qrorder: '+' + code + getDets.phone,
        paymentMethod: getpaymentoption
      }
      props.onHandleCheckout(data)
    }

    // FOR VALLIDATION THE INPUT FIELD
    // if (props.storeOpenOrderId == "new") {

    // if (menu.cart.length < 1) {
    //   setError("Please go to menu & select an item.")
    // } else if (!getDets.name) {
    //   setError("Please enter your name.")
    // } else if (!getDets.phone) {
    //   setError("Please enter your phone number.")
    // } else {
    //   let data = {
    //     customer_name_for_qrorder: getDets.name,
    //     customer_email_for_qrorder: getDets.email,
    //     customer_phn_for_qrorder: '+' + code + getDets.phone,
    //     paymentMethod: getpaymentoption
    //   }
    //   props.onHandleCheckout(data)
    // }

    // } else {
    //   let data = {
    //     customer_name_for_qrorder: preName,
    //     customer_phn_for_qrorder: prePhone,
    //     paymentMethod: getpaymentoption
    //   }
    //   props.onHandleCheckout(data)
    // }
  }

  const getDisabledMinutes = () => {
    let minutes = []
    for (let i = 0; i < moment().minute() + 30; i++) {
      minutes.push(i)
    }
    return minutes
  }

  const grandTotal = Number(getGrandTotal())

  function getGrandTotal() {
    return menu.cart.length
      ? truncateDecimal(
        menu.cart.reduce((acc, item) => {
          if (
            isHappyHourStillActive(item, menu.restaurantInfo.timezone)
              .isActive &&
            item.happyHourItem
          ) {
            return (
              acc +
              Number(
                item.happyHourItem.grandTotal
                  ? item.happyHourItem.grandTotal
                  : item.happyHourItem.subTotal
              )
            )
          }

          return acc + (item.grandTotal ? item.grandTotal : item.subTotal)
        }, 0)
      )
      : ""
  }

  return (
    <>
      {error
        ?
        <div style={{ color: "red", textAlign: "center", fontSize: '16px', fontFamily: 'Poppins', letterSpacing: '0.8px' }}>{error}</div>
        :
        <div style={{ color: "white", textAlign: "center", fontSize: '16px', fontFamily: 'Poppins', letterSpacing: '0.8px' }}>maintain responsivness</div>
      }

      {/* <TextField
        className='profile-textfield'
        size='small'
        style={{ width: "100%", fontFamily: 'Poppins', marginTop: '8px' }}
        label='Full Name'
        variant='outlined'
        // value={preName}
        onChange={(e) => {
          setgetDets({ ...getDets, name: e.target.value })
          setError("")
        }}
      /> */}

      {/* <TextField
        className='profile-textfield'
        size='small'
        style={{ width: "100%", fontFamily: 'Poppins', marginTop: '12px' }}
        label='Email Id'
        variant='outlined'
        onChange={(e) => {
          setgetDets({ ...getDets, email: e.target.value })
          setError("")
        }}
      /> */}

      <div style={{ marginBottom: '10px' }}>
        <input type='text'
          className='name-inp'
          placeholder='Full Name'
          value={getDets.name}
          onChange={(e) => {
            setgetDets({ ...getDets, name: e.target.value })
            setError("")
          }}
        />
      </div>

      <div>
        <input type='text'
          className='email-inp'
          placeholder='Email id'
          value={getDets.email}
          onChange={(e) => {
            setgetDets({ ...getDets, email: e.target.value })
            setError("")
          }}
        />
      </div>

      <div className="ph-dropdown">
        <div style={{ marginBottom: '50px', marginTop: '10px', marginRight: '45px' }}>
          <PhoneInput
            country={'us'}
            value={code}
            onChange={(e) => setCode(e)}
          />
        </div>

        <input className="ph-inp"
          placeholder="Phone number"
          type='text'
          value={getDets.phone}
          onChange={(e) => {
            setgetDets({ ...getDets, phone: e.target.value })
            setError("")
          }}
        />

        {/* <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel>Country Code</InputLabel>
            <Select size='small' value={code} label="Country Code" onChange={(e) => setCode(e.target.value)}>
              {
                countryCodeArry.map((val) => {
                  return (
                    <MenuItem size='small' style={{ display: 'flex', justifyContent: 'center', height: '20px' }} value={val}>
                      +{val}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
        </Box> */}





        {/* <TextField
          size='small'
          style={{ marginLeft: '5px', width: "80%", fontFamily: 'Poppins' }}
          label='Phone Number'
          variant='outlined'
          value={getDets.phone}
          onChange={(e) => {
            setgetDets({ ...getDets, phone: e.target.value })
            setError("")
          }}
        /> */}
      </div>


      {/* <TextField
        className='profile-textfield'
        size='small'
        style={{ width: "100%", fontFamily: 'Poppins', marginTop: '8px' }}
        label='Full Name'
        variant='outlined'
        // value={preName}
        onChange={(e) => {
          setgetDets({ ...getDets, name: e.target.value })
          setError("")
        }}
      />
      <br />
      <br />
      <TextField
        size='small'
        style={{ width: "100%", fontFamily: 'Poppins' }}
        label='Phone Number'
        variant='outlined'
        value={getDets.phone}
        onChange={(e) => {
          setgetDets({ ...getDets, phone: e.target.value })
          setError("")
        }}
      /> */}


      {/* {props.storeOpenOrderId === "new"
        ?
        <>
          <TextField
            className='profile-textfield'
            size='small'
            style={{ width: "100%", fontFamily: 'Poppins', marginTop: '8px' }}
            label='Full Name'
            variant='outlined'
            // value={preName}
            onChange={(e) => {
              setgetDets({ ...getDets, name: e.target.value })
              setError("")
            }}
          />
          <br />
          <br />
          <TextField
            size='small'
            style={{ width: "100%", fontFamily: 'Poppins' }}
            label='Phone Number'
            variant='outlined'
            value={getDets.phone}
            onChange={(e) => {
              setgetDets({ ...getDets, phone: e.target.value })
              setError("")
            }}
          />
        </>
        :
        <>
          <TextField
            disabled
            size='small'
            style={{ width: "100%", fontFamily: 'Poppins' }}
            label='Full Name'
            variant='outlined'
            value={preName}
          />
          <br />
          <br />
          <TextField
            disabled
            size='small'
            style={{ width: "100%", fontFamily: 'Poppins' }}
            label='Phone Number'
            className='profile-textfield'
            variant='outlined'
            value={prePhone}
          />
        </>
      } */}

      <div className='paymentform-btn-container'>
        <Button
          onClick={onClick}
          style={{
            marginTop: "6%",
            marginBottom: "15%",
            fontFamily: 'Poppins',
            letterSpacing: '0.9px',
            backgroundColor: "#000000",
            color: "white",
            height: "3.8rem",
            width: "100%",
            padding: "10px",
            textTransform: "none",
            fontSize: "18px",
            borderRadius: "15px",
            fontFamily: 'Poppins',
          }}
          disable={true}
        >
          {props.placeOrderLoader
            ?
            <div class="loader"></div>
            : "Place Order"}
        </Button>
      </div>
    </>
  )
}
export default PaymentForm
