import React, { useEffect, useState } from "react"
import { Button } from "react-scroll"
//import Button from "@material-ui/core/Button";
//import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch } from "react-redux"
import { openModal, closeModal } from "../../state-management/modal/actions"
import MenuModal from "../../containers/Modals/DishModal/DishModal"
import { addItem, removeItem } from "../../state-management/menu/actions"
import DemoFoodImage from "./demo-food.png"
import {
  isHappyHourStillActive,
  setTimer
} from "../../state-management/menu/utils"
import DishModal from "../../containers/Modals/DishModal/DishModal"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import { getTaxes } from "../../state-management/menu/operations"
import AddIcon from "@material-ui/icons/Add"
import "./MenuTable.css"
import Skeleton from "react-loading-skeleton"
import { modalNames } from "../../components/AppModal/constants"
import RemoveIcon from "@material-ui/icons/Remove"
import img1 from "./388@2x.png"
import img2 from "./387@2x.png"
import img3 from "./389@2x.png"
import img4 from "./391@2x.png"
import img5 from "./390@2x.png"
import img6 from "./demo-slider-1.jpg"
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { BiInfoCircle } from 'react-icons/bi';
import { FiCircle } from 'react-icons/fi';

import { Link } from "react-scroll";

import { truncateDecimal } from "../../state-management/menu/utils"
const MenuTable = ({
  onAddItem,
  onRemoveItem,
  category_name,
  list,
  symbol,
  actualPrice,
  description,
  loading,
  headerforhappyhour,
  itemId,
  setOpensDrawer,
  setPicUrl,
  setDrawName,
  setDrawPrice,
  setDrawDescriptiion
}) => {
  const dispatch = useDispatch()
  const menu = useSelector((state) => state.menu)
  const modal = useSelector((state) => state.modal)
  // console.log("header for happy", category_name)

  // console.log("listr in menutable", list)
  // if (list.length > 1) {
  //   console.log("cnane in menutable", list[0].category_id)
  // }
  const modalNames = {
    DISH_MODAL: "DishModal",
    INTERMEDIATE_ADD_MODAL: "IntermediateAddModal",
    PIZZA_MODAL: "PizzaModal"
  }

  var refIndex = -1
  var timeOutRef = Array.from({ length: 1000 }, () => React.createRef())

  const isPriceWithoutTax = () => {
    // console.log(
    //   "price without tax",
    //   menu.restaurantInfo["price_without_tax_flag"]
    // )
    return Number(menu.restaurantInfo["price_without_tax_flag"])
  }

  const getSelectedCategoryName = (cname) => {
    // console.log("cname in menu", cname)
    var selectedCategoryId
    if (cname.length > 0) {
      selectedCategoryId = cname[0].category_id
    }
    if (menu.categoriesList.length) {
      const category = menu.categoriesList.find(
        (category) =>
          category["sub_category"] &&
          category["sub_category"].find(
            ({ category_id: cId }) => cId === selectedCategoryId
          )
      )
      const subCategory =
        category &&
        category["sub_category"] &&
        category["sub_category"].find(
          ({ category_id: cId }) => cId === selectedCategoryId
        )

      // console.log("yesssssssssssssss", subCategory ? subCategory.cname : "")
      return subCategory ? subCategory.cname : ""
    }

    if (menu.selectedCategoryId === -1) {
      return "Happy Hours"
    } else if (menu.selectedCategoryId === -2) {
      return "Pizza's"
    }
  }

  function getDishesDescription(cname) {
    var selectedCategoryId
    if (cname.length > 0) {
      selectedCategoryId = cname[0].category_id
    }
    if (menu.categoriesList) {
      const category = menu.categoriesList.find(
        (category) =>
          category["sub_category"] &&
          category["sub_category"].find(
            ({ category_id: cId }) => cId === selectedCategoryId
          )
      )
      // console.log("category", category)
      const subCategory =
        category &&
        category["sub_category"] &&
        category["sub_category"].find(
          ({ category_id: cId }) => cId === selectedCategoryId
        )
      // console.log("subcategory is", subCategory)
      // cname = subCategory ? subCategory.cname : "";
      return subCategory ? subCategory.description : ""
    }
  }

  const getActualPrice = (item) => {
    if (menu.restaurantInfo) {
      // console.log("actual price", typeof(this.isPriceWithoutTax()));
      return isPriceWithoutTax()
        ? item.price
        : (
          Number(item.price) +
          Number(getTaxes(item, item.price, menu.restaurantInfo).tax)
        ).toFixed(2)
    }

    return 0
  }

  const getSizeAndBase = (pizza) => {
    const sizeAndBaseCollection = []

    for (let i = 0; i < (pizza.size && pizza.size.length); i++) {
      const sizeBaseObj = {
        name: pizza.size[i].pizza_size,
        baseId: null,
        basePrice: 0,
        sizeId: pizza.size[i].pizza_size_id,
        sizePrice: getPizzaActualPrice(pizza, pizza.size[i].price),
        totalPrice: getPizzaActualPrice(pizza, pizza.size[i].price)
      }

      // get the base price with base_size
      if (
        pizza.base !== undefined &&
        pizza.base !== null &&
        pizza.base !== "0" &&
        pizza.base !== 0
      ) {
        for (let j = 0; j < pizza.base.length; j++) {
          const sizeBaseObj = {
            name: pizza.size[i].pizza_size,
            basePrice: 0,
            baseId: null,
            sizeId: pizza.size[i].pizza_size_id,
            sizePrice: getPizzaActualPrice(pizza, pizza.size[i].price),
            totalPrice: getPizzaActualPrice(pizza, pizza.size[i].price)
          }

          sizeBaseObj.name += ` ${pizza.base[j].base_name}`
          sizeBaseObj.baseId = pizza.base[j].base_id
          const basePrice = pizza.base[j].base_price.find(
            (item) => item.base_size === pizza.size[i].pizza_size
          )

          sizeBaseObj.basePrice = getPizzaActualPrice(
            pizza,
            basePrice === undefined ? 0 : basePrice.base_price
          )
          sizeBaseObj.totalPrice =
            Number(sizeBaseObj.totalPrice) + Number(sizeBaseObj.basePrice)
          sizeAndBaseCollection.push(sizeBaseObj)
        }
      } else {
        sizeAndBaseCollection.push(sizeBaseObj)
      }
    }
    sizeAndBaseCollection.sort((a, b) => a.totalPrice - b.totalPrice)

    return sizeAndBaseCollection
  }

  const getPizzaActualPrice = (pizza, price) => {
    return isPriceWithoutTax()
      ? Number(price).toFixed(2)
      : (
        Number(price) +
        Number(getTaxes(pizza, Number(price), menu.restaurantInfo).tax)
      ).toFixed(2)
  }

  const openPizzaModal = (pizza, baseId, sizeId) => {
    // open Pizza modal having default topppings
    // and half and half
    // console.log("pizza qty is", pizza)
    if (pizza.qty) {
      dispatch(
        openModal(modalNames.INTERMEDIATE_ADD_MODAL, {
          item: pizza,
          baseId,
          sizeId
        })
      )
    } else {
      dispatch(
        openModal(modalNames.PIZZA_MODAL, {
          pizza,
          baseId,
          sizeId
        })
      )
    }
  }

  const removefromcart = (item) => {
    dispatch(removeItem(item, item.modifiers || null, 0, menu.restaurantInfo))
  }

  const getPriceIfZero = (item) => {
    var priceArr = []
    const itemForcedModifiers = item.forced_modifier.split(",")
    // console.log("split fm", itemForcedModifiers)
    itemForcedModifiers.map((curritem) => {
      const filteredModifier = menu.allForcedModifier.filter((itemOfFilter) =>
        curritem.includes(itemOfFilter.fm_cat_id)
      )

      // let arr = filteredModifier[0]?.items

      let min = Math.min.apply(
        null,
        filteredModifier[0]?.items.map(function (item) {
          return item.price
        })
      )
      var m = isPriceWithoutTax()
        ? truncateDecimal(min)
        : truncateDecimal(
          Number(min) +
          Number(getTaxes(item, Number(min), menu.restaurantInfo).tax)
        )

      priceArr.push(m)
    })

    // console.log("min is", priceArr)
    var final = 0
    priceArr.map((currval) => {
      final = final + parseFloat(currval)
    })
    return final
  }

  const trimNames = (names) => {
    if (names.split(' ')[0].length > 12) {
      return names.substring(0, 10)
    } else {
      return names
    }
  }

  // console.log("lists", list)

  return (
    <>
      {/* New Code */}

      {/* <section id={itemId} className="content-section"> */}
      <div id={itemId}>
        <p className='rcmd-headings'>
          {category_name == "Happy Hours"
            ? "Happy Hours"
            : getSelectedCategoryName(list)}
        </p>

        <div style={{ display: "flex", flexWrap: "wrap", gap: "2%", justifyContent: "space-between" }}>
          <>
            {list.map((item, i) => {
              // console.log("items", item)
              const sizeAndBaseCollection = getSizeAndBase(item)
              const isImageUrl = require("is-image-url")
              var checkImageUrl = isImageUrl(item.image_url)
              let isStillActive = false

              if (item.isHappyHourActive) {
                const result = isHappyHourStillActive(
                  item,
                  menu.restaurantInfo.timezone
                )

                isStillActive = result.isActive
                if (isStillActive) {
                  refIndex++
                  setTimer(result.distance, timeOutRef[refIndex])
                }
              }
              const minQty =
                Number(item.min_qty) === 0 ? 0 : Number(item.min_qty)


              // console.log("item Qty", item.qty)
              return (
                <>
                  {item.qty ? (
                    <>
                      {item.base ? (
                        <>
                          <div className="menu-itms" key={i}>
                            <div className="tik-btn">
                              {/* <BiInfoCircle onClick={() => {
                                setOpensDrawer(true)
                                setDrawName(item.cname || item.name || item.title)
                                setPicUrl(checkImageUrl ? item.image_url : DemoFoodImage)
                                setDrawPrice(item.price == "0" ? symbol + " " + getPriceIfZero(item) : symbol + " " + getActualPrice(item))
                                setDrawDescriptiion(item?.description)
                              }} size={30} color="#645991" /> */}

                              <BsFillCheckCircleFill size={25} color="green" />
                            </div>
                            <div onClick={() =>
                              openPizzaModal(
                                item,
                                isHappyHourStillActive(
                                  item,
                                  menu.restaurantInfo.timezone
                                ).isActive
                              )}>
                              <div style={{ display: "flex", justifyContent: "center", marginTop: "-15px" }}>
                                <div className="img-cropper">
                                  <img src={checkImageUrl ? item.image_url : DemoFoodImage} alt="Avatar"></img>
                                </div>
                              </div>

                              <div className='food-dtls'>
                                <div className=''>
                                  <h4 className='item-name-text'>
                                    {trimNames(item.cname || item.name || item.title)}
                                    {/* {(item.cname || item.name || item.title).split(' ')[0] > 12 ? (item.cname || item.name || item.title).substring(0, 10) : (item.cname || item.name || item.title)} */}
                                  </h4>

                                  {item.price == "0" ? (
                                    <>
                                      <h4 className='itm-amt'>
                                        {`${symbol} ${getPriceIfZero(item)}`}
                                      </h4>
                                    </>
                                  ) : (
                                    <>
                                      <h4 className='itm-amt'>
                                        {`${symbol} ${getActualPrice(item)}`}
                                      </h4>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                        </>
                      )
                        : (
                          <>
                            <div className="menu-itms">
                              <div className="tik-btn">
                                {/* <BiInfoCircle onClick={() => {
                                  setOpensDrawer(true)
                                  setDrawName(item.cname || item.name || item.title)
                                  setPicUrl(checkImageUrl ? item.image_url : DemoFoodImage)
                                  setDrawPrice(item.price == "0" ? symbol + " " + getPriceIfZero(item) : symbol + " " + getActualPrice(item))
                                  setDrawDescriptiion(item?.description)
                                }} size={30} color="#645991" /> */}

                                <BsFillCheckCircleFill size={25} color="green" />
                              </div>
                              <div onClick={() =>
                                onAddItem(
                                  item,
                                  isHappyHourStillActive(
                                    item,
                                    menu.restaurantInfo.timezone
                                  ).isActive
                                )}>
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "-15px" }}>
                                  <div className="img-cropper">
                                    <img src={checkImageUrl ? item.image_url : DemoFoodImage} alt="Avatar"></img>
                                  </div>
                                </div>

                                <div className='food-dtls'>
                                  <div className=''>
                                    <h4 className='item-name-text'>
                                      {trimNames(item.cname || item.name || item.title)}
                                      {/* {(item.cname || item.name || item.title).split(' ')[0] > 12 ? (item.cname || item.name || item.title).substring(0, 10) : (item.cname || item.name || item.title)} */}
                                    </h4>

                                    {item.price == "0" ? (
                                      <>
                                        <h4 className='itm-amt'>
                                          {`${symbol} ${getPriceIfZero(item)}`}
                                        </h4>
                                      </>
                                    ) : (
                                      <>
                                        <h4 className='itm-amt'>
                                          {`${symbol} ${getActualPrice(item)}`}
                                        </h4>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      {item.base ? (
                        <>
                          <div className="menu-itms">
                            <div className="tik-btn">
                              {/* <BiInfoCircle onClick={() => {
                                setOpensDrawer(true)
                                setDrawName(item.cname || item.name || item.title)
                                setPicUrl(checkImageUrl ? item.image_url : DemoFoodImage)
                                setDrawPrice(item.price == "0" ? symbol + " " + getPriceIfZero(item) : symbol + " " + getActualPrice(item))
                                setDrawDescriptiion(item?.description)
                              }} size={30} color="#645991" /> */}

                              <FiCircle size={25} color="#f3f2f7" />
                            </div>
                            <div onClick={() =>
                              openPizzaModal(
                                item,
                                isHappyHourStillActive(
                                  item,
                                  menu.restaurantInfo.timezone
                                ).isActive
                              )}>
                              <div style={{ display: "flex", justifyContent: "center", marginTop: "-15px" }}>
                                <div className="img-cropper">
                                  <img src={checkImageUrl ? item.image_url : DemoFoodImage} alt="Avatar"></img>
                                </div>
                              </div>

                              <div className='food-dtls'>
                                <div className=''>
                                  <h4 className='item-name-text'>
                                    {trimNames(item.cname || item.name || item.title)}
                                    {/* {(item.cname || item.name || item.title).split(' ')[0] > 12 ? (item.cname || item.name || item.title).substring(0, 10) : (item.cname || item.name || item.title)} */}
                                  </h4>

                                  {item.price == "0" ? (
                                    <>
                                      <h4 className='itm-amt'>
                                        {`${symbol} ${getPriceIfZero(item)}`}
                                      </h4>
                                    </>
                                  ) : (
                                    <>
                                      <h4 className='itm-amt'>
                                        {`${symbol} ${getActualPrice(item)}`}
                                      </h4>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                        </>
                      )
                        : (
                          <>
                            <div className="menu-itms">
                              <div className="tik-btn">
                                {/* <BiInfoCircle onClick={() => {
                                  setOpensDrawer(true)
                                  setDrawName(item.cname || item.name || item.title)
                                  setPicUrl(checkImageUrl ? item.image_url : DemoFoodImage)
                                  setDrawPrice(item.price == "0" ? symbol + " " + getPriceIfZero(item) : symbol + " " + getActualPrice(item))
                                  setDrawDescriptiion(item?.description)
                                }} size={30} color="#645991" /> */}

                                <FiCircle size={25} color="#f3f2f7" />
                              </div>
                              <div onClick={() =>
                                onAddItem(
                                  item,
                                  isHappyHourStillActive(
                                    item,
                                    menu.restaurantInfo.timezone
                                  ).isActive
                                )}>
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "-15px" }}>
                                  <div className="img-cropper">
                                    <img src={checkImageUrl ? item.image_url : DemoFoodImage} alt="Avatar"></img>
                                  </div>
                                </div>

                                <div className='food-dtls'>
                                  <div className=''>
                                    <h4 className='item-name-text'>
                                      {trimNames(item.cname || item.name || item.title)}
                                      {/* {(item.cname || item.name || item.title).split(' ')[0] > 12 ? (item.cname || item.name || item.title).substring(0, 10) : (item.cname || item.name || item.title)} */}
                                    </h4>

                                    {item.price == "0" ? (
                                      <>
                                        <h4 className='itm-amt'>
                                          {`${symbol} ${getPriceIfZero(item)}`}
                                        </h4>
                                      </>
                                    ) : (
                                      <>
                                        <h4 className='itm-amt'>
                                          {`${symbol} ${getActualPrice(item)}`}
                                        </h4>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                    </>
                  )}
                </>
              )
            })}
          </>
        </div>
      </div>
      {/* </section> */}


      {/* Old Code */}
      {/* <h4
            className='category-name-head text-pizzamodal'>
            {category_name == "Happy Hours"
              ? "Happy Hours"
              : getSelectedCategoryName(list)}
          </h4>
          <div
            id={getSelectedCategoryName(list)}
            style={{ display: "flex", flexWrap: "wrap", gap: "2%", justifyContent: "space-between" }}
          >

            <>
              {list.map((item) => {
                const sizeAndBaseCollection = getSizeAndBase(item)
                const isImageUrl = require("is-image-url")
                var checkImageUrl = isImageUrl(item.image_url)
                console.log("npm url is", checkImageUrl)
                let isStillActive = false
                console.log("menuItems in this file", item)

                if (item.isHappyHourActive) {
                  const result = isHappyHourStillActive(
                    item,
                    menu.restaurantInfo.timezone
                  )

                  isStillActive = result.isActive
                  if (isStillActive) {
                    refIndex++
                    setTimer(result.distance, timeOutRef[refIndex])
                  }
                }
                const minQty =
                  Number(item.min_qty) === 0 ? 0 : Number(item.min_qty)
                return (
                  <>
                    <div className="menue-items" >
                      <div style={{ display: "flex", justifyContent: "center", marginTop: "4%" }}>
                        <div className="image-cropper">
                          <img
                            className="rounded"
                            src={
                              checkImageUrl ? item.image_url : DemoFoodImage
                            }
                            alt="Avatar"
                          ></img>
                        </div>
                      </div>
                      <div className='food-details'>
                        <div className=''>
                          <div className=''>
                            <h4 className='food-item-name'>
                              {item.cname || item.name || item.title}
                            </h4>
                            {item.price == "0" ? (
                              <>
                                <div className='item-price-parent-div' style={{ display: "flex", justifyContent: "center" }}>
                                  <span className='item-price' style={{ fontFamily: 'Poppins', }}>
                                    {`${symbol} ${getPriceIfZero(item)}`}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='item-price-parent-div' style={{ display: "flex", justifyContent: "center" }}>
                                  <span className='item-price'>
                                    {`${symbol} ${getActualPrice(item)}`}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>

                          {item.qty ? (
                            <>
                              <div className='after-first-add-container'>
                                <div className='after-first-add-parent'>
                                  <div className='after-first-add-child'>
                                    <div
                                      className='left-after-add'
                                      onClick={() => removefromcart(item)}
                                    >
                                      <RemoveIcon fontSize='small' />
                                    </div>
                                    <div className='middle-after-add'>
                                      <span className='qty-after-add'>
                                        {item.qty}
                                      </span>
                                    </div>
                                    {item.base ? (
                                      <>
                                        <div
                                          className='right-after-add'
                                          onClick={() =>
                                            openPizzaModal(
                                              item,
                                              isHappyHourStillActive(
                                                item,
                                                menu.restaurantInfo.timezone
                                              ).isActive
                                            )
                                          }
                                        >
                                          <AddIcon fontSize='small' />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          className='right-after-add'
                                          onClick={() =>
                                            onAddItem(
                                              item,
                                              isHappyHourStillActive(
                                                item,
                                                menu.restaurantInfo.timezone
                                              ).isActive
                                            )
                                          }
                                        >
                                          <AddIcon fontSize='small' />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ display: "flex", justifyContent: "center", marginBottom: "4%" }}>
                                {item.base ? (
                                  <>
                                    <div
                                      className='button-container'
                                      onClick={() =>
                                        openPizzaModal(
                                          item,
                                          isHappyHourStillActive(
                                            item,
                                            menu.restaurantInfo.timezone
                                          ).isActive
                                        )
                                      }
                                    >
                                      <span className='add-to-cart-button'>
                                        Add
                                      </span>
                                      <span className='add-to-cart-button-plus'>
                                        <AddIcon />
                                      </span>
                                    </div>
                                  </>
                                )
                                  : (
                                    <>
                                      <div
                                        className='button-container'
                                        onClick={() =>
                                          onAddItem(
                                            item,
                                            isHappyHourStillActive(
                                              item,
                                              menu.restaurantInfo.timezone
                                            ).isActive
                                          )
                                        }
                                      >
                                        <span className='add-to-cart-button'>
                                          Add
                                        </span>
                                        <span className='add-to-cart-button-plus'>
                                          <AddIcon />
                                        </span>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </>
                          )}


                        </div>
                        {item.isHappyHourActive && isStillActive ? (
                          <>
                            <p
                              className='food-description'
                              style={{
                                color: "#bd1e44",
                                fontWeight: "600"
                              }}
                            >
                              {item.happyHourDetail.happyHourDisplayText}
                            </p>
                            <p className='food-description'>
                              <span
                                style={{
                                  color: "#bd1e44",
                                  fontWeight: "700"
                                }}
                                ref={timeOutRef[refIndex]}
                              />
                            </p>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                )
              })}
            </>
          </div> */}
    </>

  )
}

export default MenuTable
