import React, { useEffect, useState } from "react";
import "./RestaurantCard.css";

const RestaurantCard = ({ restaurant }) => {
  const [order, setorder] = useState([]);

  {
    restaurant?.order_option?.split(",").map((option, i) => {
      order.push(option);
    });
  }

  return (
    <>
      <a
        className="hover-cat"
        href={`${`?/restId=${restaurant.restaurant_id}/menu`}`}
      >
        <div style={{ marginTop: "20px", marginLeft: "15px" }}>
          <div className="restro-card-container">
            <div className="desc">
              <div
                className="thumb_strip"
                style={{
                  marginTop: "20px",
                  borderRadius: "15px",
                }}
              >
                <img src={restaurant.logo} alt />
              </div>
              <p className="restro-card-rname">{restaurant.rname}</p>

              <div className="location restro-card-location">
                {restaurant.address} {restaurant.city}
                {restaurant.zipcode}
              </div>
              <div style={{ fontWeight: "700", color: "#6244da" }}>
                Opens at
              </div>

              <div className="rest-card-info">
                {restaurant?.order_option?.split(",").map((option, i) => {
                  if (option == "delivery") {
                    return (
                      <>
                        <div className="rest-card-info-child">Delivery</div>
                      </>
                    );
                  }
                })}
                &nbsp; &nbsp;

                {restaurant?.order_option?.split(",").map((option, i) => {
                  if (option == "pickup") {
                    return (
                      <>
                        <div className="rest-card-info-child-pickup">
                          pickup
                        </div>
                      </>
                    );
                  }
                })}
              </div>

              <div className="rest-card-info">
                {restaurant?.order_option?.split(",").map((option, i) => {
                  if (option == "eatin") {
                    return (
                      <>
                        <div className="rest-card-info-child-eatin">Eat-In</div>
                        &nbsp; &nbsp;
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>{" "}
      </a>
    </>
  );
};

export default RestaurantCard;