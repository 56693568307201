import React from "react";
import RestaurantCard from "./RestaurantCard/RestaurantCard";

const Restaurants = ({ restaurants }) => {
  return (
    <>
      <div className="white_bg">
        <div className="container margin_60">
          <div className="main_title">
            <h2 className="nomargin_top">Choose from Most Popular</h2>
            <p>Your Favourite Restaurants</p>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {restaurants.map((restaurant) => {
              return <RestaurantCard restaurant={restaurant} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Restaurants;
