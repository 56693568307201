import React, { useState } from "react"
import { Radio } from "antd"
import { ModifierOption } from "../../ModifierOption"
import "./ModifierCategory.css"
import "../../../Modals/PizzaModal/PizzaModal.css"
import Button from "@material-ui/core/Button"


export const ModifierCategory = ({
  full_item,
  forceModifier,
  detours,
  currency,
  getModifierPrice,
  onSelectionChange,
  lowestModifierId,
  activeLinkedFm
}) => {
  const [selectedModifierId, setSelectedModifierId] = useState(lowestModifierId)

  const handleGroupSelectionChange = ({ target: { value } }) => {
  // console.log("its forceModifier here", value)

    const selectedForceModifier = forceModifier?.items.find(
      ({ id }) => id === value
    )

    const selectedLinkedModifier = forceModifier?.items.find(
      ({ fm_item_id }) => fm_item_id === value
    )

    const finalSelectionChange = selectedForceModifier
      ? selectedForceModifier
      : selectedLinkedModifier

    setSelectedModifierId(value)
    
    onSelectionChange({
      ...finalSelectionChange,
      optionalModifiers: [],
      categoryId: forceModifier.fm_cat_id,
      price: Number(finalSelectionChange.price)
    })
  }

  return (
    <>

      <div className='choose-parents'>
        <div className="choose-child">
          <p className="choose-head">{forceModifier?.name ? forceModifier?.name : forceModifier?.fm_cat_name}</p>

          <div
            className='modifier-options'
            style={{ marginTop: "10px", marginLeft: "" }}
          >
            <Radio.Group
              onChange={handleGroupSelectionChange}
              value={selectedModifierId}
            >
              {/* {console.log("forceModifier?.items", forceModifier?.items)} */}
              {forceModifier?.items &&
                forceModifier?.items.map((item, i) => {
                  let checked = item.id ? item.id : item.fm_item_id

                  return (
                    <>
                      <ModifierOption
                        key={i}
                        isChecked={checked === selectedModifierId}
                        currency={currency}
                        option={item}
                        categoryId={forceModifier.fm_cat_id}
                        forceModifierdetours={detours}
                        getModifierPrice={getModifierPrice}
                        onSelectionChange={onSelectionChange}
                        item={full_item}
                        lowestModifierId={lowestModifierId}
                        activeLinkedFm={activeLinkedFm}
                      />
                    </>
                  )
                })}
            </Radio.Group>
          </div>

        </div>
      </div>








      {/* Old Code */}

      {/* <div
        className='modifier-category'
        style={{
          // backgroundColor: "#f1f1f1",
          marginBottom: "10px",
          paddingTop: "10px",
          paddingBottom: "20px"
        }}
      >
        <p
          className='modifier-category-name '
          style={{
            marginLeft: "15px",
            fontSize: "20px",
            fontWeight: "700",
            color: "#5c48d2"
          }}
        >
          {forceModifier.fm_cat_name || forceModifier.name}
        </p>
        <br />

        <p
          className='modifier-category-instruction '
          style={{
            marginLeft: "15px",
            fontSize: "15px",
            marginTop: "-35px",
            marginBottom: "20px",
            color: "#968EA1"
          }}
        >
          You can choose only 1 option
        </p>

        <div
          className='modifier-options'
          style={{ marginTop: "10px", marginLeft: "20px" }}
        >
          <Radio.Group
            onChange={handleGroupSelectionChange}
            value={selectedModifierId}
          >
            {forceModifier?.items &&
              forceModifier?.items.map((item, i) => {
                let checked = item.id ? item.id : item.fm_item_id
                console.log("checkedhdhd", checked, selectedModifierId)
                return (
                  <>
                    <ModifierOption
                      key={i}
                      isChecked={checked === selectedModifierId}
                      currency={currency}
                      option={item}
                      categoryId={forceModifier.fm_cat_id}
                      forceModifierdetours={detours}
                      getModifierPrice={getModifierPrice}
                      onSelectionChange={onSelectionChange}
                      item={full_item}
                      lowestModifierId={lowestModifierId}
                      activeLinkedFm={activeLinkedFm}
                    />
                  </>
                )
              })}
          </Radio.Group>
        </div>
      </div> */}
    </>
  )
}
export default ModifierCategory
