import React from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const FirsrtLandingPage = () => {
    const route = useHistory()
    useEffect(() => {
        route.replace(route?.location?.pathname.charAt(route?.location?.pathname.length - 1) == '/' ? route?.location?.pathname + "tableDetails" : route?.location?.pathname + "/tableDetails")
    }, [])

    return (
        <div></div>
    )
}

export default FirsrtLandingPage