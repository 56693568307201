import React, { useEffect, useState } from 'react'
import "./enterTableNoPage.css"
import img6 from "./Demo-Rest-Logo.png"
import Button from "@material-ui/core/Button"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

const EnterTableNoPage = () => {
    const main = useSelector((state) => state.main)
    const menu = useSelector((state) => state.menu)
    const [tblNo, setTblNo] = useState('')
    const History = useHistory()

    const goToOpenOrders = () => {
        History.push(
            `/restId=${main.restId}&tid=${tblNo}/tableDetails`
        )
        window.location.reload()
    }

    return (
        <div>
            <div className="tblNo-lgo-parent">
                <div className='tblNo-lgo-bdr'>
                    <img src={main.selectedRestaurant.logo} className='tblNo-lgo-img' />
                </div>
            </div>

            <div style={{ textAlign: 'center' }}>
                <div className='tblNo-welcome-txt'>Welcome!</div>
                <div className='entr-tblno-txt'>Enter your table number:</div>

                <input
                    value={tblNo}
                    onChange={(e) => setTblNo(e.target.value)}
                    className="tbleNo-input"
                    type="text"
                />
            </div>

            <div className='strtOrdr-btn-container'>
                {
                    tblNo
                        ?
                        <Button
                            onClick={goToOpenOrders}
                            style={{
                                backgroundColor: "#000000",
                                color: "white",
                                height: "3.8rem",
                                width: "100%",
                                padding: "10px",
                                textTransform: "none",
                                fontSize: "18px",
                                borderRadius: "15px",
                                fontFamily: 'Poppins',
                                letterSpacing: '0.7px'
                            }}
                        >
                            Start Order
                        </Button>
                        :
                        <Button
                            disabled
                            style={{
                                backgroundColor: "#d6d2e4",
                                color: "white",
                                height: "3.8rem",
                                width: "100%",
                                padding: "10px",
                                textTransform: "none",
                                fontSize: "18px",
                                borderRadius: "15px",
                                fontFamily: 'Poppins',
                                letterSpacing: '0.7px'
                            }}
                        >
                            Start Order
                        </Button>
                }
            </div>
        </div>
    )
}

export default EnterTableNoPage