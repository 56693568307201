import Button from "@material-ui/core/Button"
import { MailRounded, RestaurantMenuOutlined } from "@material-ui/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { allTableNamesInfo, checkItsBackFromLogo, fetchOpenOrder, firstToken, storeOpenOrderId, storeTableId } from "../../state-management/main/actions"
import { getOpenOrders } from "../../state-management/main/asyncActions"
import "./table-details.css"
import moment from 'moment';
import Skeleton from "react-loading-skeleton";
import img6 from "./Demo-Rest-Logo.png"
import { useParams } from "react-router-dom";
import { clearCart } from "../../state-management/menu/actions"
import LargeScreenErrorPage from "../largeScreenErrorPage/LargeScreenErrorPage"

const TableDetails = () => {
  const main = useSelector((state) => state.main)
  const menu = useSelector((state) => state.menu)

  const History = useHistory()
  const [visible, setvisible] = useState();
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false);
  const [tblNameLdr, setTblNameLdr] = useState(true)

  let skeletonArray = [1, 2, 3];

  const today = moment();
  const currentDate = today.format().substring(0, 10);
  const currentTime = today.format().substring(11, 13);
  const beforeCurrentTime = currentTime - 1;
  const params = useParams();
  const newToken = Math.random().toString(36).substring(2, 7) + 'tkn';

  useEffect(() => {
    if (main.checkItsBackFromLogo.itsBackFromLogo == "yes") {
      window.location.reload()
    } else {
      return
    }
  }, [])


  useEffect(async () => {
    if (window.screen.width > 450) {
      setvisible(false)
    } else {
      setvisible(true)
    }

    // console.log("its main here", main.selectedRestaurant)
    // let restSettingsData = main.selectedRestaurant.restaurant_settings_details

    // for (var i = 0; i < restSettingsData.length; i++) {
    //   if (restSettingsData[i].setting_identifier === "QR_ORDERING") {
    //     if (restSettingsData[i].status != "ON") {
    //       History.push(`/restId=${menu.restaurantInfo.restaurant_id}/pagenotfound`)
    //     }
    //   }
    // }
    getOpenTableOrder()
    allTablesNames()
  }, [])

  const getOpenTableOrder = () => {
    var axios = require('axios');
    var data = JSON.stringify({
      "table_id": main.tableId,
      "order_status": "OPEN"
    });

    var config = {
      method: 'post',
      url: 'https://api.ciboapp.com/api/selforder/getordersqr',
      headers: {
        'Authorization': 'Basic Wm90dG86MTIzNDU=',
        'Content-Type': 'application/json'
      },
      data: data
    };

    setLoader(true);
    axios(config)
      .then(function (response) {
        // console.log("data", response.data);
        dispatch(fetchOpenOrder(response.data));
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const allTablesNames = () => {
    const axios = require('axios');
    let data = '';

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://api.ciboapp.com/api/onlineOrdering/getTableDetails/${main.restId}`,
      headers: {
        'Authorization': 'Basic Wm90dG86MTIzNDU='
      },
      data: data
    };

    setTblNameLdr(true);
    axios.request(config)
      .then((response) => {
        // console.log("res.data", response.data);
        // if(response.data.status === "200"){
        dispatch(allTableNamesInfo(response.data.data));
        // }
        setTblNameLdr(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const goToMenu = () => {
    dispatch(clearCart())
    dispatch(checkItsBackFromLogo({ "itsBackFromLogo": "yes" }))
    dispatch(storeOpenOrderId({ "order_id": "", "order_id_status": "new" }));
    dispatch(storeTableId({ "tid": main.tableId }));
    dispatch(firstToken({ "tid": main.tableId }));
    dispatch(firstToken({ "newToken": newToken }));
    localStorage.setItem('localNewToken', newToken);

    History.push(
      `/restId=${menu.restaurantInfo.restaurant_id}&tid=${main.tableId}/menu`
    )

  }

  const goToViewDetails = (ordr_id, rest_id) => {
    History.push(
      `/viewDetails/restid=${rest_id}/tid=${main.tableId}/orderid=${ordr_id}`
    )
  }

  // let filteredDataByTime = main?.fetchOpenOrder?.data?.filter(n => n.order_date.substring(0, 10) == "2023-08-04");
  let filteredDataByTime = main?.fetchOpenOrder?.data?.filter(n => n.order_date.substring(0, 10) == currentDate && beforeCurrentTime <= Number(n.order_time.substring(0, 2)) && Number(n.order_time.substring(0, 2)) <= Number(currentTime));
  // let filteredDataByTime = main?.fetchOpenOrder?.data

  let tableNameById = main?.allTableNamesInfo?.filter((n => n.table_id == main?.tableId))

  console.log("res.data", tableNameById);


  return (
    <>

      {visible ? (
        <>
          <input style={{ height: '0px', color: '#fff', borderStyle: 'hidden' }} autofocus></input>

          <div>
            <div className="img-qr-parent">
              <div className='img-qr-parent-bdr'>
                <img src={main.selectedRestaurant.logo} className='img-qr' />
              </div>
            </div>

            <div className='welcome-text-parent'>
              <div className='welcome-txt'>Welcome!</div>

              <div className='tbl-nme-txt'>Your table:</div>
              <div style={{ height: '40px' }}>
                {tblNameLdr
                  ?
                  <div><Skeleton height={28} width={220} style={{ marginBottom: '7px', backgroundColor: '#e9e7f1', borderRadius: "7px" }} /></div>
                  :
                  // <div className='tbl-nme-txt'>ll</div>

                  <div className='tbl-nme-txt'>{
                    tableNameById == undefined
                      ?
                      main.tableId
                      :
                      tableNameById[0]?.table_name === ''
                        ?
                        tableNameById[0]?.table_id
                        :
                        tableNameById[0]?.table_name
                  }</div>
                }
              </div>
            </div>

            <div className='plcOdr-btn-container'>
              <Button
                onClick={goToMenu}
                style={{
                  backgroundColor: "#000000",
                  color: "white",
                  height: "3.8rem",
                  width: "100%",
                  padding: "10px",
                  textTransform: "none",
                  fontSize: "18px",
                  borderRadius: "15px",
                  fontFamily: 'Poppins',
                  letterSpacing: '0.7px'
                }}
              >
                Place New Order
              </Button>
            </div>

            <div style={{ paddingLeft: '8%', paddingRight: '8%' }}>
              <hr />
            </div>

            {loader
              ?
              <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className='open-orders-parent' style={{ marginTop: "19%", marginBottom: "22%", marginLeft: '8%', marginRight: '8%' }}>
                    {
                      skeletonArray.map((i) => {
                        return (
                          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "6%" }} key={i}>
                            <Skeleton height={25} width={132} style={{ backgroundColor: '#e9e7f1', borderRadius: "7px" }} />
                            <Skeleton height={25} width={132} style={{ backgroundColor: '#e9e7f1', borderRadius: "7px" }} />
                          </div>
                        )
                      })
                    }
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Skeleton height={25} width={170} style={{ backgroundColor: '#e9e7f1', borderRadius: "7px" }} />
                    </div>
                  </div>
                </div>
              </>
              :
              <>
                {/* {console.log("filteredDataByTime", filteredDataByTime)} */}
                {
                  filteredDataByTime?.length == 0
                    ?
                    <>
                      <div className='previous-orders'>
                        <div className='previous-orders-text'>No Order Found !</div>
                      </div>
                    </>
                    :
                    <>
                      <div className='previous-orders'>
                        <div className='previous-orders-text'>Previous orders</div>
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div>
                          {filteredDataByTime?.map((val, i) => {
                            return (
                              <div key={i}>
                                <div className='open-orders-parent'>
                                  <div className='open-odr-parent'>
                                    <span className='open-odr-text'>
                                      Order No
                                    </span>
                                    <span className='open-odr-value-text'>
                                      {val?.order_id}
                                    </span>
                                  </div>

                                  <div className='open-odr-parent'>
                                    <span className='open-odr-text'>
                                      Name
                                    </span>
                                    <span className='open-odr-value-text'>
                                      {val?.delivery_firstname && val?.delivery_firstname}
                                    </span>
                                  </div>

                                  <div className='open-odr-parent'>
                                    <span className='open-odr-text'>
                                      Amount
                                    </span>
                                    <span className='open-odr-value-text'>
                                      {menu.restaurantInfo.monetary_symbol}
                                      {val?.grand_total.toString().length > 10
                                        ?
                                        val?.grand_total.toString().substring(0, 8) + `..`
                                        :
                                        val?.grand_total.toString().includes('.')
                                          ?
                                          val?.grand_total
                                          :
                                          val?.grand_total + `.00`
                                      }
                                    </span>
                                  </div>

                                  <div className="viewBtn-container">
                                    <button
                                      className='view-details-btn'
                                      onClick={() => goToViewDetails(val.order_id, val.restaurant_id)}>
                                      View Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                          }
                        </div>
                      </div>
                    </>
                }
              </>
            }
          </div>
        </>
      ) : (
        <LargeScreenErrorPage />
      )
      }
    </>
  )
}
export default TableDetails