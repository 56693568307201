import React, { useEffect, useState } from "react"
import Detours from "../Modifiers/ForcedModifiers/Detours/Detours"
import { Radio, Form } from "antd"
import { getForcedModifierDetours } from "../Modifiers/ForcedModifiers/Detours/utils"
import { useSelector } from "react-redux"
import "./modifier-option.css";
import { MdCheckBox } from 'react-icons/md';
import { ImCheckboxUnchecked } from 'react-icons/im';

export const ModifierOption = ({
  item,
  option,
  currency,
  forceModifierdetours,
  categoryId,
  isChecked,
  getModifierPrice,
  onSelectionChange,
  lowestModifierId,
  activeLinkedFm

  // checkedId
}) => {
  const detours = getForcedModifierDetours(forceModifierdetours, option.id)
  // console.log("items in modify options", item)
  const [id, setid] = useState("17282")
  const menu = useSelector((state) => state.menu)

  useEffect(() => {
    var priceArr = []
    const itemForcedModifiers = item.forced_modifier.split(",")
    // console.log("split fm", itemForcedModifiers)
    itemForcedModifiers.map((curritem) => {
      const filteredModifier = menu.allForcedModifier.filter((itemOfFilter) =>
        curritem.includes(itemOfFilter.fm_cat_id)
      )

      let min = Math.min.apply(
        null,
        filteredModifier[0]?.items.map(function (item) {
          return item.id
        })
      )

      priceArr.push(min)
    })
  }, [])

  function handleSelectionChange(selectedDetours) {
    const detourIds = selectedDetours.map((detour) => detour.id).join()
    const selectedForceModifier = {
      ...option,
      categoryId,
      optionalModifiers: selectedDetours,
      detour: detourIds,
      price:
        selectedDetours.reduce(
          (acc, item) => acc + (item.isFree ? 0 : Number(item.price)),
          0
        ) + Number(option.price)
    }

    onSelectionChange && onSelectionChange(selectedForceModifier)
  }

  // console.log("active linked fm", activeLinkedFm)
  return (
    <>
      <Radio value={option.id || option.fm_item_id}
        style={{
          fontFamily: 'Poppins',
          fontSize: '14px',
          letterSpacing: '0.6px',
          fontWeight: '500',
          backgroundColor: '#fff',
          color: '#000',
          borderRadius: '20px',
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '4px',
          paddingRight: '2px',
          marginBottom: '6px'
        }}>
        {option.name || option.fm_item_name}
      </Radio>

      {detours && isChecked ? (
        <Detours
          optionalModifierId={detours}
          getModifierPrice={getModifierPrice}
          onDetourSelectionChange={handleSelectionChange}
        />
      ) : null}
    </>
  )
}
export default ModifierOption





// old code
{/* <>
      <div style={{ display: "flex" }}>
        <div className="chk-btn">
          <Radio value={option.id || option.fm_item_id}
            style={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              letterSpacing: '0.6px',
              fontWeight: '600',
            }}>
            {option.name || option.fm_item_name}

            <span style={{ float: 'right' }}>
              ({`${currency} ${getModifierPrice(option.price)}`})
            </span>
            
          </Radio>
        </div>
      </div>

      {detours && isChecked ? (
        <Detours
          optionalModifierId={detours}
          getModifierPrice={getModifierPrice}
          onDetourSelectionChange={handleSelectionChange}
        />
      ) : null}
    </> */}
