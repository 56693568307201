import React, { useEffect } from 'react'
import "./paymentfailed.css"
import pays_fail_icon from "./pays_fail_icon.png"
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router"
import { BiArrowBack } from 'react-icons/bi';
import { useParams } from "react-router-dom";
import { checkItsBackFromLogo, storeFilterDrawData } from "../../state-management/main/actions"
import { useSelector, useDispatch } from "react-redux"


const Paymentfailed = () => {
  const History = useHistory()
  const params = useParams();
  const main = useSelector((state) => state.main)
  const menu = useSelector((state) => state.menu)
  const dispatch = useDispatch()


  useEffect(() => {
    console.log("params", params)
  }, [])
  
  const goHomeBtn = () => {
    History.push(
      `/restId=${menu.restaurantInfo.restaurant_id}&tid=${params.tid}/tableDetails`
    )
    window.location.reload(false)
    
  }

  return (
    <div>
      <div className='icn-container'>
        <img src={pays_fail_icon} className='pays_fail_icon' />
      </div>

      <p className="oops-texts">
        Oops !
      </p>

      <div className='fail-msg'>
        Payment failed!
      </div>

      <div className='btnCont'>
        <Button
          onClick={goHomeBtn}
          style={{
            backgroundColor: "#000000",
            color: "white",
            fontSize: "22px",
            height: "4rem",
            width: "50%",
            padding: "10px",
            textTransform: "none",
            borderRadius: "20px",
            marginTop: "40%",
            fontFamily: 'Poppins',
            letterSpacing: '0.1rem'
          }}
        >
          Go Home
        </Button>
      </div>
    </div>
  )
}

export default Paymentfailed